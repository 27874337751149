import {
  CHANGE_TOOLBAR_VIEW,
  SET_TOOLBAR_POSITION,
  RESET_TOOLBAR_POSITION,
  PIN_TOOLBAR,
  UNPIN_TOOLBAR,
  SET_LINE_WIDTH,
  SET_FILL_COLOR,
  SET_LINE_COLOR,
  SET_TOOLBAR_VERTICAL,
  SET_TOOLBAR_SIZE,
  SHOW_TOOLBAR_LABELS,
} from "./actionTypes";

export const changeToolbarView = () => {
  return {
    type: CHANGE_TOOLBAR_VIEW,
  };
};

//Toolbar X,Y Positions
export const setToolbarPosition = (position) => {
  return {
    type: SET_TOOLBAR_POSITION,
    payload: { x: position.x, y: position.y },
  };
};
export const setToolbarVertical = (status) => {
  return {
    type: SET_TOOLBAR_VERTICAL,
    payload: status,
  };
};
export const resetToolbarPosition = () => {
  return {
    type: RESET_TOOLBAR_POSITION,
  };
};
export const pinToolbar = () => {
  return {
    type: PIN_TOOLBAR,
  };
};
export const unpinToolbar = () => {
  return {
    type: UNPIN_TOOLBAR,
  };
};
export const setLineWidth = (val) => {
  return {
    type: SET_LINE_WIDTH,
    payload: val,
  };
};
export const setLineColor = (val) => {
  return {
    type: SET_LINE_COLOR,
    payload: val,
  };
};
export const setFillColor = (val) => {
  return {
    type: SET_FILL_COLOR,
    payload: val,
  };
};
export const setToolbarSize = (val) => {
  return {
    type: SET_TOOLBAR_SIZE,
    payload: val,
  };
};
export const showToolbarLabels = (val) => {
  return {
    type: SHOW_TOOLBAR_LABELS,
    payload: val,
  };
};
