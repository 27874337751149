import React from "react";
import { useTranslation } from "react-i18next";

export default function Redo(props) {
  const { t } = useTranslation();
  return (
    <li onClick={props.onClickRedo}>
      <span 
       className={`button normal-btn redo ${
        props.shouldShowButtonLabels ? "show-label-span" : ""
        }`}
      title={t("Redo")}>
        <img
          src={require("../../assets/img/svg/history-redo.svg")}
          alt={t("Redo")}
          data-tip={t("Redo")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Redo")}</span>}
      </span>
    </li>
  );
}
