import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Eraser extends Component {
  render() {
    return (
      <li
        className={`sub-menu ${
          // this.props.sub.eraserOpened ? "open" : ""
          this.props.activeSubButton === "eraser" ? "open" : ""
        }`}
      >
        <span
          className={`button normal-btn erase ${
            this.props.shouldShowButtonLabels ? "show-label-span" : ""
          } ${
            this.props.activeSubButton === "eraser" ? "active" : ""
          }`}
          title={this.props.t("Erase") + " (Del/Backspace)"}
          onClick={this.props.onClick}
          data-tip={this.props.t("Erase")}
        >
          <img
            src={require("../../assets/img/svg/tools-eraser.svg")}
            alt={this.props.t("Erase") + " (Del/Backspace)"}
          />
          {this.props.shouldShowButtonLabels && <span className="show-label-text">{this.props.t("Erase")}</span>}
        </span>
        <div className="shapes-menu">
          <div className="buttons has-addons">
            <ul>
              <li onClick={this.props.onClickEraseAll}>
                <span
                  // className="button normal-btn custom-shapes"
                  className={`button normal-btn Eraser ${
                    this.props.activeSubChildButton === "eraseall" ? "active" : ""
                  }`}
                  title={this.props.t("Clear All")}
                  data-tip={this.props.t("Clear All")}
                >
                  <img
                    src={require("../../assets/img/svg/tools-all-clear.svg")}
                    alt={this.props.t("Clear All")}
                  />
                </span>
              </li>
              <li onClick={this.props.onClickEraseSelected}>
                <span

                  // className="button normal-btn Eraser"

                  className={`button normal-btn custom-shapes ${
                    this.props.activeSubChildButton === "eraseselected" ? "active" : ""
                  }`}
                  title={this.props.t("Erase") + " (Del/Backspace)"}
                  data-tip={this.props.t("Erase") + " (Del/Backspace)"}
                >
                  <img
                    src={require("../../assets/img/svg/tools-eraser.svg")}
                    alt={this.props.t("Erase") + " (Del/Backspace)"}
                    
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </li>
    );
  }
}
export default withTranslation()(Eraser);
