import React from "react";
import { connect } from "react-redux";
import { setLibraryFromLocal } from "../../actions/login";
import { details } from "../../actions/details";
import {  Route, Redirect } from "react-router-dom";

class ProtectedRoute extends React.Component {
  render() {
    const Component = this.props.component;
    const Path = this.props.path;
    // console.log('Path', Path)
    // console.log('Component', Component)
    // const authStatus = localStorage.auth_token;
    // console.log("this.props.isLoggedIn", this.props.isLoggedIn);
    // console.log("this.props.login", this.props.login);
    // console.log(
    //   'localStorage.getItem("auth_token")',
    //   localStorage.getItem("auth_token")
    // );
    if (!this.props.isLoggedIn) {
      // console.log(
      //   'localStorage.getItem("auth_token") !== null',
      //   localStorage.getItem("auth_token") !== null
      // );
      if (localStorage.getItem("auth_token") !== null) {
        this.props.details();

        // return <Component />;
        return (
          <Route exact={this.props.exact} path={Path} component={Component} />
        );
      } else {
        return <Redirect to={{ pathname: "/login" }} />;
      }
    }
    return <Route exact={this.props.exact} path={Path} component={Component} />;
  }
}
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setLibraryFromLocal: () => dispatch(setLibraryFromLocal()),
    details: () => dispatch(details()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
