import {
  SELECTED_TOOL_CHANGED,
  SELECTED_TOOL_CHANGED_FROM_OUTSIDE,
} from "../actions/actionTypes";
import Tools from "../components/Sketch/tools";
const defaultState = {
  selectedTool: Tools.Pan,
  selectedToolChangedFromOutside: true,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECTED_TOOL_CHANGED:
      if (action.payload == "shapes" && state.selectedTool == "shapes") {
        return {
          ...state,
          selectedTool: Tools.Select,
          selectedToolChangedFromOutside: false,
        };
      }
      if (
        action.payload == "eraseall" ||
        action.payload == "eraseselected" ||
        action.payload == "eraseser"
      ) {
        return {
          ...state,
          selectedTool: Tools.Select,
          selectedToolChangedFromOutside: false,
        };
      }
      return {
        ...state,
        selectedTool: action.payload,
        selectedToolChangedFromOutside: false,
      };
    case SELECTED_TOOL_CHANGED_FROM_OUTSIDE:
      if (action.payload == "eraseall" || action.payload == "eraseselected") {
        return {
          ...state,
          selectedTool: Tools.Select,
          selectedToolChangedFromOutside: true,
        };
      }
      return {
        ...state,
        selectedTool: action.payload,
        selectedToolChangedFromOutside: true,
      };

    default:
      return state;
  }
};
