/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {Link} from 'react-router-dom'

export default class Thumbnail extends Component {
  constructor() {
    super();
    this.state = {
      imageIsReady: false
    };
  }

  onLoadImage = () => {
    this.setState({ imageIsReady: true });
  };

  render() {
    const { pageNumber, data } = this.props;
    return (
      <li
        onClick={() => this.props.onClick(data.pageNumber)}
        className={`pdf-thumbnail ${
          pageNumber == data.pageNumber && this.state.imageIsReady
            ? "active"
            : ""
        }`}
      >
        <Link to={`${data.pageNumber}`} className="pdf-thumbnail-link">
          <figure className="image">
            <img
              src={data.thumbnail}
              alt=""
              style={{ display: !this.state.imageIsReady ? "none" : "" }}
              onLoad={this.onLoadImage}
            />
          </figure>

          {!this.state.imageIsReady ? (
            <div className="preloader-mini-frame">
              <div className="preloader-mini-icn">
                <div className="preloader-mini-cut">
                  <div className="preloader-mini-donut" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Link>
        <div className="thumbnail-page-num">{data.pageNumber}</div>
      </li>
    );
  }
}
