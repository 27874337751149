import React, {Component} from "react";
import {connect} from "react-redux";
import CustomModal from "./CustomModal";
import {closeQwant} from '../actions/qwant'

class Wiktionary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true
        };
    }

    toggleModal = (e, modalData) => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen, modalData });
        this.props.closeQwant();
    };

    render() {
        return (
            <CustomModal
                isOpen={this.state.modalIsOpen}
                toggleModal={e => this.toggleModal(e)}
                data={{button_type:"link",content:this.props.url,label:""}}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        scaleZoomFactorVal: state.scaleZoomFactor
    };
};

export default connect(mapStateToProps, {closeQwant})(Wiktionary);