import {
  ENABLE_EDIT_MODE,
  DISABLE_EDIT_MODE,
  ENABLE_BUTTON_MOVE,
  DISABLE_BUTTON_MOVE,
  ENABLE_STUDIO_MODE,
  DISABLE_STUDIO_MODE,
  SET_BACKGROUND_IMAGE_OPACITY,
  SHOW_ANNOTATIONS,
  HIDE_ANNOTATIONS,
  UNDO_TRIGGERED,
  REDO_TRIGGERED,
  REMOVE_TRIGGERED,
  REMOVE_ALL_TRIGGERED,
  ZOOM_IN_TRIGGERED,
  ZOOM_OUT_TRIGGERED,
  IMAGE_LOADED,
  SET_PAGE_PART_ZOOM_MODE,
  SET_PAGE_PART_ZOOM_OBJECT
} from "../actions/actionTypes";

const defaultState = {
  editMode: false,
  moveButtons: true,
  studioMode: true, //TODO SET false after development // full-studio, mini-studio, student, author
  backgroundImageOpacity: 1,
  showAnnotations: true,
  undo: false,
  redo: false,
  remove: false,
  removeAll: false,
  zoomIn: false,
  zoomOut: false,
  imageLoaded: false,
  pageZoomPartMode: null, // can be null, zoomed
  pageZoomPartObject: {
    orderId: null,
    downCoords: null,
    upCoords: null,
  },
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case ENABLE_EDIT_MODE:
      return {
        ...state,
        editMode: true,
      };
    case DISABLE_EDIT_MODE:
      return {
        ...state,
        editMode: false,
      };

    case ENABLE_BUTTON_MOVE:
      return {
        ...state,
        moveButtons: true,
      };
    case DISABLE_BUTTON_MOVE:
      return {
        ...state,
        moveButtons: false,
      };
    case ENABLE_STUDIO_MODE:
      return {
        ...state,
        studioMode: true,
      };
    case DISABLE_STUDIO_MODE:
      return {
        ...state,
        studioMode: false,
      };
    case SET_BACKGROUND_IMAGE_OPACITY:
      return {
        ...state,
        backgroundImageOpacity: action.payload,
      };
    case SHOW_ANNOTATIONS:
      return {
        ...state,
        showAnnotations: true,
      };
    case HIDE_ANNOTATIONS:
      return {
        ...state,
        showAnnotations: false,
      };

    case UNDO_TRIGGERED:
      return {
        ...state,
        undo: action.payload,
      };
    case REDO_TRIGGERED:
      return {
        ...state,
        redo: action.payload,
      };
    case REMOVE_TRIGGERED:
      return {
        ...state,
        remove: action.payload,
      };
    case REMOVE_ALL_TRIGGERED:
      return {
        ...state,
        removeAll: action.payload,
      };
    case ZOOM_IN_TRIGGERED:
      return {
        ...state,
        zoomIn: action.payload,
      };
    case ZOOM_OUT_TRIGGERED:
      return {
        ...state,
        zoomOut: action.payload,
      };
    case IMAGE_LOADED:
      return {
        ...state,
        imageLoaded: action.payload,
      };
    case SET_PAGE_PART_ZOOM_MODE:
      return {
        ...state,
        pageZoomPartMode: action.payload,
      };
    case SET_PAGE_PART_ZOOM_OBJECT:
      const { orderId, downCoords, upCoords } = action.payload;
      return {
        ...state,
        pageZoomPartObject: {
          orderId,
          downCoords,
          upCoords,
        },
      };

    default:
      return state;
  }
};
