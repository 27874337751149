import React, { useState, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  addButtonModalClose,
  addButtonModalOpen,
} from "../../actions/addButton";
import {
  cancelActivity,
  doneActivity,
  insertActivity,
} from "../../actions/addActivity";
import { addButtonFormService } from "../../services/api";
import { useTranslation } from "react-i18next";

import SwitchOption from "./SwitchOption";

import ButtonStylesBulma from "./ButtonStylesBulma";

import SearchButton from "../SubComponents/SearchBar/SearchButton";
import { pullVideosFromPixabay } from "../../actions/pixabayVideo";
import { closePixabay } from "../../actions/pixabay";
import PixabayIcon from "../../assets/img/pixabay3.png";
import PixabayForm from "../PixabayVideoForm";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ReactPlayer from "react-player";
import { confirmAlert } from "react-confirm-alert";
import { MdPublic } from "react-icons/md";

import "react-tabs/style/react-tabs.css";

import "react-quill/dist/quill.snow.css";
import { guidGenerator } from "../../services/util";
import AddNoteGif from "../../assets/img/gif/addNote.gif";

import Uppy from "@uppy/core";
// import Tus from "@uppy/tus";
import { DragDrop } from "@uppy/react";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";

// import modalCloseBtn from "../../assets/img/svg/modal-close-btn.svg";

function VideoForm(props) {
  const { t } = useTranslation();
  const domain = useSelector((state) => state.login.user_profile.domain_name);
  const dispatch = useDispatch();
  const activityId = useSelector((state) => state.activities.activityId);
  const activityType = useSelector((state) => state.activities.activityName);
  const activityLeft = useSelector((state) => state.activities.activityLeft);
  const activityTop = useSelector((state) => state.activities.activityTop);
  const activityBtnStyle = useSelector(
    (state) => state.activities.activityBtnStyle
  );
  const activityShow = useSelector((state) => state.activities.activityShow);

  !activityType && props.addButtonModalClose();
  !activityType && props.cancelActivity();

  //   const [loader, setLoader] = useState(false);
  const [buttonStyle, setButtonStyle] = useState("style1");
  const [isPublic, setIsPublic] = useState(true);
  // const [fileName, setFileName] = useState(true);
  const [visible, setVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  //   const [buttonStyle, setButtonStyle] = useState("style1");

  const [fileName, setFileName] = useState("");

  const [showSources, setShowSources] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [pixabayClicked, setPixabayClicked] = useState(false);
  const [pixabayImage, setPixabayImage] = useState(null);
  const [pixabayImageLoaded, setPixabayImageLoaded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleOnChange = (e) => {
    props.closePixabay();

    if (e.target.value) {
      setShowSources(true);
      setInputValue(e.target.value);
    } else {
      setShowSources(false);
      setInputValue("");
    }
  };

  const pixabayHandler = () => {
    setPixabayClicked(true);
    props.pullVideosFromPixabay(inputValue);
  };

  const pixabayImageHandler = (imageUrl) => {
    setPixabayImage(imageUrl);
    setPixabayImageLoaded(false);
    console.log("imageUrl", imageUrl);
  };

  const [picture, setPicture] = useState(null);
  const onChangePicture = (e) => {
    console.log("e", e);
    // if (e.target.files[0]) {
    //   setPicture(URL.createObjectURL(e.target.files[0]));
    // }
  };

  const showContent = () => {
    confirmAlert({ customUI: showContentCustomUI });
  };

  const showContentCustomUI = ({ onClose }) => {
    const handleClickedClose = () => {
      onClose();
    };
    return (
      <section className="hero is-large">
        <div className="hero-body has-text-centered">
          <div className="container">
            <button
              class="button is-danger is-rounded is-bold is-size-4 is-pulled-right pb-0 pt-0 pl-3 pr-3"
              style={{ marginRight: -15, marginTop: -15, height: "auto" }}
              onClick={handleClickedClose}
            >
              X
            </button>
            <div className="box">
              <img src={AddNoteGif} alt="Placeholder image" />
            </div>
          </div>
        </div>
      </section>
    );
  };

  const schema = yup.object().shape({
    // type: yup.string().required(),
    // fileupload: yup
    //   .mixed()
    //   .when("type", {
    //     is: (type) => type == "audio",
    //     then: yup
    //       .mixed()
    //       .test("required", "This Field is Required", (value) => {
    //         return value[0] != undefined;
    //       })
    //       .test("fileSize", "Max 4 MB is allowed", (value) => {
    //         return value && value[0] && value[0].size <= 4000000;
    //       })
    //       .test("type", "Only wav/mp3/aiff is allowed", (value) => {
    //         return (
    //           value &&
    //           value[0] &&
    //           (value[0].name.split(".")[1] == "wav" ||
    //             value[0].name.split(".")[1] == "mp3" ||
    //             value[0].name.split(".")[1] == "aiff")
    //         );
    //       }),
    //   }),
    videoLink: yup.string().required(),
  });

  const { register, handleSubmit, watch, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("submit", data, tabIndex);
    setIsLoading(true);

    const myRatio =
      props.canvasVals.canvasBackgroundImageWidth /
      props.canvasVals.canvasWidth; //ratio of the book page
    const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000

    const sendData = {
      id: guidGenerator(), //For redux based id generation.
      left: myRatio * parseInt(data.left) * originRatio, // * props.canvasVals.vpW) / 1000, // -
      top: myRatio * parseInt(data.top) * originRatio, // * ratio, // - 24,
      height: 24,
      width: 24,
      show: 1,
      button_type: 'video',
      own_activity: 1,
      style: buttonStyle,
      status: 1,
      sharing: data.public,
      content: data.videoLink,
    };
    console.log("sendData", sendData);
    // return;
    // activityType === "audio" || activityType === "image"
    //   ? tabIndex === 0
    //     ? formData.append("content", {
    //         uri: sendData.content.uri, /// URL
    //         type: sendData.content.type, /// "url"
    //         name: sendData.content.name, // no empty
    //       })
    //     : formData.append("content", sendData.content)
    //   : formData.append("content", sendData.content);

    addButtonFormService(
      sendData,
      String(domain).toLowerCase(),
      props.currentPageId
    )
      .then(async (response) => {
        sendData.id = response.id;
        if (activityType === "audio" || activityType === "image") {
          sendData.content = response.content;
        }

        props.addButtonModalClose();
        props.doneActivity();
        props.insertActivity(sendData, props.currentPageId);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("logged error", error);
        setIsLoading(false);
      });
  };

  const selectButtonStyle = (bstyle) => {
    setButtonStyle(bstyle);
    console.log("bstyle", bstyle);
  };
  const selectOptionPublic = (opt) => {
    setIsPublic(opt);
    console.log("public?", opt);
  };
  const selectOptionVisible = (opt) => {
    setVisible(opt);
    console.log("visible?", opt);
  };

  const uppy = new Uppy({
    meta: { type: "video" },
    restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ["video/*"] },
  });

  // uppy.use(Tus, { endpoint: "/upload" });

  uppy.on("complete", (result) => {
    console.log("successful files:", result.successful);
    console.log("failed files:", result.failed);
    // store.dispatch({
    //   type: "SET_USER_AVATAR_URL",
    //   payload: { url: url },
    // });
  });

  uppy.on("file-added", (file) => {
    console.log("Added file", file);
    setFileName(file.name);
  });

  const oldValue = activityId
    ? props.buttons.filter((btn) => btn.id === activityId)[0]
    : null;
  // console.log(
  //   "oldValue1",
  //   activityId,
  //   oldValue,
  //   props.buttons,
  //   props.buttons.filter((btn) => btn.id === activityId)
  // );
  return (
    <>
      {
        <div className="main-model">
          {/* <label className="label"> */}
          {/* {t(activityType)} */}
          {/* <a
              className="tag is-success has-text-right p-1 ml-2"
              onClick={() => showContent()}
            >
              ?
            </a> */}
          {/* </label> */}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-100">
              <div className="error-message">{errors.fileupload?.message}</div>
              <Tabs defaultIndex={0} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                  {/* <Tab>
                    <span className="is-size-6 m-0">{t("Upload a Video")}</span>
                  </Tab> */}
                  <Tab>
                    <span className="is-size-6 m-0">{t("Video Link")}</span>
                  </Tab>

                  {/* <Tab>
                    <span className="is-size-6 m-0">
                      {t("Pick a Video From Pixabay")}
                    </span>
                  </Tab> */}
                </TabList>

                {/* <TabPanel>
                  <div className="input-group mb-3">
                    <DragDrop
                      uppy={uppy}
                      locale={{
                        strings: {
                          // Text to show on the droppable area.
                          // `%{browse}` is replaced with a link that opens the system file selection dialog.
                          dropHereOr: "Drop here or %{browse}",
                          // Used as the label for the link that opens the system file selection dialog.
                          browse: "browse",
                        },
                      }}
                    />
                    <label className="label" htmlFor="DragDrop">
                      {fileName}
                    </label>
                  </div>
                </TabPanel> */}
                <TabPanel>
                  <div className=" mb-3">
                    <div className="">
                      <div class="field">
                        <span class="tag is-info is-light">
                          {t(
                            "Video Link (Youtube, Vimeo, Facebook, Twitch,SoundCloud, Streamable, Wistia, DailyMotion)"
                          )}
                        </span>
                        <div className="control">
                          <textarea
                            className="textarea is-link"
                            ref={register}
                            name="videoLink"
                            placeholder={
                              t("Please enter a valid link like") +
                              " https://youtu.be/SjiSEvh6fJs"
                            }
                          ></textarea>
                          <div className="error-message">
                            {errors.link?.message}
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        {ReactPlayer.canPlay(watch("videoLink")) ? (
                          <div className="player-wrapper-add-form">
                            <ReactPlayer
                              url={watch("videoLink")}
                              width="100%"
                              height="50%"
                              controls
                            />
                          </div>
                        ) : (
                          <span>
                            {t("Please enter a valid link like") +
                              " https://youtu.be/SjiSEvh6fJs"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
              {/* <hr /> */}
            </div>

            <div className="column box w-100">
              <ButtonStylesBulma
                activityType={activityType}
                selectButtonStyle={selectButtonStyle}
                defaultStyle={activityId ? oldValue.style : "style1"}
              />
            </div>
            <div className="row">
              {/* <div className=""> */}

              {/* <select name="Title" ref={register({ required: true })}>
                  <option value={0}>{t("Private")}</option>
                  <option value={1}>{t("Public")}</option>
                </select> */}
              {/* <SwitchOption
                  activityType={activityType}
                  val1={t("Public")}
                  val2={t("Private")}
                  name="public"
                  ref={register}
                  selectOption={selectOptionPublic}
                  id={1}
                  defaultValue={activityId ? (oldValue.show ? 1 : 0) : 1}
                /> */}
              {/* </div> */}
              <div className="field is-grouped is-grouped-centered w-100">
                <div className="control has-icons-left">
                  <div className="select">
                    <select
                      name="public"
                      ref={register({ required: true })}
                      defaultValue={1}
                    >
                      <option value={0}>{t("Private")}</option>
                      <option value={1}>{t("Public")}</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    {/* {t("Public")} */}
                    <MdPublic className="fas fa-2x" />
                  </span>
                </div>

                <div className="control has-icons-left has-icons-right">
                  <input
                    className="input"
                    // type="email"
                    placeholder=""
                    defaultValue={parseInt(activityTop)}
                    ref={register}
                    type="text"
                    name="top"
                  />
                  <span className="icon is-small is-left">{t("Top")}</span>
                </div>

                {/*<div className="error-message">field is empty.</div>*/}
                <div className="control has-icons-left has-icons-right">
                  <input
                    className="input"
                    // type="email"
                    placeholder=""
                    defaultValue={parseInt(activityLeft)}
                    ref={register}
                    type="text"
                    name="left"
                  />
                  <span className="icon is-small is-left">{t("Left")}</span>
                </div>
              </div>
            </div>
            {/* <SwitchOption
              activityType={activityType}
              val1={t("Visible")}
              val2={t("Hidden")}
              selectOption={selectOptionVisible}
              id={3}
            /> */}
            <div className="field is-grouped w-100">
              <p className="control">
                <button
                  type="submit"
                  value={t("Submit")}
                  className={`button is-info  ${isLoading ? "is-loading" : ""}`}
                  disabled={isLoading}
                >
                  {t("Submit")}
                </button>
              </p>
              <p class="control">
                <button
                  type="reset"
                  value={t("Cancel")}
                  onClick={props.addButtonModalClose}
                  disabled={isLoading}
                  className={`button is-danger  ${
                    isLoading ? "is-loading" : ""
                  }`}
                >
                  {t("Cancel")}
                </button>
              </p>
            </div>
          </form>
        </div>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentPageId: state.pages.currentPage[0].id,
    canvasScaleX: state.scaleZoomFactor.canvas.scaleX,
    canvasScaleY: state.scaleZoomFactor.canvas.scaleY,
    canvasVals: state.scaleZoomFactor.canvas,
    sideBarOpened: state.sideBarOpened,
    pixabay: state.pixabayVideo,
    buttons: state.pages.currentPage[0].buttons,
  };
};

export default connect(mapStateToProps, {
  addButtonModalOpen,
  addButtonModalClose,
  cancelActivity,
  doneActivity,
  insertActivity,
  pullVideosFromPixabay,
  closePixabay,
})(VideoForm);
