import React from "react";
import { useTranslation } from "react-i18next";

export default function FillColor(props) {
  const { t, i18n } = useTranslation();
  // or const [t, i18n] = useTranslation();
  const { object } = props;
  return (
    <li>
      <span
        className={`button normal-btn fill-color ${
          props.activeButton === "fillcolor" ? "active" : ""
        }`}
        title={t("Fill color")}
        onClick={props.onClick}
        data-tip={t("Fill color")}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            className="f-color"
            x="1"
            y="1"
            width="14"
            height="14"
            fill={props.color}
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M10 6L10.191 5.83866L10 5.61262L9.80903 5.83866L10 6ZM10 6C10.191 5.83866 10.191 5.8387 10.191 5.83874L10.1912 5.83888L10.1915 5.83932L10.1928 5.84084L10.1975 5.84642L10.215 5.86751C10.2302 5.88587 10.2523 5.91273 10.2803 5.94727C10.3362 6.01633 10.4157 6.11621 10.5109 6.24046C10.7012 6.48871 10.9553 6.83555 11.2099 7.22924C11.464 7.6222 11.7216 8.06634 11.9163 8.50877C12.1092 8.94706 12.25 9.40447 12.25 9.81818C12.25 11.1353 11.2581 12.25 10 12.25C8.7419 12.25 7.75 11.1353 7.75 9.81818C7.75 9.40447 7.89076 8.94706 8.08369 8.50877C8.27843 8.06634 8.53595 7.6222 8.79007 7.22924C9.04466 6.83555 9.29879 6.48871 9.48909 6.24046C9.58433 6.11621 9.66384 6.01633 9.71974 5.94727C9.7477 5.91273 9.76977 5.88587 9.78497 5.86751L9.80251 5.84642L9.8072 5.84084L9.80848 5.83932L9.80884 5.83888L9.80896 5.83874C9.809 5.8387 9.80903 5.83866 10 6Z"
            fill="white"
            stroke="#A2A4A8"
            strokeWidth="0.5"
          />
          <rect x="0.5" y="0.5" width="15" height="15" stroke="#A2A4A8" />
        </svg>
      </span>
    </li>
  );
}
