import { SHOW_TOAST } from "./actionTypes";
import { toast } from "react-toastify";

export function toastDark(message) {
  toast.dark(message);
  return function (dispatch) {
    dispatch({
      type: SHOW_TOAST,
    });
  };
}
export function toastWarning(message) {
  toast.warning(message);
  return function (dispatch) {
    dispatch({
      type: SHOW_TOAST,
    });
  };
}
export function toaster(message, type, duration = 2500) {
  toast[type](message, { autoClose: duration });
  return function (dispatch) {
    dispatch({
      type: SHOW_TOAST,
    });
  };
}
