import {
  GET_PAGES,
  GET_ZIP,
  PAGE_NO_CHANGE,
  ZIP_DOWN_PROG,
  RESET_PAGES,
  INSERT_ACTIVITY,
  DELETE_ACTIVITY,
} from "../actions/actionTypes";

const defaultPageState = {
  pages: [],
  totalPageNumber: 0,
  currentPageNo: 1,
  currentPage: {},
  zipLoaded: false,
  toc: {},
  icon: "",
  ebookId: null,
  addedActivities: 0,
};
export default (state = defaultPageState, action) => {
  switch (action.type) {
    case RESET_PAGES:
      return {
        pages: [],
        totalPageNumber: 0,
        currentPageNo: 1,
        currentPage: {},
        zipLoaded: false,
        toc: {},
        icon: "",
        ebookId: null,
        addedActivities: 0,
      };
    case GET_PAGES:
      let pages = action.payload.ebook_pages;
      pages.sort((page1, page2) => {
        if (page1.page_number > page2.page_number) return 1;
        if (page1.page_number < page2.page_number) return -1;
        return 0;
      });
      let pagesWithNums = pages.map((page, index) => ({
        ...page,
        pageNumber: index + 1,
      }));

      return {
        pages: pagesWithNums,
        totalPageNumber: pagesWithNums.length,
        currentPageNo: state.currentPageNo,
        currentPage: [pagesWithNums[state.currentPageNo - 1]],
        zipLoaded: state.zipLoaded,
        toc: JSON.parse(action.payload.toc),
        icon: action.payload.icon,
        ebookId: pages[0].ebook_id,
      };
    case INSERT_ACTIVITY:
      let newButton = action.payload[0];
      return {
        ...state,
        currentPage: [
          {
            ...state.currentPage[0],
            buttons: [...state.currentPage[0].buttons, newButton],
          },
        ],
        addedActivities:
          action.payload[0].button_type === "questionHideable"
            ? state.addedActivities + 1
            : state.addedActivities,
      };
    case DELETE_ACTIVITY:
      let buttonToDelete = action.payload;

      return {
        ...state,
        currentPage: [
          {
            ...state.currentPage[0],
            buttons: state.currentPage[0].buttons.filter(
              (btn) => btn.id !== buttonToDelete
            ),
          },
        ],
        // addedActivities: state.addedActivities - 1,
      };

    case PAGE_NO_CHANGE:
      let newPageNo = action.payload;
      // console.log("called me !!!!!!!!", newPageNo, state.totalPageNumber);
      if (newPageNo === 0) {
        newPageNo = 1;
      } else if (state.totalPageNumber === 0) {
      } else if (newPageNo === state.totalPageNumber + 1) {
        newPageNo = state.totalPageNumber;
        // console.log("called me newPageNo === state.totalPageNumber + 1r");
      } else if (newPageNo > state.totalPageNumber) {
        // console.log("called me newPageNo > state.totalPageNumber");
        newPageNo = state.totalPageNumber;
      }
      // console.log('window.location.hash', window.location.hash)
      window.location.hash = `#/cenibook/${newPageNo}`;

      return {
        ...state,
        // pages: state.pages,
        // totalPageNumber: state.totalPageNumber,
        currentPageNo: newPageNo,
        currentPage: [state.pages[newPageNo - 1]],
        // zipLoaded: state.zipLoaded,
        // toc: state.toc,
        // icon: state.icon,
        // pages: state.pages,
        // totalPageNumber: state.totalPageNumber,
        // currentPageNo: action.payload,
        // currentPage: [state.pages[action.payload - 1]],
        // zipLoaded: state.zipLoaded
      };

    case GET_ZIP:
      // var pagesAfterURLChange = state.pages;
      var blobURLs = action.payload;
      var pagesAfterURLChange = state.pages.map((page, ind) => {
        if (blobURLs[ind]) {
          page.path = blobURLs[ind];
        }
      });

      return {
        ...state,
        // pages: state.pages,
        // totalPageNumber: state.totalPageNumber,
        // currentPageNo: state,
        // currentPage: state.currentPage,
        zipLoaded: true,
        // toc: state.toc,
        // icon: state.icon,
      };

    default:
      return state;
  }
};
