import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

 class DrawToolbar extends Component {
  render() {
    return (
      <li className="draw-toolbar-icon">
        <span
          className={`button normal-btn main-button ${this.props.activeButton === 'draw-toolbar' ? 'active' : ''} ${
            this.props.hideButton ? "passive" : ""
          }`}
          title={this.props.t("Draw Toolbar")}
          data-tip={this.props.t("Draw Toolbar")}
          onClick={this.props.onClick}>
          <img
            src={require('../../assets/img/svg/toolbar-main-tools.svg')}
            alt={this.props.t("Draw Toolbar")}
             />
        </span>
      </li>
    )
  }
}
export default withTranslation()(DrawToolbar);
