import {
  SELECTED_TOOL_CHANGED,
  SELECTED_TOOL_CHANGED_FROM_OUTSIDE,
} from "./actionTypes";

export const changeSelectedTool = (selectedTool) => {
  return {
    type: SELECTED_TOOL_CHANGED,
    payload: selectedTool,
  };
};
export const changeSelectedToolFromOutside = (selectedTool) => {
  return {
    type: SELECTED_TOOL_CHANGED_FROM_OUTSIDE,
    payload: selectedTool,
  };
};
// export const changeSelectedSubButton = (button) => {
//   return {
//     type: SELECTED_TOOL_SUB_BUTTON_CHANGED,
//     payload: button,
//   };
// };

// export const changeSelectedSubChildButton = (button) => {
//   return {
//     type: SELECTED_TOOL_SUB_CHILD_BUTTON_CHANGED,
//     payload: button,
//   };
// };

// export const changeSelectedMouseButton = (button) => {
//   return {
//     type: SELECTED_TOOL_MOUSE_BUTTON_CHANGED,
//     payload: button,
//   };
// };
