/*eslint no-unused-vars: 0*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import History from "./history";
import Pan from "./pan";
import Capture from "./capture";
import QHideable from "./qHideable";
import Curtain from "./curtain";
import Pencil from "./pencil";
import Line from "./line";
import Arrow from "./arrow";
import Rectangle from "./rectangle";
import EmptyRectangle from "./empty-rectangle";
import EmptyCircle from "./empty-circle";
import Circle from "./circle";
import Select from "./select";
import HardDrag from "./HardDrag";
import Tool from "./tools";
import Highlighter from "./highlighter";
import AddNote from "./AddNoteSketch";
import AddVideo from "./AddVideoSketch";
import AddH5p from "./AddH5pSketch";
import AddAudio from "./AddAudioSketch";
import AddImage from "./AddImageSketch";
import Text from "./text";
import "./main.css";
import { connect } from "react-redux";
import { pageNoChange } from "../../actions/pages";
import { toggleButtons } from "../../actions/buttonShowHide";
import { changeSelectedToolFromOutside } from "../../actions/selectedTool";
import { setScalePageDown } from "../../actions/scalePageDown";
import { setScalePageUp } from "../../actions/scalePageUp";
import { setScalePageSet } from "../../actions/scalePageReset";
import { setScalePageScl } from "../../actions/scalePageSetScl";
import { toggleCapture } from "../../actions/captureStatus";
import { addActivity } from "../../actions/addActivity";
import { addMcQuestionModalOpen } from "../../actions/quiz";
import { addButtonModalOpen } from "../../actions/addButton";
import {
  hideInteractiveObjects,
  showInteractiveObjects,
} from "../../actions/interactiveObjects";
import { Annotation } from "./Annotation";
import {
  bookmarkPage,
  unBookmarkPage,
  isPageBookmarked,
} from "../../services/util";
import {
  setAnnotationSelectionMode,
  setAnnotationSelectionCoords,
  saveAnnotationSelectionToButton,
  setEditSelectionMode,
  setAnnotationBeforeState,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setSelectionPasteMode,
} from "../../actions/annotationSelection";
import { guidGenerator } from "../../services/util";
import {
  triggerRedo,
  triggerUndo,
  triggerRemove,
  triggerRemoveAll,
  setImageLoaded,
  showAnnotations,
  hideAnnotations,
  setPagePartZoomMode,
  setPagePartZoomObject,
} from "../../actions/settings";
import { toaster } from "../../actions/showToast";
import {
  nothingSelectedMessage,
  cantDeleteMessage,
} from "../../services/constants";
import { toggleSideBar } from "../../actions/sidebar";
import {
  setToolbarPosition,
  resetToolbarPosition,
  setToolbarVertical,
} from "../../actions/toolbar";

const fabric = require("fabric").fabric;
const IMAGESIZE = 1000;
/**
 * Sketch Tool based on FabricJS for React Applications
 */
class SketchField extends PureComponent {
  static propTypes = {
    // the color of the line
    lineColor: PropTypes.string,
    // The width of the line
    lineWidth: PropTypes.number,
    // the fill color of the shape when applicable
    fillColor: PropTypes.string,
    // the background color of the sketch
    backgroundColor: PropTypes.string,
    // the opacity of the object
    opacity: PropTypes.number,
    // number of undo/redo steps to maintain
    undoSteps: PropTypes.number,
    // The tool to use, can be pencil, rectangle, circle, brush;
    tool: PropTypes.string,
    // image format when calling toDataURL
    imageFormat: PropTypes.string,
    // Sketch data for controlling sketch from
    // outside the component
    value: PropTypes.object,
    // Set to true if you wish to force load the given value, even if it is  the same
    forceValue: PropTypes.bool,
    // Specify some width correction which will be applied on auto resize
    widthCorrection: PropTypes.number,
    // Specify some height correction which will be applied on auto resize
    heightCorrection: PropTypes.number,
    // Specify action on change
    onChange: PropTypes.func,
    // Default initial value
    defaultValue: PropTypes.object,
    // Sketch width
    width: PropTypes.number,
    // Sketch height
    height: PropTypes.number,
    // Class name to pass to container div of canvas
    className: PropTypes.string,
    // Style options to pass to container div of canvas
    style: PropTypes.object,
  };

  static defaultProps = {
    lineColor: "black",
    lineWidth: 10,
    fillColor: "transparent",
    backgroundColor: "transparent",
    opacity: 1.0,
    undoSteps: 25,
    // tool: Tool.Select,
    widthCorrection: 2,
    heightCorrection: 0,
    forceValue: false,
  };

  state = {
    action: true,
    annotationList: [],
    deneme: 0,
    scl: 1,
    objectSelected: false,
    downCoords: {
      x: 0,
      y: 0,
    },
    previousPageScale: 1,
    tempImage: null,
    isLoading: true,
    lockScaleConversion: false,
  };
  _initTools = (fabricCanvas) => {
    this._tools = {};
    this._tools[Tool.Capture] = new Capture(fabricCanvas);
    this._tools[Tool.Curtain] = new Curtain(fabricCanvas);
    this._tools[Tool.Select] = new Select(fabricCanvas);
    this._tools[Tool.Pencil] = new Pencil(fabricCanvas);
    this._tools[Tool.Line] = new Line(fabricCanvas);
    this._tools[Tool.Arrow] = new Arrow(fabricCanvas);
    this._tools[Tool.Rectangle] = new Rectangle(fabricCanvas);
    this._tools[Tool.EmptyRectangle] = new EmptyRectangle(fabricCanvas);
    this._tools[Tool.EmptyCircle] = new EmptyCircle(fabricCanvas);
    this._tools[Tool.Circle] = new Circle(fabricCanvas);
    this._tools[Tool.Pan] = new Pan(fabricCanvas);
    this._tools[Tool.HardDrag] = new HardDrag(fabricCanvas);
    this._tools[Tool.Highlighter] = new Highlighter(fabricCanvas);
    this._tools[Tool.AddNote] = new AddNote(fabricCanvas);
    this._tools[Tool.AddVideo] = new AddVideo(fabricCanvas);
    this._tools[Tool.AddH5p] = new AddH5p(fabricCanvas);
    this._tools[Tool.AddAudio] = new AddAudio(fabricCanvas);
    this._tools[Tool.AddImage] = new AddImage(fabricCanvas);
    this._tools[Tool.AddMCQuestion] = new AddImage(fabricCanvas);
    this._tools[Tool.Text] = new Text(fabricCanvas);
    this._tools[Tool.AddMRQuestion] = new AddImage(fabricCanvas);
    this._tools[Tool.QHideable] = new QHideable(fabricCanvas);
  };

  /**
   * Enable touch Scrolling on Canvas
   */
  enableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) return;
    canvas.allowTouchScrolling = true;
  };

  /**
   * Disable touch Scrolling on Canvas
   */
  disableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false;
    }
  };

  /**
   * Add an image as object to the canvas
   *
   * @param dataUrl the image url or Data Url
   * @param options object to pass and change some options when loading image, the format of the object is:
   *
   * {
   *   left: <Number: distance from left of canvas>,
   *   top: <Number: distance from top of canvas>,
   *   scale: <Number: initial scale of image>
   * }
   */

  /**
   * Action when an object is added to the canvas
   */
  _onPathCreated = (e) => {
    this.saveCanvas();
    if (!this.state.action) {
      this.setState({ action: true });
      return;
    }
    let obj = e.path;
    obj.__version = 1;
    // record current object state as json and save as originalState
    let objState = obj.toJSON();
    obj.__originalState = objState;
    let state = JSON.stringify(objState);
    // object, previous state, current state
    this._history.keep([obj, state, state]);
  };

  _afterQHideableClose = async () => {
    // if (!this.props.annotationSelectionMode) {
    const canvas = this._fc;

    await this._fc.forEachObject((obj) => {
      if (obj.qId === this.props.annotationSelections.currentSelection.qId) {
        obj.canvasWidth = this.props.scaleZoomFactorVal.canvas.canvasWidth; //*
        //this.props.annotationSelections.currentSelection.proportion;
        obj.scaleX =
          obj.scaleX * parseFloat(this.props.scaleZoomFactorVal.scaleFactor);
        // obj.left =
        //   (obj.left * 1) /
        //   this.props.annotationSelections.currentSelection.proportion;
        // obj.top =
        //   (obj.top * 1) /
        //   this.props.annotationSelections.currentSelection.proportion;
        console.log("~~~~~~~~~~~object: ", obj.qId ? false : true, obj);
        return obj;
      }
      // obj.visible = false; //obj.qId ? false : true;
    });

    await canvas.discardActiveObject();
    console.log(
      "canvas.getObjects().filter((ob) => ob.visible",
      canvas.getObjects().filter((ob) => ob.visible),
      canvas.getObjects().filter((ob) => ob.visible).length
    );
    if (canvas.getObjects().filter((ob) => ob.visible).length > 0) {
      let sel = await new fabric.ActiveSelection(
        canvas.getObjects().filter((ob) => ob.visible),
        {
          canvas: canvas,
        }
      );

      console.log("---->sel", sel, sel.length);
      console.log("---->sel", sel, sel.length);
      await canvas.setActiveObject(sel);
      await canvas.requestRenderAll();
      await this.group();
      const annotObjects = await this._fc
        ?.toJSON([
          "isCapture",
          "oldScaleX",
          "oldScaleY",
          "canvasWidth",
          "canvasHeight",
          // "isQuestion",
          "qId", //if its added on question selection annot mode
        ])
        .objects.filter((ann) => {
          console.log("ann", ann);
          return ann.visible;
        });
      // .map((ob) => (ob.qId = guidGenerator()));
      console.log("---------->>>>>>>>> ", annotObjects[0]);
      console.log(
        "---------->>>>>>>>> ",
        this.props.annotationSelections.currentSelection.top,
        this.props.annotationSelections.currentSelection.left
      );
      annotObjects[0].left =
        this.props.annotationSelections.currentSelection.left +
        annotObjects[0].left; //*
      // (1 / this.props.annotationSelections.currentSelection.proportion);
      annotObjects[0].top =
        this.props.annotationSelections.currentSelection.top +
        annotObjects[0].top; //*
      // (1 / this.props.annotationSelections.currentSelection.proportion);
      annotObjects.length > 0 &&
        (await this.props.saveAnnotationSelectionToButton(annotObjects));

      await canvas.getActiveObjects().forEach(function (object) {
        canvas.remove(object);
      });
      this.props.showInteractiveObjects();
      this.reloadDraw();
      // console.log("2---------->>>>>>>>> ", annotObjects);
    }
    // await this._fc.forEachObject((obj) => {
    //   if (obj.visible) {
    //     obj.__removed = true;
    //   }
    //   // obj.visible = false; //obj.qId ? false : true;
    // });

    //scaleZoomFactorVal
    // hideInteractiveObjectsVal
    // showAnnotations

    await this.props.setScalePageSet(this.props.scaleZoomFactorVal.scaleFactor);

    this.props.annotationSelections.stateBefore.drawingState
      ? this.props.showAnnotations()
      : this.props.hideAnnotations();

    // this.props.annotationSelections.stateBefore.buttonState
    //   ? this.props.hideInteractiveObjects()
    //   : this.props.showInteractiveObjects();

    // console.log("this._fc.getObjects()", this._fc.getObjects());

    // this._fc.renderAll();
    // }
  };
  _afterPagePartZoomClose = async () => {
    this.setThumbnailBGFromDataUrl(
      this.props.image.thumbnail,
      this.props.image.path,
      {}
      // reloadDraw()
    );

    // _sketch.current.setBackgroundFromDataUrl(props.image.path, {}, _setBg);
  };

  _onObjectAdded = (e) => {
    //after:render

    let obj = e.target;
    if (obj.fromJson === true) return;
    if (this.props.annotationSelectionMode) {
      obj.qId = this.props.annotationSelections.currentSelection.qId;
    }
    if (this.props.pageZoomPartMode) {
      obj.zoomPagePartId = 111;
    }
    console.log("obj.fromJson", obj.fromJson, obj.qId);
    obj.canvasWidth = this.props.setAnnotationSelectionMode
      ? this.props.scaleZoomFactorVal.canvas.canvasWidth // *
      : // this.props.annotationSelections.currentSelection.proportion
        this._fc.getWidth();
    obj.oldScaleX = 1;
    obj.oldScaleY = 1;
    console.log("object added????");
    console.log("object added????", obj, this._fc.getObjects());

    // console.log("object:added", e.target);
    if (
      this._selectedTool == this._tools[Tool.Rectangle] ||
      this._selectedTool == this._tools[Tool.Circle] ||
      this._selectedTool == this._tools[Tool.EmptyRectangle] ||
      this._selectedTool == this._tools[Tool.EmptyCircle]
    ) {
      setTimeout(() => {
        this.saveCanvas();
      }, 1500);
    } else {
      this.saveCanvas();
    }

    if (!this.state.action) {
      this.setState({ action: true });
      return;
    }

    obj.__version = 1;
    // record current object state as json and save as originalState
    let objState = obj.toJSON();
    obj.__originalState = objState;
    let state = JSON.stringify(objState);
    // object, previous state, current state
    this._history.keep([obj, state, state]);
  };

  /**
   * Action when an object is scaling inside the canvas
   */
  _onObjectScaling = (e) => {
    // this.saveCanvas();
  };

  /**
   * Action when an object is rotating inside the canvas
   */
  _onObjectRotating = (e) => {
    // this.saveCanvas();
  };

  _onObjectModified = (e) => {
    // console.log(" modified????", e);
    let obj = e.target;
    obj.__version += 1;
    let prevState = JSON.stringify(obj.__originalState);
    let objState = obj.toJSON();
    // record current object state as json and update to originalState
    obj.__originalState = objState;
    let currState = JSON.stringify(objState);
    this._history.keep([obj, prevState, currState]);

    this.saveCanvas();
  };
  _onObjectScaled = (e) => {
    // return;
    if (this.state.lockScaleConversion) return;

    let obj = e.target;
    console.log("OBJECT SCALED");
    setTimeout(() => {
      if (obj.type === "rect") {
        obj.set({
          width: obj.width * obj.scaleX,
          height: obj.height * obj.scaleY,
        });
        obj.scaleX = obj.scaleY = 1;
      } else {
        obj.oldScaleX = obj.scaleX;
        obj.oldScaleY = obj.scaleY;
      }

      console.log("OBJECT SCALED", obj);

      obj.setCoords();
      this._fc.calcOffset();
      this._fc.renderAll();
      obj.__version += 1;
      let prevState = JSON.stringify(obj.__originalState);
      let objState = obj.toJSON();
      // record current object state as json and update to originalState
      obj.__originalState = objState;
      let currState = JSON.stringify(objState);
      this._history.keep([obj, prevState, currState]);

      this.saveCanvas();
    }, 100);
  };

  /**
   * Action when an object is removed from the canvas
   */
  _onObjectRemoved = (e) => {
    // console.log('removed');
    let obj = e.target;
    this.saveCanvas();
    if (obj.__removed) {
      obj.__version += 1;
      return;
    }
    obj.__version = 0;
  };

  /**
   * Action when the mouse button is pressed down
   */
  _onMouseDown = (e) => {
    console.log("this._selectedTool", this._selectedTool);
    if (!this.props.modalOpen) {
      switch (this._selectedTool) {
        case this._tools[Tool.AddNote]:
          this.props.addActivity(
            "text",
            e.pointer.y,
            e.pointer.x,
            "Note Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddVideo]:
          this.props.addActivity(
            "video",
            e.pointer.y,
            e.pointer.x,
            "Video Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddH5p]:
          this.props.addActivity(
            "h5p",
            e.pointer.y,
            e.pointer.x,
            "Embed Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddAudio]:
          this.props.addActivity(
            "audio",
            e.pointer.y,
            e.pointer.x,
            "Audio Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddImage]:
          this.props.addActivity(
            "image",
            e.pointer.y,
            e.pointer.x,
            "Image Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddMCQuestion]:
          this.props.addActivity(
            "mc_question",
            e.pointer.y,
            e.pointer.x,
            "Multiple Choice Question"
          );
          this.props.addMcQuestionModalOpen();
          break;
        case this._tools[Tool.AddMRQuestion]:
          this.props.addActivity(
            "mr_question",
            e.pointer.y,
            e.pointer.x,
            "Multiple Response Question"
          );
          this.props.addMcQuestionModalOpen();
          break;
        case this._tools[Tool.HardDrag]:
          this._selectedTool.doMouseDown(e);
          break;

        case this._tools[Tool.QHideable]:
          this.onChangeDrawingObjects(false);
          let canvas = this._fc;
          console.log("e", e);
          this.setState({
            downCoords: {
              x: e.pointer.x,
              y: e.pointer.y,
            },
          });
          if (canvas._iTextInstances) {
            canvas._iTextInstances.forEach(function (ob) {
              ob.exitEditing();
            });
          }
          this._selectedTool.doMouseDown(e);

          break;

        default:
          this._selectedTool.doMouseDown(e);
          // console.log("this._selectedTool", this._selectedTool);
          break;
      }
    }
  };

  /**
   * Action when the mouse button double clicked
   */

  _onMouseDblclick = (e) => {
    let canvas = this._fc;
    console.log("canvas", canvas);
    if (this.props.captured) {
      this.props.changeSelectedToolFromOutside("select");
      this.props.showInteractiveObjects();
      this.props.toggleCapture(this.props.captured);
      canvas.backgroundImage.set("opacity", 1);

      canvas.renderAll();
    }
  };

  _onMouseMove = (e) => {
    if (this._selectedTool === this._tools[Tool.Pan]) {
    } else {
      this._selectedTool.doMouseMove(e);
    }
  };

  /**
   * Action when the mouse cursor is moving out from the canvas
   */
  _onMouseOut = (e) => {
    this._selectedTool.doMouseOut(e);
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
  };

  _onMouseUp = (e) => {
    if (
      this._selectedTool == this._tools[Tool.Rectangle] ||
      this._selectedTool == this._tools[Tool.Circle] ||
      this._selectedTool == this._tools[Tool.EmptyRectangle] ||
      this._selectedTool == this._tools[Tool.EmptyCircle]
    ) {
      this._selectedTool.doMouseUp(e);
      setTimeout(() => {
        this.saveCanvas();
      }, 500);
    } else if (this._selectedTool === this._tools[Tool.Capture]) {
      this._selectedTool.doMouseUp(e, this.props.imagePath);
      setTimeout(() => {
        this.props.changeSelectedToolFromOutside("select");
        let paGe = document.getElementById("pdfPageList");

        paGe.scrollTop = 0;
        paGe.scrollLeft = 0;

        if (!this.props.hideInteractiveObjectsVal) {
          this.props.hideInteractiveObjects();
          this.props.toggleCapture(true);
        }
      }, 500);
    } else if (this._selectedTool === this._tools[Tool.QHideable]) {
      // this.qHideableMouseUp(e, this.props.imagePath);
      this.qZoomMouseUp(e, this.props.imagePath);

      setTimeout(() => {
        this.props.changeSelectedToolFromOutside("select");
        if (!this.props.hideInteractiveObjectsVal) {
          this.props.hideInteractiveObjects();
          this.props.toggleCapture(true);
        }
      }, 500);
    } else if (this._selectedTool === this._tools[Tool.Pan]) {
    } else {
      this._selectedTool.doMouseUp(e);
    }

    // Update the final state to new-generated object
    // Ignore Path object since it would be created after mouseUp
    // Assumed the last object in canvas.getObjects() in the newest object
    if (this.props.tool !== Tool.Pencil) {
      const canvas = this._fc;
      const objects = canvas.getObjects();
      const newObj = objects[objects.length - 1];
      if (newObj && newObj.__version === 1) {
        newObj.__originalState = newObj.toJSON();
      }
    }
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
  };

  qHideableMouseUp = (o, imagePath) => {
    this.props.toggleSideBar(true);
    this.props.setToolbarVertical(true);
    this.props.setToolbarPosition({ x: 100, y: 0 });
    const { downCoords } = this.state;
    console.log("downCoords", downCoords);
    console.log("o", o);
    console.log("o.eclientX", o.pointer.x, o.pointer.y);
    console.log(
      "o.pointer.X - downCoords.x",
      o.pointer.x,
      downCoords.x,
      o.pointer.x - downCoords.x
    );
    console.log(
      "(o.pointer.y - downCoords.y)",
      o.pointer.y,
      downCoords.y,
      o.pointer.y - downCoords.y
    );
    let actualWidth = this._fc.backgroundImage.getScaledWidth();
    let proportion =
      this._fc.backgroundImage.getOriginalSize().width / actualWidth;
    let proportionY =
      this._fc.backgroundImage.getOriginalSize().height /
      this._fc.backgroundImage.getScaledHeight();

    if (
      Math.abs(o.pointer.x - downCoords.x) < 10 ||
      Math.abs(o.pointer.y - downCoords.y) < 10
    )
      return;

    let canvas = this._fc;

    console.log("proportion", proportion, proportionY);

    const iWidth = (o.pointer.x - downCoords.x) * proportion;
    const iHeight = (o.pointer.y - downCoords.y) * proportionY;
    const cropX = downCoords.x * proportion;
    const cropY = downCoords.y * proportion;
    console.log("iWidth, iHeight, cropX, cropY", iWidth, iHeight, cropX, cropY);
    fabric.Image.fromURL(
      imagePath,
      (img) => {
        img.set({
          left: 0,
          top: 0,
          cropX,
          cropY,
          width: iWidth,
          height: iHeight,
          isQuestion: true,
          scaleX: 1 / proportion,
          scaleY: 1 / proportionY,
        });
        canvas.setBackgroundImage(
          img,
          () => {
            canvas.setWidth((img.width * 1) / proportion);
            canvas.setHeight((img.height * 1) / proportionY);
          },
          { excludeFromExport: true }
        );

        this.props.setAnnotationSelectionMode(true);

        this.props.setAnnotationSelectionCoords(
          (cropY * 1) / proportion,
          (cropX * 1) / proportion,
          iWidth,
          iHeight,
          guidGenerator(),
          proportion
        );

        canvas.renderAll();

        if (
          window.innerWidth / img.width > 1.25 &&
          window.innerHeight / img.height > 1.25
        ) {
          this.setState({
            previousPageScale: this.props.scaleZoomFactorVal.scaleFactor,
          });
          // this.props.setScalePageUp(this.props.scaleZoomFactorVal.scaleFactor);
        }
      },
      { crossOrigin: "anonymous" }
    );
  };

  qZoomMouseUp = (o, imagePath) => {
    this.props.toggleSideBar(true);
    this.props.setToolbarVertical(true);
    this.props.setToolbarPosition({ x: 100, y: 0 });
    const { downCoords } = this.state;

    let actualWidth = this._fc.backgroundImage.getScaledWidth();
    let proportion =
      this._fc.backgroundImage.getOriginalSize().width / actualWidth;
    let proportionY =
      this._fc.backgroundImage.getOriginalSize().height /
      this._fc.backgroundImage.getScaledHeight();

    if (
      Math.abs(o.pointer.x - downCoords.x) < 10 ||
      Math.abs(o.pointer.y - downCoords.y) < 10
    )
      return;

    this.props.setPagePartZoomMode("zoomed");
    let canvas = this._fc;

    const iWidth = (o.pointer.x - downCoords.x) * proportion;
    const iHeight = (o.pointer.y - downCoords.y) * proportionY;
    const cropX = downCoords.x * proportion;
    const cropY = downCoords.y * proportion;

    const fullScale = Math.min(
      canvas.width / iWidth,
      (window.innerHeight - 50) / iHeight
    );

    // console.log("fullScale, proportion", fullScale, proportion, 1 / proportion);
    fabric.Image.fromURL(
      imagePath,
      (img) => {
        img.set({
          left: 0,
          top: 0,
          cropX,
          cropY,
          width: iWidth,
          height: iHeight,
          zoomPagePart: true, //TODO change
          scaleX: fullScale,
          scaleY: fullScale,
        });

        canvas.add(img).setActiveObject(img);
        canvas.getActiveObject().setOptions({
          zoomPagePart: true, //TODO change
        });

        // canvas.setBackgroundColor("#fff");
        // canvas.backgroundImage.set("opacity", 0);

        this.props.setPagePartZoomObject(1, downCoords, o.pointer);
        canvas.renderAll();
      },
      { crossOrigin: "anonymous" }
    );
  };
  /**
   * Track the resize of the window and update our state
   *
   * @param e the resize event
   * @private
   */

  _resize = (e, shouldReRender = false) => {
    try {
      if (this.props.annotationSelectionMode) return;
      // console.log("resize???", e);
      if (e) e.preventDefault();
      let canvas = this._fc;

      let diffSize = 0;
      if (window.innerWidth < 1024) {
        this.vpW = window.innerWidth - 85;
        diffSize = -85;
      } else {
        this.vpW = window.innerWidth - 65;
        diffSize = -65;
      }

      if (
        this.props.sidebarIsOpened
        // && window.innerWidth < 640
      ) {
        this.vpW -= 200;
        diffSize -= 200;
      }

      if (this.vpW < 640) this.vpW = 640;

      this.vpH = window.innerHeight;

      if (
        !shouldReRender &&
        this.vpW === this.props.scaleZoomFactorVal.canvas.vpW &&
        this.vpH === this.props.scaleZoomFactorVal.canvas.vpH &&
        this.props.scaleZoomFactorVal.canvas.canvasBackgroundImageWidth > 250
      ) {
        if (canvas.backgroundImage) {
          canvas.backgroundImage.scaleToWidth(canvas.getWidth());
          // canvas.setHeight(canvas.backgroundImage.getScaledHeight());
        }
        setTimeout(() => {
          this.setCanvasObjectSizes();
        }, 100);

        canvas.renderAll();
        return;
      }
      let sclOld = (this.vpW - IMAGESIZE) / IMAGESIZE;

      this.props.interactiveButtonsSizeChanged(sclOld);

      this.setState({ scl: sclOld });

      canvas.setWidth(this.vpW);
      canvas.setHeight(this.vpH);
      if (canvas.backgroundImage) {
        canvas.backgroundImage.scaleToWidth(canvas.getWidth());
        canvas.setHeight(canvas.backgroundImage.getScaledHeight());
      }
      sclOld &&
        this.props.setScalePageScl(
          sclOld,
          canvas.backgroundImage ? canvas.backgroundImage.scaleX : null,
          canvas.backgroundImage ? canvas.backgroundImage.scaleY : null,
          this.vpW,
          this.vpH,
          canvas.width,
          canvas.height,
          canvas.backgroundImage.width,
          canvas.backgroundImage.height
        );
      // return;
      // this.setCanvasObjectSizes();
      // setTimeout(() => {
      this.setCanvasObjectSizes(shouldReRender);
      // }, 100);

      canvas.renderAll();
    } catch (error) {
      console.log("error", error);
    }
  };

  toggleQHideables = () => {
    console.log("toggleQHideables", this.props.annotationIdToShow);
    const canvas = this._fc;
    canvas.forEachObject((obj) => {
      // console.log("obj", obj);
      if (obj.qId) {
        console.log(
          "obj.qId",
          obj.qId,
          this.props.annotationIdToShow,
          obj.visible
        );
        obj.visible =
          obj.qId === this.props.annotationIdToShow
            ? !obj.visible
            : obj.visible;
      }
    });
    canvas.renderAll();
  };

  setCanvasObjectSizes = async (shouldReRender = false) => {
    // return;
    // console.log(">>>>>>>>>setCanvasObjectSizes");
    const canvas = this._fc;
    await this.setState({
      lockScaleConversion: true,
    });
    // let previuosTrueObjectanvasWidth
    await canvas.forEachObject((obj) => {
      try {
        const newRatioX = canvas.getWidth() / obj.canvasWidth || 1;
        if (shouldReRender)
          obj.visible = this.props.annotationSelectionMode
            ? obj.qId
              ? true
              : false
            : obj.qId
            ? this.props.annotationIdToShow === obj.qId
              ? true
              : false
            : true;
        if (newRatioX !== 1) {
          console.log(
            canvas.getWidth(),
            obj.type,
            obj.canvasWidth,
            "newRatioX",
            newRatioX
          );
          obj.canvasWidth = canvas.getWidth();
          obj.set("scaleX", obj.scaleX * newRatioX);
          obj.set("scaleY", obj.scaleY * newRatioX);

          obj.set("left", obj.left * newRatioX);
          obj.set("top", obj.top * newRatioX);
          obj.setCoords();
          this._fc.calcOffset();
        }
        this._fc.renderAll();
      } catch (err) {
        console.error(err);
      }
    });
    await this.setState({
      lockScaleConversion: false,
    });
  };

  objInArray(obj, annotationArray) {
    for (var i = 0; i < annotationArray.length; i++) {
      if (annotationArray[i].obj === obj) {
        return i;
      }
    }
    return -1;
  }

  /**
   * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
   */
  undo = () => {
    try {
      let history = this._history;
      if (!history.current) return false;
      let [obj, prevState, currState] = history.getCurrent();
      history.undo();
      if (obj.__removed) {
        this.setState({ action: false }, () => {
          this._fc.add(obj);
          obj.__version -= 1;
          obj.__removed = false;
        });
      } else if (obj.__version <= 1 && !obj.zoomPagePart) {
        this._fc.remove(obj);
      } else {
        obj.__version -= 1;
        obj.setOptions(JSON.parse(prevState));
        obj.setCoords();
        this._fc.renderAll();
      }
      if (this.props.onChange) {
        this.props.onChange();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
   */
  redo = () => {
    try {
      let history = this._history;
      if (history.canRedo()) {
        let canvas = this._fc;
        //noinspection Eslint
        let [obj, prevState, currState] = history.redo();
        if (obj.__version === 0) {
          this.setState({ action: false }, () => {
            canvas.add(obj);
            obj.__version = 1;
          });
        } else {
          obj.__version += 1;
          obj.setOptions(JSON.parse(currState));
        }
        obj.setCoords();
        canvas.renderAll();
        if (this.props.onChange) {
          this.props.onChange();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can undo otherwise false
   */
  canUndo = () => {
    return this._history.canUndo();
  };

  /**
   * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can redo otherwise false
   */
  canRedo = () => {
    return this._history.canRedo();
  };

  /**
   *
   * @returns {String} URL containing a representation of the object in the format specified by options.format
   */
  toDataURL = (options) => this._fc.toDataURL(options);

  /**
   * Returns JSON representation of canvas
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string
   */
  toJSON = (propertiesToInclude) => this._fc.toJSON(propertiesToInclude);

  /**
   * Populates canvas with data from the specified JSON.
   *
   * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
   *
   * @param json JSON string or object
   */
  fromJSON = (json) => {
    console.log("JSONDAAAN");
    if (!json) return;
    let canvas = this._fc;
    setTimeout(() => {
      canvas.loadFromJSON(json, () => {
        canvas.renderAll();
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
    }, 100);
  };

  /**
   * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
   * used as needed in order to undo the clear if possible
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string of the canvas just cleared
   */
  clear = (propertiesToInclude) => {
    let discarded = this.toJSON(propertiesToInclude);
    this._fc.clear();
    this._history.clear();
    this.saveCanvas();
    return discarded;
  };

  /**
   * Remove all objects from the canvas
   */
  removeAllObjects = () => {
    let canvas = this._fc;
    let objs = canvas.getObjects();
    objs.forEach((obj) => {
      obj.__removed = true;
      let objState = obj.toJSON();
      obj.__originalState = objState;
      let state = JSON.stringify(objState);
      this._history.keep([obj, state, state]);
      canvas.remove(obj);
    });
    this.saveCanvas();
    canvas.discardActiveObject();
    canvas.requestRenderAll();
  };

  /**
   * Hides/shows all drawing objects from the canvas
   */
  onChangeDrawingObjects = (visible) => {
    console.log("~~~~~~~~~visible", visible);
    let canvas = this._fc;
    canvas.selection = false;
    canvas.forEachObject((obj) => {
      obj.visible = visible;
      // canvas.remove(obj);
    });
    canvas.requestRenderAll();
  };

  /**
   * Remove selected object from the canvas
   */
  removeSelected = () => {
    let canvas = this._fc;
    let activeObj = canvas.getActiveObject();

    if (activeObj) {
      let selected = [];
      if (activeObj.type === "activeSelection") {
        activeObj.forEachObject((obj) => selected.push(obj));
      } else {
        selected.push(activeObj);
      }
      selected.forEach((obj) => {
        if (!obj.zoomPagePart) {
          obj.__removed = true;
          let objState = obj.toJSON();
          obj.__originalState = objState;
          let state = JSON.stringify(objState);
          this._history.keep([obj, state, state]);
          canvas.remove(obj);
        } else {
          this.props.toaster(cantDeleteMessage, "warning", 3500);
        }
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
      // this.saveCanvas()
    } else {
      this.props.toaster(nothingSelectedMessage, "warning", 3500);
    }
  };
  changeSelected = (action = "fillColor") => {
    let canvas = this._fc;
    let activeObj = canvas.getActiveObject();

    if (activeObj) {
      let selected = [];
      if (activeObj.type === "activeSelection") {
        activeObj.forEachObject((obj) => selected.push(obj));
      } else {
        selected.push(activeObj);
      }
      selected.forEach((obj) => {
        switch (action) {
          case "fillColor":
            obj.set({ fill: this.props.toolbar.fillColor });
            break;
          case "lineColor":
            obj.set({ stroke: this.props.toolbar.lineColor });
            break;
          case "lineWidth":
            obj.set({ strokeWidth: this.props.toolbar.lineWidth });
            break;
          default:
            break;
        }
      });
      canvas.renderAll();
    }
  };

  onKeyUp = (event) => {
    let canvas = this._fc;
    console.log("event.keyCode: ", event.keyCode);
    if (!event) return;
    let inText = this._selectedTool != this._tools[Tool.Text];
    if (!inText) {
      return;
    }

    switch (event.keyCode) {
      case 27:
        //escape

        this.props.changeSelectedToolFromOutside("select");
        if (this.props.hideInteractiveObjectsVal && this.props.captured) {
          this.props.hideInteractiveObjects();
          this.props.toggleCapture(this.props.captured);
          canvas.backgroundImage.set("opacity", 1);
        }
        canvas.renderAll();
        break;

      case 46:
        this.removeSelected();
        if (this.props.captured) {
          this.props.showInteractiveObjects();
          this.props.toggleCapture(this.props.captured);
          canvas.backgroundImage.set("opacity", 1);
        }
        break;

      case 8:
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.removeSelected();
        }

        if (this.props.captured) {
          this.props.showInteractiveObjects();
          this.props.toggleCapture(this.props.captured);
          canvas.backgroundImage.set("opacity", 1);
        }
        break;
      case 39:
        //next page
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.pageNoChange(parseInt(this.props.currentPageNumber) + 1);
        }

        break;
      case 37:
        //prev page
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.pageNoChange(parseInt(this.props.currentPageNumber) - 1);
        }
        break;
      case 72:
        //Highlight
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("highlighter");
        }
        break;

      case 84:
        //Text
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("text");
        }
        break;
      case 83:
        //Select
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("select");
        }
        break;
      case 80:
        //Pen
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("pencil");
        }
        break;
      case 70:
        //Full Screen
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          document.getElementById("fullscreen-btn").click();
        }
        break;
      case 90:
        //Zoom In
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.setScalePageUp(this.props.scaleZoomFactorVal.scaleFactor);
        }
        break;
      case 88:
        //Zoom Out
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.setScalePageDown(
            this.props.scaleZoomFactorVal.scaleFactor
          );
        }
        break;
      case 67:
        //Capture
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("capture");
        }
        break;
      case 68:
        //Drag the page
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("pan");
        }
        break;
      case 66:
        //Bookmark the page
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          if (!isPageBookmarked(this.props.currentPageNumber)) {
            bookmarkPage(this.props.currentPageNumber);
            document
              .getElementsByClassName("bookmark")[0]
              .classList.add("active");
          } else {
            unBookmarkPage(this.props.currentPageNumber);
            document
              .getElementsByClassName("bookmark")[0]
              .classList.remove("active");
          }
        }
      case 86:
        //Change tool to Select
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("select"); //TODO change toolbar active button
        }
        break;

      case 82:
        //rectangle
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("rectangle");
        }
        break;

      case 49:
        // const obj = this._fc.getActiveObject();
        // obj.set("scaleX", 2);
        // obj.set("scaleY", 2);
        this._fc.add(
          new fabric.Circle({
            radius: 40,
            left: 50,
            top: 50,
            fill: "rgb(0,255,0)",
            opacity: 0.5,
          })
        );

        // obj.set("left", scaleX);
        // obj.set("top", scaleY);
        // obj.setCoords();
        // console.log("actObj", obj);
        this._fc.renderAll();
        //escape
        // canvas.setZoom(scaleRatio)

        fabric.loadSVGFromURL(
          "http://fabricjs.com/assets/1.svg",
          function (objects, options) {
            var obj = fabric.util.groupSVGElements(objects, options);
            canvas.add(obj).renderAll();
          }
        );
        break;

      case 50: //2
        // const obj = this._fc.getActiveObject();
        // console.log(obj.toJSON());
        console.log("canvas.getObjects()", canvas.getObjects());

        break;
      case 51: //3
        console.log("canvas.toSVG();", canvas.toSVG());
        break;
      case 53: //5
        // console.log("canvas.getObjects()", canvas.getObjects());
        this.copy();
        break;
      case 52: //4
        fabric.loadSVGFromURL(
          "http://fabricjs.com/assets/1.svg",
          function (objects, options) {
            var obj = fabric.util.groupSVGElements(objects, options);
            canvas.add(obj).renderAll();
          }
        );
        break;

      default:
        break;
    }
  };

  group = () => {
    const canvas = this._fc;
    if (!canvas.getActiveObject()) {
      return;
    }
    if (canvas.getActiveObject().type !== "activeSelection") {
      return;
    }
    canvas.getActiveObject().toGroup();
    canvas.requestRenderAll();
  };

  ungroup = () => {
    const canvas = this._fc;
    if (!canvas.getActiveObject()) {
      return;
    }
    if (canvas.getActiveObject().type !== "group") {
      return;
    }
    canvas.getActiveObject().toActiveSelection();
    canvas.requestRenderAll();
  };

  copy = () => {
    let canvas = this._fc;
    canvas.getActiveObject() &&
      canvas.getActiveObject().clone((cloned) => (this._clipboard = cloned));
  };

  paste = () => {
    // clone again, so you can do multiple copies.
    this.copy();
    this._clipboard.clone((clonedObj) => {
      let canvas = this._fc;
      canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
      });
      if (clonedObj.type === "activeSelection") {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas;
        clonedObj.forEachObject((obj) => canvas.add(obj));
        clonedObj.setCoords();
      } else {
        canvas.add(clonedObj);
      }
      this._clipboard.top += 10;
      this._clipboard.left += 10;
      canvas.setActiveObject(clonedObj);
      canvas.requestRenderAll();
    });
  };

  setBackgroundFromDataUrl = (
    dataUrl,
    options = {},
    // fn = null,
    shouldReRender = false
  ) => {
    let canvas = this._fc;
    let img = new Image();
    const { stretched, stretchedX, stretchedY, ...fabricOptions } = options;
    img.src = dataUrl;
    // console.log("/....../ dataUrl", dataUrl);
    // img.crossOrigin = 'anonymous'
    img.onload = () => {
      this.props.setImageLoaded(true);
      const imgObj = new fabric.Image(img);
      // imgObj.setSrc(
      //   img.src,
      //   () => {
      //     console.log(">>>>>imgObj.getCrossOrigin()", imgObj.getCrossOrigin());
      //   },
      //   { crossOrigin: "anonymous" }
      // );

      canvas.setBackgroundImage(
        imgObj,
        () => {
          this._resize(false, shouldReRender);
          // if (fn) fn();
        },
        { excludeFromExport: true }
      );
    };
  };
  setThumbnailBGFromDataUrl = (
    dataUrl,
    imgToDownload,
    options = {},
    fn = null
  ) => {
    let canvas = this._fc;
    let img = new Image();
    let img2 = new Image();
    console.log("LOADINGGGGGG _ PAGE", dataUrl);
    this.reloadDraw();
    this.setState({ isLoading: false }, () => {
      img.src = dataUrl;
      const { stretched, stretchedX, stretchedY, ...fabricOptions } = options;
    });
    console.log("........imgToDownload", imgToDownload);
    img.onload = () => {
      img2.src = imgToDownload;
      const imgObj = new fabric.Image(img);
      canvas.setBackgroundImage(
        imgObj,
        () => {
          this._resize();
          // this.reloadDraw();
          console.log("........img1.set");

          // canvas.renderAll()
        },
        { excludeFromExport: true }
      );
    };
    img2.onload = () => {
      this.setState({ isLoading: true });
      const imgObj = new fabric.Image(img2);
      canvas.setBackgroundImage(
        imgObj,
        () => {
          this._resize();

          canvas.requestRenderAll();
          console.log("........img2.set", img2, imgObj);
        },
        { excludeFromExport: true }
      );
    };
  };
  addObjectsFromJson = async (last) => {
    console.log("last", last);
    const canvas = this._fc;
    if (last.backgroundImage) {
      console.log("deleting the bg from last");
      await delete last.backgroundImage;
      await this.saveCanvas();
    }
    last.objects &&
      last.objects.map((obj) => {
        obj.fromJson = true;
        obj.visible = obj.qId
          ? this.props.annotationIdToShow === obj.qId
            ? true
            : false
          : true;
      });
    console.log("last--------", last);
    // canvas.loadFromJSON(
    //   last,
    //   canvas.renderAll.bind(canvas),
    //   function (o, object) {
    //     // `o` = json object
    //     // `object` = fabric.Object instance
    //     // ... do some stuff ...
    //   }
    // );
    canvas.loadFromJSON(last, function () {});
    return;
  };

  reloadDraw = () => {
    // return;
    const itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}`;
    let last = JSON.parse(localStorage.getItem(itemId));
    const qHideables = this.props.currentPage[0].buttons
      .filter((qh) => qh.button_type === "questionHideable")
      .map(({ content, id }) => {
        return { ...JSON.parse(content)[0], qId: id };
      });
    // const newLast=last.objects

    // console.log(
    //   "...last.objects, ...qHideables",
    //   ...last.objects,
    //   ...qHideables
    // );
    // console.log("last.objects, qHideables", last.objects, qHideables);
    if (last) {
      // setTimeout(() => {
      last.objects = [...last.objects, ...qHideables];
      console.log("last.objects", last.objects);
      this.addObjectsFromJson(last);
      // }, 500);
    } else {
      const annotations = this._fc?.toJSON([
        "isCapture",
        "oldScaleX",
        "oldScaleY",
        "canvasWidth",
        "canvasHeight",
        // "isQuestion",
        "qId", //if its added on question selection annot mode
      ]); //Adding new properties to objects.

      // const itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}`;
      last = annotations;
      last.objects = qHideables;
      this.addObjectsFromJson(last);
    }

    console.log("~~~~~~~~~~~~~~~~~~~~~~qHideables", last, qHideables);
  };

  addText = (text, options = {}) => {
    let canvas = this._fc;
    let iText = new fabric.IText(text, options);
    let opts = {
      left: (canvas.getWidth() - iText.width) * 0.5,
      top: (canvas.getHeight() - iText.height) * 0.5,
    };
    Object.assign(options, opts);
    iText.set({
      left: options.left,
      top: options.top,
    });

    canvas.add(iText);
  };

  saveCanvas = () => {
    if (this.props.annotationSelectionMode || this.props.pageZoomPartMode)
      return;
    console.log("SAVING !!", this.props.pageZoomPartMode);
    try {
      if (this.props.image.hasOwnProperty("id")) {
        const annotations = this._fc?.toJSON([
          "isCapture",
          "oldScaleX",
          "oldScaleY",
          "canvasWidth",
          "canvasHeight",
          // "isQuestion",
          "qId", //if its added on question selection annot mode
        ]); //Adding new properties to objects.

        const itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}`;
        if (annotations.objects?.length > 0) {
          const newObjects = annotations.objects.filter((obj) => {
            return !obj.qId;
          });
          annotations.objects = newObjects;
          localStorage.setItem(itemId, JSON.stringify(annotations));
        } else {
          localStorage.removeItem(itemId);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount = () => {
    let { tool, value, undoSteps, defaultValue, backgroundColor } = this.props;
    fabric.Object.NUM_FRACTION_DIGITS = 4;
    fabric.Object.prototype.set({
      transparentCorners: false,
      cornerColor: "rgba(102,153,255,0.5)",
      cornerSize: 12,
      // padding: 5,
    });
    let canvas = (this._fc = new fabric.Canvas(this._canvas, {
      preserveObjectStacking: false,
      width:
        this.props.scaleZoomFactorVal.canvas.canvasWidth ||
        window.innerWidth - 300,
      height:
        this.props.scaleZoomFactorVal.canvas.canvasHeight || window.innerHeight,
    }));

    this._initTools(canvas);

    let selectedTool = this._tools[tool];
    selectedTool && selectedTool.configureCanvas(this.props);

    this._selectedTool = selectedTool;
    this.props.setAnnotationSelectionMode(false);
    // Control resize
    window.addEventListener("resize", this._resize, false);

    // Initialize History, with maximum number of undo steps
    this._history = new History(undoSteps);
    this.timeOutFunctionId = null;

    // Events binding

    canvas.on("mouse:down", this._onMouseDown);
    // canvas.on("after:render", console.log("after render fired!"));
    canvas.on("mouse:dblclick", this._onMouseDblclick);
    canvas.on("mouse:move", this._onMouseMove);
    canvas.on("mouse:up", this._onMouseUp);
    canvas.on("mouse:out", this._onMouseOut);
    canvas.on("object:modified", this._onObjectModified);
    canvas.on("object:scaled", this._onObjectScaled);
    canvas.on("object:removed", this._onObjectRemoved);
    canvas.on("object:added", this._onObjectAdded);
    canvas.on("selection:created", this._onObjectSelected);
    canvas.on("selection:cleared", this._onObjectSelectionCleared);
    canvas.on("path:created", this._onPathCreated);
    fabric.util.addListener(document.body, "keyup", this.onKeyUp);

    // IText Events fired on Adding Text
    // canvas.on("text:event:changed", console.log)
    // canvas.on("text:selection:changed", console.log)
    // canvas.on("text:editing:entered", console.log)
    // canvas.on("text:editing:exited", console.log("exited"));

    this.enableTouchScroll();

    // initialize canvas with controlled value if exists
    (value || defaultValue) && this.fromJSON(value || defaultValue);
    setTimeout(() => {
      canvas.on("-object:added", this._onObjectAdded);
    }, 3000);
  };

  _onObjectSelected = (e) => {
    const obj = e.target;
    console.log("e- object selected", obj, e);
    this.props.setEditSelectionMode(true);
  };
  _onObjectSelectionCleared = (e) => {
    console.log("CLEARED");
    // console.log(e);
    this.props.setEditSelectionMode(false);
  };
  componentWillUnmount = () => {
    let canvas = this._fc;
    window.removeEventListener("resize", this._resize);

    fabric.util.removeListener(document.body, "keyup", this.onKeyUp);
    canvas.off("mouse:down", this._onMouseDown);
    // canvas.off("after:render", console.log("after render fired!"));
    canvas.off("mouse:dblclick", this._onMouseDblclick);
    canvas.off("mouse:move", this._onMouseMove);
    canvas.off("mouse:up", this._onMouseUp);
    canvas.off("mouse:out", this._onMouseOut);
    canvas.off("object:removed", this._onObjectRemoved);
    canvas.off("object:modified", this._onObjectModified);
    canvas.off("object:selected", this.onObjectSelected);

    if (this.timeOutFunctionId) {
      clearTimeout(this.timeOutFunctionId);
    }

    // if(this.props.captured){
    //   console.log('CAPTURED')
    //   // const deleteObject = (eventData, target) => {
    //   // let canvas = target.target.canvas;
    //   // console.log('eventData', eventData)
    //   // console.log('target', target)
    //   // canvas.backgroundImage.set("opacity", 1);
    //   // canvas.remove(target.target);
    //   // this.props.toggleCapture(this.props.captured);
    //   // this.props.changeSelectedToolFromOutside("select");
    //   // this.props.showInteractiveObjects();
    //   // canvas.requestRenderAll();
    // // };
    // }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.currentPageNumber !== prevProps.currentPageNumber) {
      if (this.props.pageZoomPartMode) {
        this._afterPagePartZoomClose();
      }
      if (this.props.annotationSelectionMode) {
        this._afterQHideableClose();
      }
    }
    if (this.props.addedActivities !== prevProps.addedActivities) {
      console.log(">>>>>>>>> ACTIVITY INSERTED ");
      // this.reloadDraw()
      window.location.reload();
    }

    if (
      this.props.annotationSelections.pasteMode &&
      this.props.annotationSelections.pasteMode !==
        prevProps.annotationSelections.pasteMode
    ) {
      console.log(">>>>>>>>> PASTE ACTIVATED");
      this.paste();
      this.props.setSelectionPasteMode(false);
    }

    if (
      this.props.annotationSelections.copyMode &&
      this.props.annotationSelections.copyMode !==
        prevProps.annotationSelections.copyMode
    ) {
      console.log(
        ">>>>>>>>> COPY ACTIVATED",
        this.copy(),
        this.props.annotationSelections.copyMode
      );
    }
    if (
      this.props.annotationSelections.removeMode &&
      this.props.annotationSelections.removeMode !==
        prevProps.annotationSelections.removeMode
    ) {
      console.log(
        ">>>>>>>>> remove ACTIVATED",
        this.removeSelected(),
        this.props.annotationSelections.removeMode
      );
    }
    if (
      this.props.annotationSelections.undoMode !==
      prevProps.annotationSelections.undoMode
    ) {
      console.log(">>>>>>>>> UNDO ACTIVATED");
      this.props.annotationSelections.undoMode && this.undo();
      // this.props.changeSelectedToolFromOutside("undo");
      this.props.setSelectionUndoMode(false);
      // this.props.annotationSelections.undoMode
    }
    if (
      this.props.annotationSelections.redoMode &&
      this.props.annotationSelections.redoMode !==
        prevProps.annotationSelections.redoMode
    ) {
      console.log(
        ">>>>>>>>> REDO ACTIVATED",
        this.redo(),
        this.props.triggerRedo(false),
        this.props.annotationSelections.redoMode
      );
    }
    if (
      !this.props.annotationSelectionMode &&
      this.props.annotationSelectionMode !== prevProps.annotationSelectionMode
    ) {
      this._afterQHideableClose();
    }
    if (this.props.pageZoomPartMode !== prevProps.pageZoomPartMode) {
      this._afterPagePartZoomClose();
    }
    if (this.props.annotationIdToShow !== prevProps.annotationIdToShow) {
      this.toggleQHideables();
    }

    if (
      this.props.settings.remove &&
      this.props.settings.remove !== prevProps.settings.remove
    ) {
      this.removeSelected();
      this.props.triggerRemove(false);
    }
    if (
      this.props.settings.removeAll &&
      this.props.settings.removeAll !== prevProps.settings.removeAll
    ) {
      this.removeAllObjects();
      this.props.triggerRemoveAll(false);
    }
    if (
      this.props.settings.redo &&
      this.props.settings.redo !== prevProps.settings.redo
    ) {
      this.redo();
      this.props.triggerRedo(false);
    }
    if (
      this.props.settings.undo &&
      this.props.settings.undo !== prevProps.settings.undo
    ) {
      this.undo();
      this.props.setSelectionUndoMode(false);
    }
    // if (this.props.showAnnotationsVal !== prevProps.showAnnotations) {
    //   console.log(
    //     "~~~this.props.showAnnotations ",
    //     this.props.showAnnotationsVal
    //   );
    //   this.onChangeDrawingObjects(this.props.showAnnotationsVal);
    // }
    if (
      // If fill color changed and something selected then change the selected object's  fill color.
      this.props.toolbar.fillColor !== prevProps.toolbar.fillColor &&
      this._selectedTool === this._tools[Tool.Select]
    ) {
      this.changeSelected("fillColor");
    }
    if (
      // If stroke color changed and something selected then change the selected object's  stroke color.
      this.props.toolbar.lineColor !== prevProps.toolbar.lineColor &&
      this._selectedTool === this._tools[Tool.Select]
    ) {
      this.changeSelected("lineColor");
    }
    if (
      // If stroke width changed and something selected then change the selected object's  stroke width.
      this.props.toolbar.lineWidth !== prevProps.toolbar.lineWidth &&
      this._selectedTool === this._tools[Tool.Select]
    ) {
      this.changeSelected("lineWidth");
    }

    if (
      this.props.sideBarOpened !== prevProps.sideBarOpened &&
      prevState.deneme > 1
    ) {
      this._resize();
    } else {
      this.setState({
        deneme: prevState.deneme + 1,
      });
    }
    if (this.state.scl !== prevState.scl) {
      this._resize();
    }
    if (this.props.tool !== prevProps.tool) {
      this._selectedTool =
        this._tools[this.props.tool] || this._tools[Tool.Pencil];
    }

    //Bring the cursor back to default if it is changed by a tool
    this._fc.defaultCursor = "grab";
    this._selectedTool.configureCanvas(this.props);

    if (this.props.backgroundColor !== prevProps.backgroundColor) {
      this._backgroundColor(this.props.backgroundColor);
    }

    if (
      this.props.value !== prevProps.value ||
      (this.props.value && this.props.forceValue)
    ) {
      this.fromJSON(this.props.value);
    }
  };

  render = () => {
    return (
      <div
        className={` ${
          this._tools && this._selectedTool === this._tools[Tool.Pan]
            ? "canvas-no-pointer-events"
            : ""
        }`}
      >
        <canvas
          className="draw-canvas"
          ref={(c) => (this._canvas = c)}
          style={{
            backgroundImage: !this.state.isLoading
              ? "url(" + this.props.image.thumbnail + ")"
              : "",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          Sorry, Canvas HTML5 element is not supported by your browser :(
        </canvas>
      </div>
    );
  };
}

// export default SketchField;
const mapStateToProps = (state) => {
  return {
    currentPageNumber: state.pages.currentPageNo,
    scaleZoomFactorVal: state.scaleZoomFactor,
    isButtonsShown: state.isButtonsShown,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    tool: state.selectedTool.selectedTool,
    captured: state.captured,
    modalOpen: state.addButton.modalOpen,
    quizAddModalOpen: state.quiz.quizAddModalOpen,
    ebookId: state.pages.ebookId,
    canvasScaleX: state.scaleZoomFactor.canvas.scaleX,
    canvasScaleY: state.scaleZoomFactor.canvas.scaleY,
    sideBarOpened: state.sideBarOpened,
    annotationSelections: state.annotationSelections,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    currentSelection: state.annotationSelections.currentSelection,
    showAnnotationsVal: state.settings.showAnnotations,
    settings: state.settings,
    toolbar: state.toolbar,
    currentPage: state.pages.currentPage,
    addedActivities: state.pages.addedActivities,
    annotationIdToShow: state.annotationSelections.annotationIdToShow,
    pageZoomPartMode: state.settings.pageZoomPartMode,
  };
};

export default connect(
  mapStateToProps,
  {
    setScalePageDown,
    setScalePageUp,
    pageNoChange,
    toggleButtons,
    showInteractiveObjects,
    hideInteractiveObjects,
    changeSelectedToolFromOutside,
    toggleCapture,
    addActivity,
    addButtonModalOpen,
    setScalePageScl,
    addMcQuestionModalOpen,
    setAnnotationSelectionMode,
    setAnnotationSelectionCoords,
    saveAnnotationSelectionToButton,
    toaster,
    triggerRedo,
    triggerUndo,
    triggerRemove,
    triggerRemoveAll,
    toggleSideBar,
    setToolbarVertical,
    setToolbarPosition,
    resetToolbarPosition,
    setImageLoaded,
    setEditSelectionMode,
    setAnnotationBeforeState,
    setScalePageSet,
    showAnnotations,
    hideAnnotations,
    setSelectionUndoMode,
    setSelectionPasteMode,
    setSelectionRedoMode,
    setPagePartZoomMode,
    setPagePartZoomObject,
  },
  null,
  { forwardRef: true }
)(SketchField);
