/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

// import { store } from '../../store/configureStore';
// ​
// function pageChange(add) {
//   return {
//     type: 'SET_PAGE_FROM_PAN',
//     payload: add,
//   }
// }
// store.dispatch(pageChange(false));

// ​

// const fabric = require("fabric").fabric;

class Pan extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    //Change the cursor to the move grabber
    // canvas.defaultCursor = "pointer";

    if (canvas._iTextInstances) {
      canvas._iTextInstances.forEach(function (ob) {
        ob.exitEditing();
      });
    }
    canvas.defaultCursor = "pointer";

    // console.log("jkl", canvas.defaultCursor);
    // console.log(props);
  }

  _doMouseDown(o) {}

  _doMouseMove(o, e) {}

  _doMouseUp(o) {}
}

export default Pan;
