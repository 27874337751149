import React from "react";
import { useTranslation } from "react-i18next";

export default function LinkButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (
    <div
      className="interactive-object"
      style={{ transform, "transformOrigin": "left top" }}
    >
      <span className="object-icon">
        <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2}
            fill="white"
            stroke="#FD6D87"
            strokeWidth="0" // Was 2 = Mustafa changed to 0
          />
          <path
            d="M7.8661 16.1339C6.99338 15.2612 6.99338 13.8424 7.8661 12.9697L9.42271 11.4131C9.69048 11.1453 9.69048 10.7112 9.42271 10.4434C9.15494 10.1756 8.72079 10.1756 8.45302 10.4434L6.89641 12C5.48782 13.4086 5.48782 15.695 6.89641 17.1036C8.30501 18.5122 10.5914 18.5122 12 17.1036L13.5566 15.547C13.8244 15.2793 13.8244 14.8451 13.5566 14.5773C13.2889 14.3096 12.8547 14.3096 12.587 14.5773L11.0303 16.1339C10.1576 17.0067 8.73882 17.0067 7.8661 16.1339ZM9.95859 14.0415C10.2405 14.3233 10.6974 14.3233 10.9793 14.0415L14.0415 10.9793C14.3233 10.6974 14.3233 10.2404 14.0415 9.95856C13.7596 9.67669 13.3026 9.67669 13.0208 9.95856L9.95859 13.0207C9.67672 13.3026 9.67672 13.7596 9.95859 14.0415ZM12 6.89639L10.4434 8.45299C10.1757 8.72076 10.1757 9.15491 10.4434 9.42268C10.7112 9.69045 11.1453 9.69045 11.4131 9.42268L12.9697 7.86608C13.8424 6.99336 15.2612 6.99336 16.134 7.86608C17.0067 8.73879 17.0067 10.1576 16.134 11.0303L14.5774 12.5869C14.3096 12.8547 14.3096 13.2888 14.5774 13.5566C14.8451 13.8244 15.2793 13.8244 15.5471 13.5566L17.1037 12C18.5123 10.5914 18.5123 8.30499 17.1037 6.89639C15.6951 5.48779 13.4086 5.48779 12 6.89639Z"
            fill="#FD6D87"
          />
        </svg>
      </span>
      <span className="object-text">{t("Link")}</span>
    </div>
  );
}
