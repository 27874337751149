import React, { Component } from "react";
import { connect } from "react-redux";
import CustomModal from "./CustomModal";
import { pullImagesFromPixabay } from "../actions/pixabay";

class PixabayVideoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modelImgURL: "",
      selectedImageUrl: "",
    };
  }

  toggleModal = (e, modalData) => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, modalData });
  };

  render() {
    console.log('imageData', this.props.imagesData)
    return (
      //<div className={this.props.scaleZoomFactorVal.scaleCss}>
      <div>
        <div className="columns is-multiline" style={{}}>
          {this.props.imagesData
            ? this.props.imagesData.map((imageData) => {
                return (
                  <div
                    key={imageData.id}
                    style={{ width: "100%" }}
                    className="column is-one-quarter-desktop is-half-tablet col-2"
                  >
                    <div className="card picZoom">
                      <div className="card-image">
                        {/* <figure className="image is-3by2 "> */}
                          <video
                            style={{ pointerEvents: "none" }}
                            width="320"
                            height="240"
                            controls
                          >
                            <source
                              src={imageData.videos.small.url}
                              type="video/mp4"
                            />
                          </video>
                          {/* <figure className="image is-3by2 "> */}

                          {/* <img
                          style={{objectFit:'cover'}}
                            src={imageData.previewURL}
                            onClick={() => {
                              this.setState({
                                modelImgURL: imageData.largeImageURL,
                                modalIsOpen: true
                              });
                            }}
                            alt=""
                          /> */}
                          {/* </figure> */}
                        {/* </figure> */}
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}

          <CustomModal
            isOpen={this.state.modalIsOpen}
            toggleModal={(e) => this.toggleModal(e)}
            data={{
              button_type: "image",
              content: this.state.modelImgURL,
              label: "",
            }}
          />
          {/*<Modal*/}
          {/*    centered={true}*/}
          {/*    className="deneme"*/}
          {/*    isOpen={true}*/}
          {/*>*/}
          {/*    <ModalBody>*/}
          {/*        <img*/}
          {/*            src={this.props.imagesData[0].largeImageURL}*/}
          {/*            alt="{datas.label}"*/}
          {/*        />*/}
          {/*    </ModalBody>*/}
          {/*</Modal>*/}
        </div>
        <div className="pagination">
          <p
            onClick={() => {
              if (this.props.pixaBayPage > 1) {
                this.props.pullImagesFromPixabay(
                  this.props.inputValue,
                  this.props.pixaBayPage - 1
                );
                // this.setState({pixaBayPage: this.state.pixaBayPage - 1})
              }
            }}
          >
            &laquo;
          </p>
          <p
            href="#"
            className={this.props.pixaBayPage == 1 ? "selectedPagination" : ""}
            onClick={() => {
              this.props.pullImagesFromPixabay(this.props.inputValue, 1);
              // this.setState({pixaBayPage:1})
            }}
          >
            1
          </p>
          <p
            href="#"
            className={this.props.pixaBayPage == 2 ? "selectedPagination" : ""}
            onClick={() => {
              this.props.pullImagesFromPixabay(this.props.inputValue, 2);
              // this.setState({pixaBayPage:2})
            }}
          >
            2
          </p>
          <p
            href="#"
            className={this.props.pixaBayPage == 3 ? "selectedPagination" : ""}
            onClick={() => {
              this.props.pullImagesFromPixabay(this.props.inputValue, 3);
              // this.setState({pixaBayPage:3})
            }}
          >
            3
          </p>
          <p
            href="#"
            className={this.props.pixaBayPage == 4 ? "selectedPagination" : ""}
            onClick={() => {
              this.props.pullImagesFromPixabay(this.props.inputValue, 4);
              // this.setState({pixaBayPage:4})
            }}
          >
            4
          </p>
          <p
            href="#"
            onClick={() => {
              if (this.props.pixaBayPage < 4) {
                this.props.pullImagesFromPixabay(
                  this.props.inputValue,
                  this.props.pixaBayPage + 1
                );
                // this.setState({pixaBayPage:this.state.pixaBayPage + 1})
              }
            }}
          >
            &raquo;
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var imagesData = state.pixabayVideo.result
    ? state.pixabayVideo.result.hits
    : [];
  return {
    imagesData,
    scaleZoomFactorVal: state.scaleZoomFactor,
    pixaBayPage: state.pixabayVideo.page,
  };
};
export default connect(mapStateToProps, { pullImagesFromPixabay })(
  PixabayVideoForm
);
