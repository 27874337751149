import React from "react";
import {
  HashRouter as Router,
  // BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LoginPage from "./components/LoginPage";
import MainPage from "./components/MainPage";
import Library from "./components/Library";

import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
// import { isLogin } from "./services/util";
import ProtectedRoute from "./components/routes/ProtectedRoute";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {/* <ProtectedRoutes /> */}
          {/* <Route path="/:page_no" component={MainPage} />; */}
          {/* <Route exact path="/:page_no" component={LoginPage} /> */}
          {/* <Route
            path="/"
            exact
            component={MainPage}
            render={() => {
              // console.log("askdjbajksdb");
              return <Redirect to="/4" />;
            }}
          /> */}
          <ProtectedRoute exact={true} path="/library" component={Library} />
          <ProtectedRoute
            path="/"
            exact={true}
            component={Library}
            // render={() => {
            //   return <Redirect to="/library" />;
            // }}

          />
          <ProtectedRoute
            path="/cenibook/:page"
            // exact={true}
            component={MainPage}
            // render={() => {
            //   // console.log("askdjbajksdb");
            //   return <Redirect to="/cenibook/:page" />;
            // }}

          />
          <Route path="/login" component={LoginPage} />
          {/* <ProtectedRoute component={Dashboard} /> */}
          {/* <ProtectedRoutes /> */}
          {/* <Route path="/:page_no" component={MainPage} />
          <Route
            path="*"
            render={() => {
              return <Redirect to="/1" />;
            }}
          /> */}
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
