import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import { toggleSideBar, sideBarOpen, sideBarClose } from "../actions/sidebar";
import { toggleSearchBar } from "../actions/searchbar";
// import { pageNoChange } from "../actions/pages";
import { pullPages, pageNoChange } from "../actions/pages";
import QuestionButton from "./SketchSelectedButtons/QuestionButton";
import CopyButton from "./SketchSelectedButtons/CopyButton";
import NextZoomPagePartButton from "./SketchSelectedButtons/NextZoomPagePartButton";
import PreviousZoomPagePartButton from "./SketchSelectedButtons/PreviousZoomPagePartButton";
import Timer from "./SketchSelectedButtons/Timer";
import ExitButton from "./SketchSelectedButtons/ExitButton";
import {
  setAnnotationSelectionMode,
  setAnnotationSelectionCoords,
  saveAnnotationSelectionToButton,
  resetAnnotationSelectionMode,
  setEditSelectionMode,
  setSelectionCopyMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setAnnotationBeforeState,
} from "../actions/annotationSelection";
import { setPagePartZoomMode } from "../actions/settings";

import {
  BrowserRouter as Router,
  useLocation,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
// import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Offline } from "react-detect-offline";
import startTour from "./Tour";
import TourIntro3 from "./TourIntro3";
import {
  captureInProcess,
  hideCaptureImage,
  captureNotInprocess,
} from "../actions/imageCapture";

import { insertActivity } from "../actions/addActivity";

const BottomMenu = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`navbar is-fixed-bottom  bottom-annotation-menu ${
        props.shouldBottomMenuOpened ? "open" : "hide"
      } `}
    >
      {/* <button
        // disabled={props.isLoading}
        className={`button is-small modal-button`}
        onClick={props.onClickHandler}
        title={t("Zoom Mode")}
      >
        <span className="icon is-small has-text-light">Zoom</span>
      </button> */}
      <PreviousZoomPagePartButton
        onClickHandler={() => {
        //   props.setSelectionRemoveMode(true);
        }}
      />
      <NextZoomPagePartButton
        onClickHandler={() => {
        //   props.setSelectionPasteMode(true);
        }}
      />
      <ExitButton
        onClickHandler={() => {
          props.pageZoomPartMode
            ? props.setPagePartZoomMode(null)
            : props.setAnnotationSelectionMode(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    pages: state.pages.pages,
    pagesState: state.pages,
    totalPageNumber: state.pages.totalPageNumber,
    currentPageNumber: state.pages.currentPageNo,
    currentPageId: state.pages.currentPage[0]?.id,
    currentPage: state.pages.currentPage,
    pageLoaded: state.pageLoaded,
    imageCapture: state.imageCapture,
    imagesLoaded: state.imagesLoaded,
    scaleZoomFactorVal: state.scaleZoomFactor,
    selectedTool: state.selectedTool.selectedTool,
    searchBarOpened: state.searchBarOpen,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    editSelectionMode: state.annotationSelections.editSelectionMode,
    currentSelection: state.annotationSelections.currentSelection,
    annotations: state.annotationSelections.annotations,
    domain: state.login.user_profile.domain_name,
    ebookId: state.pages.ebookId,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    showAnnotations: state.settings.showAnnotations,
    canvasVals: state.scaleZoomFactor.canvas,
    pageZoomPartMode: state.settings.pageZoomPartMode,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
  toggleSearchBar,
  pageNoChange,
  pullPages,
  captureInProcess,
  captureNotInprocess,
  hideCaptureImage,
  sideBarOpen,
  sideBarClose,
  setAnnotationSelectionMode,
  setEditSelectionMode,
  resetAnnotationSelectionMode,
  setSelectionCopyMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setAnnotationBeforeState,
  insertActivity,
  setPagePartZoomMode,
})(BottomMenu);
