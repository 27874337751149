import React from "react";
import { useTranslation } from "react-i18next";

export default function ActivityOptions(props) {
  const { t } = useTranslation();
  return (
    <li className="draw-toolbar-icon">
      <span
        className={`button normal-btn main-button ${
          props.activeButton === "activity-options" ? "active" : ""
        }${props.hideButton ? "passive" : ""}`}
        title={t("Activity Tools")}
        data-tip={t("Activity Tools")}
        onClick={props.onClick}
      >
        <img
          src={require("../../assets/img/svg/tools-activities.svg")}
          alt={t("Activity Tools")}
        />
      </span>
    </li>
  );
}
