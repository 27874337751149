import { GET_UNSPLASH, CLOSE_UNSPLASH } from "./actionTypes";
import axios from "axios";
import configuration from "../config.json";

const axiosConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    crossorigin: true,
  },
};

export const pullImagesFromUnsplash = (searchQuery, page = 1) => {
  return (dispatch) => {
    fetch(
      "https://api.unsplash.com/search/photos/?client_id=gZoKjF5fLAizFaLgR-VLKcsjtcQbti2Gzg3izdu9DOw&lang=" +
        configuration.searchBarLang +
        "&query=" +
        searchQuery +
        "&page=" +page
    )
      .then((res) => res.json())

      .then((result) => {
        console.log("query", searchQuery);
        console.log("result unsplash", result);
        dispatch(getUnsplash({ result, page: page }));
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const getUnsplash = (result) => {
  return {
    type: GET_UNSPLASH,
    payload: result,
  };
};

export const closeUnsplash = (result) => {
  return {
    type: CLOSE_UNSPLASH,
    payload: result,
  };
};
