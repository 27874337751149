import React, { Component } from "react";
import BookPlaceholder from "../assets/img/book-placeholder.png";
import { connect } from "react-redux";
// // import MainPage from "./MainPage";
import { withRouter } from "react-router-dom";
import { imagesUnloaded as imagesUnloadedAction } from "../actions/imageLoad";
import { resetPages } from "../actions/pages";
import { setLibraryFromLocal } from "../actions/login";
import { withTranslation } from "react-i18next";
import ChangeLanguage from "../components/SubComponents/ChangeLanguage";
import { Offline } from "react-detect-offline";
// import {asyncLocalStorage} from "../services/util"

class Library extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.imagesUnloadedAction();
    this.props.resetPages();
    // console.log("this.library", this.props.library);
    if (!this.props.loggedIn) {
      this.props.setLibraryFromLocal();
    }
    // this.setState({
    //   library: JSON.parse(localStorage.library),
    //   domain: localStorage.cenibook_domain,
    //   profile: localStorage.user_profile,
    //   url: "http://" + localStorage.cenibook_domain + ".cenibook.com/storage/",
    // });
  }
  //
  // getLibrary = async () => {
  //   const lib = await localStorage.library
  //   console.log('lib', lib)
  //   return JSON.parse(lib)
  // };
  logoutHandler = (e) => {
    // e.preventDefault();
    localStorage.clear(); //TODO DELETE ONLY AUTH , KEEP THE ANNOTS AND CREATE A UI TO DELETE OWN SETTINGS
    window.location.reload();
    // this.props.i18n.changeLanguage("en");
  };
  langHandler = (e) => {
    e.preventDefault();
    // localStorage.clear();
    // window.location.reload();
    console.log("e", e);
    const lang = this.props.i18n.language === "en" ? "tr" : "en";
    // console.log('this.props.i18n', this.props.i18n)
    this.props.i18n.changeLanguage(lang);
  };

  //🇬🇧  🇹🇷
  render() {
    const { library, profile, domain, t, theme } = this.props;
    // const url = "http://" + domain + ".cenibook.com/storage/";
    const url = "https://cenibook-dev.fra1.cdn.digitaloceanspaces.com/";
    console.log("profile", profile);
    const libraryClickHandler = (id) => {
      localStorage.cenibook_id = id; //TODO Move this from localStorage to Redux
      this.props.history.push("/cenibook/1");
    };
    return (
      <div className="library" data-theme={theme}>
        <div className="navbar library-header is-fixed-top">
          <div className="container column">
            <h2 className="is-hidden-tablet">{t("library")}</h2>
            <h1 className="is-hidden-mobile">{t("library")}</h1>

            <Offline>
              <div className="no-internet">
                {t("Warning! You do not have an internet connection!")}
              </div>
            </Offline>
            <div className="welcome-user">
              <div>
                {t("Welcome")}, {profile && profile.name}
              </div>
              <a href="" onClick={this.logoutHandler}>
                {t("Logout")}
              </a>
              {/* <img src="https://source.unsplash.com/collection/190727/1600x900" /> */}
            </div>
          </div>
          {/* <a onClick={this.langHandler}>{t("language")}</a> */}
          <div className="library-selection">
            <div className="select-area">
              {/* <label htmlFor="do-switchSmall">Theme</label> */}
              <div className="select is-small">
                <ChangeLanguage />
                <span className="icon">
                  <img
                    src={require("../assets/img/svg/pagi-combobox-arrow.svg")}
                    alt="First Page"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="library-content">
          <div className="container column">
            {/* <div className="library-content-row">
              <div className="library-content-title">
                <h2>Last Opened</h2>
              </div>
              <div className="library-book-list">
                <ul>
                  <li>
                    <div className="library-book-item">
                      <a className="library-book-cover" href="">
                        <img src={BookPlaceholder} alt="" />
                      </a>
                      <a className="library-book-title" href="">
                        Temel ve Genel Matematik
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="library-book-item">
                      <a className="library-book-cover" href="">
                        <img src={BookPlaceholder} alt="" />
                      </a>
                      <a className="library-book-title" href="">
                        Osmanlı Tarihi (1730-1908)
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="library-book-item">
                      <a className="library-book-cover" href="">
                        <img src={BookPlaceholder} alt="" />
                      </a>
                      <a className="library-book-title" href="">
                        İngilizce Dil Becerilerinin Öğretimi - II{" "}
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="library-content-row">
              <div className="library-content-title">
                <h2>{t("My Cenibooks")}</h2>
              </div>
              <div className="library-book-list">
                <ul>
                  {library
                    ? library.map((cenibook) => {
                        return (
                          <li key={cenibook.id}>
                            <div className="library-book-item">
                              <a
                                className="library-book-cover"
                                onClick={() => {
                                  libraryClickHandler(cenibook.id);
                                }}
                              >
                                <img
                                  src={
                                    cenibook.icon
                                      ? url + cenibook.icon
                                      : require("../assets/img/book-placeholder.png")
                                  }
                                  alt=""
                                />
                              </a>
                              <a
                                className="library-book-title"
                                // href=""
                                onClick={() => {
                                  libraryClickHandler(cenibook.id);
                                }}
                              >
                                {cenibook.name}
                              </a>
                            </div>
                          </li>
                        );
                      })
                    : "You have no cenibooks."}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    library: state.login.library,
    domain: state.login.domain,
    profile: state.login.user_profile,
    loggedIn: state.login.loggedIn,
    theme: state.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    imagesUnloadedAction: () => dispatch(imagesUnloadedAction()),
    resetPages: () => dispatch(resetPages()),
    setLibraryFromLocal: () => dispatch(setLibraryFromLocal()),

    // spinner: (show) => dispatch(spinner(show)),
    // setLoginSuccess: (status) => dispatch(setLoginSuccess(status)),
  };
};
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(Library));

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Library))
);
