import { GET_PIXABAY, CLOSE_PIXABAY } from "./actionTypes";
import axios from "axios";
import configuration from "../config.json";
import i18n from "i18next";

const axiosConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    crossorigin: true,
  },
};
//https://pixabay.com/api/?key=18563003-6f1f80a7f7d937b317c81a97b&q=cat&lang=en&per_page=10&safesearch=true&page=1
export const pullImagesFromPixabay = (searchQuery, page = 1) => {
  return (dispatch) => {
    fetch(
      "https://pixabay.com/api/?key=18563003-6f1f80a7f7d937b317c81a97b&q=" +
        searchQuery +
        "&lang=" +
        i18n.language +
        "&per_page=10&safesearch=true&page=" +
        page //&image_type=vector"
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("result pixabay", result);
        dispatch(getPixabay({ result, page: page }));
      })
      .catch((e) => {
        console.log(e);
      });
  };
};
// export const pullVideosFromPixabay = (searchQuery, page = 1) => {
//   return (dispatch) => {
//     fetch(
//       "https://pixabay.com/api/videos/?key=18563003-6f1f80a7f7d937b317c81a97b&q=" +
//         searchQuery +
//         "&lang=" +
//         i18n.language +
//         "&per_page=10&safesearch=true&page=" +
//         page //&image_type=vector"
//     )
//       .then((res) => res.json())
//       .then((result) => {
//         console.log("result pixabay", result);
//         dispatch(getPixabay({ result, page: page }));
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   };
// };

export const getPixabay = (result) => {
  return {
    type: GET_PIXABAY,
    payload: result,
  };
};

export const closePixabay = (result) => {
  return {
    type: CLOSE_PIXABAY,
    payload: result,
  };
};
