import React, { Component } from "react";
import { connect } from "react-redux";
import { setLoginSuccess, setDomain } from "../actions/login";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
// import { login } from "../services/util";
import { login } from "../services/api";
import Spinner from "./Spinner";
import { spinner } from "../actions/spinner";
import { withTranslation } from "react-i18next";
import ChangeLanguage from "../components/SubComponents/ChangeLanguage";
import { Offline, Online } from "react-detect-offline";

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      cenibook_domain: "",

      showModal: false,
      modalText: "",
    };
  }

  gotoLibrary = async (response, cenibook_domain) => {
    if (response.success.token) {
      localStorage.auth_token = await response.success.token;
      localStorage.domain = await cenibook_domain;
      // localStorage.user_profile = await JSON.stringify(
      //   response.success.details
      // );
      // localStorage.library = await JSON.stringify(response.success.library);

      this.setState({
        modalText: "Something went wrong please try again later",
      });
      const status = await localStorage.auth_token;
      console.log("status", status);
      console.log("logged in successfully");
      this.props.setLoginSuccess(response.success);
      this.props.setDomain(cenibook_domain);

      this.props.history.push("/library");
    }
  };
  /**
   * does the form submit process
   */
  loginHandler = (e) => {
    e.preventDefault();
    //this.props.spinner(true);

    const { email, password, cenibook_domain } = this.state;
    if (email === "" || password === "" || cenibook_domain === "") {
      this.setState(
        {
          modalText: this.props.i18n.t(
            "Please enter a valid email, password and cenibook domain."
          ),
        },
        () => {
          this.toggleModal();
        }
      );
      return;
    }
    // this.props.attemptLogin(email, password);
    // return;
    login(email, password, cenibook_domain)
      .then(async (response) => {
        console.log("response", response);
        this.gotoLibrary(response, cenibook_domain);

        //  window.location.reload();
      })
      .catch((error) => {
        this.setState(
          {
            modalText: this.props.i18n.t(
              "Please check your login credentials."
            ),
          },
          () => {
            this.toggleModal();
          }
        );
        console.log("logged error");
        this.props.setLoginSuccess(false);
      })
      .finally(() => {
        //this.props.spinner(false);
      });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    if (!this.state.form.email.trim() || !this.state.form.password.trim()) {
      this.setState(
        {
          modalText: this.props.i18n.t(
            "Please fill both the email and password fields."
          ),
        },
        () => {
          this.toggleModal();
        }
      );
      return;
    }

    if (!login(this.state.form.email, this.state.form.password)) {
      this.setState(
        {
          modalText: this.props.i18n.t("Please check your login credentials."),
        },
        () => {
          this.toggleModal();
        }
      );
    } else {
      window.location.reload();
    }
  };

  /**
   * when text in inputs change it pass text to the state
   */

  /**
   * shows and hides modal
   */
  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  /**
   * renders modal
   */
  renderModal = () => {
    return (
      <Modal
        isOpen={this.state.showModal}
        toggle={this.toggleModal}
        centered={true}
      >
        <ModalHeader>
          <b>Oops!</b>
        </ModalHeader>
        <ModalBody>{this.state.modalText}</ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={this.toggleModal}>
            {this.props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  _onTextChange = (e, key) => {
    if (key === "cenibook_domain") {
      this.setState({
        cenibook_domain: e,
      });
    } else if (key === "email") {
      this.setState({
        email: e,
      });
    } else {
      this.setState({
        password: e,
      });
    }
  };
  render() {
    const { email, password, cenibook_domain } = this.state;
    return (
      <React.Fragment>
        {this.renderModal()}

        <div className="login-form-area">
          <div className="login-form" id="login-form">
            <div className="lf-left">
              <figure className="welcome-logo">
                <img
                  src={process.env.PUBLIC_URL + "/cenibook.png"}
                  alt="Logo"
                />
              </figure>
              <div className="lf-left-title">
                <span>{this.props.t("Welcome")}</span>{" "}
              </div>
              <div className="lf-left-text">
                <p>
                  <Online>
                    {this.props.t("Please enter your email, password and domain name.")}
                  </Online>
                  <Offline>
                    <div className="no-internet-login">
                      {this.props.t(
                        "No internet connection."
                      )}
                    </div>
                  </Offline>
                </p>
              </div>
              <div className="lf-left-notice">
                <p>
                  {this.props.t(
                    "If you experience a problem, please contact your domain administrator."
                  )}
                </p>
              </div>
              <div className="library-selection is-pulled-left mt-5 login-selection">
                <div className="select-area">
                  {/* <label htmlFor="do-switchSmall">Theme</label> */}
                  <div className="select is-small">
                    <ChangeLanguage />
                    <span className="icon">
                      <img
                        src={require("../assets/img/svg/pagi-combobox-arrow.svg")}
                        alt="First Page"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="lf-right">
              <div className="lf-right-title"> {this.props.t("Sign in")} </div>
              <form className="login-form-box" onSubmit={this.loginHandler}>
                <label className="login-form-label" htmlFor="email">
                  {this.props.t("Email")}
                </label>
                <div
                  className="validate-input"
                  data-validate={this.props.t("Email is required")}
                >
                  <input
                    className="login-form-input"
                    type="text"
                    name="email"
                    placeholder={this.props.t("Please enter your email")}
                    autoComplete="book-email"
                    onChange={(e) =>
                      this._onTextChange(e.target.value, "email")
                    }
                    value={email}
                  />
                </div>
                <label className="login-form-label" htmlFor="password">
                  {this.props.t("Password")}
                </label>
                <div
                  className="validate-input"
                  data-validate={this.props.t("Password is required")}
                >
                  <input
                    className="login-form-input"
                    type="password"
                    name="password"
                    placeholder={this.props.t("Please enter your password")}
                    autoComplete="book-password"
                    value={password}
                    onChange={(e) =>
                      this._onTextChange(e.target.value, "password")
                    }
                  />
                </div>
                <label
                  className="login-form-label"
                  htmlFor="cenibook_domain-id"
                >
                  {this.props.t("CeniBook Domain")}
                </label>
                <div
                  className="validate-input"
                  data-validate={this.props.t("Cenibook domain is required")}
                >
                  <input
                    className="login-form-input"
                    type="cenibook_domain"
                    name="cenibook_domain"
                    placeholder={this.props.t("Please enter CeniBook Domain")}
                    autoComplete="cenibook_domain"
                    value={cenibook_domain}
                    onChange={(e) =>
                      this._onTextChange(e.target.value, "cenibook_domain")
                    }
                  />
                </div>
                <div className="login-form-footer">
                  <button className="login-form-btn">
                    {" "}
                    {this.props.t("Login")}{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoginPending: state.isLoginPending,
    isLoginSuccess: state.isLoginSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spinner: (show) => dispatch(spinner(show)),
    setLoginSuccess: (status) => dispatch(setLoginSuccess(status)),
    setDomain: (domain) => dispatch(setDomain(domain)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LoginPage));
