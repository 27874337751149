import React from "react";
import { useTranslation } from "react-i18next";

export default function NextPage(props) {
  const { t } = useTranslation();
  console.log("props.hideButton",props.hideButton);
  return (
    
    <li onClick={props.onClick}>
      <span
        className={`button normal-btn next-page  ${ props.hideButton ? "passive" : "" } ${
          props.activeButton === "select" ? "active" : ""
        }`}
        title={t("Go to Next Page") + "->"}
        data-tip={t("GGo to Next Page") + " ->"}
      >
        <img
          src={require("../../assets/img/svg/next-page.svg")}
          className={
            parseInt(props.currentPageNumber) ===
            parseInt(props.totalPageNumber)
              ? "button-low-opacity"
              : ""
          }
          alt={t("Go to Next Page") + "->"}
        />
      </span>
    </li>
  );
}
