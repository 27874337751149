import React, { Component } from "react";
import { connect } from "react-redux";
import NewModal from "../NewModal";
import addButtonReducer from "../../reducers/addButtonReducer";
import Form from "./Form";
import VideoForm from "./VideoForm";
import NoteForm from "./NoteForm";
import SidebarIndex from "../SidebarIndex";
import {
  addButtonModalOpen,
  addButtonModalClose,
} from "../../actions/addButton";
class AddButton extends Component {
  toggleModal = () => {
    console.log(this.props.modalOpen);
    if (this.props.modalOpen) {
      this.props.addButtonModalClose();
    } else {
      this.props.addButtonModalOpen();
    }
  };

  render() {
    let dataToRender = <Form />;

    switch (this.props.activityName) {
      case "text":
        dataToRender = <NoteForm />;
        break;
      case "video":
        dataToRender = <VideoForm />;
        break;
      case "audio":
        dataToRender = <Form />;
        break;
      case "image":
        dataToRender = <Form />;
        break;

      default:
        break;
    }

    return (
      <div>
        {this.props.modalOpen ? (
          <NewModal
            isOpen={this.props.modalOpen}
            toggleModal={(e) => this.toggleModal(e)}
            data={{
              button_type: "component",
              content: dataToRender,
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // modalOpen:true,
    modalOpen: state.addButton.modalOpen,
    activityName: state.activities.activityName,
  };
};

export default connect(mapStateToProps, {
  addButtonModalOpen,
  addButtonModalClose,
})(AddButton);
