export default {
    translation: {
        "My Cenibooks": "Mes Cenibooks",
        language: "FR",
        library: "Bibliothèque",
        Welcome: "Bienvenue",
        Logout: "Déconnexion",
        Loading: "Chargement",
        intro_1:
          "Barre de recherche: recherchez des images, des définitions et d'autres ressources! Vous pouvez également voir une liste de mots prédéfinie ici.",
        intro_2:
          "Index: table des matières, index des activités, vos signets et annotations sont ici!",
        intro_3:
          "Découvrez les nouvelles fonctionnalités! Raccourcis clavier, capture d'une partie de la page, thèmes, boutons de navigation et plus encore!",
        "Interactive Objects": "Objets interactifs",
        "Drawing Objects": "Objets de dessin",
        "Load All Images": "Charger toutes les images",
        Drag: "Glisser",
        "Drag the page": "Faites glisser la page",
        "Zoom out": "Zoom arrière",
        "Zoom in": "Zoom avant",
        "Full Screen": "Plein écran",
        "Draw Toolbar": "Dessiner la barre d'outils",
        "Navigation Tools": "Outils de navigation",
        Capture: "Capture",
        Curtain: "Rideau",
        Settings: "Paramètres",
        "Vertical Toolbar": "Barre d'outils verticale",
        "Horizontal Toolbar": "Barre d'outils horizontale",
        Pen: "Stylo",
        Highlighter: "Highlighter",
        "Clear All": "Tout effacer", // Effacer tout
        Eraser: "Gomme",
        Shapes: "Formes",
        Line: "Ligne",
        "Empty Circle": "Cercle vide",
        Circle: "Cercle",
        "Empty Rectangle": "Rectangle vide",
        Rectangle: "Rectangle",
        "Line Size": "Taille de la ligne",
        "Text and stroke color": "Couleur du texte et du trait",
        "Fill color": "Couleur de remplissage",
        Undo: "Annuler",
        Redo: "Refaire",
        Language: "Langue",
        Dark: "Sombre",
        Light: "Lumière",
        "Dark Blue": "Bleu",
        Green: "Vert",
        "Go to Previous Page": "Aller à la page précédente",
        "Go to Next Page": "Aller à la page suivante",
        "Scroll to top of the page": "Faire défiler vers le haut de la page",
        "Scroll to end of the page": "Faire défiler jusqu'à la fin de la page",
        Erase: "Effacer",
        "Select Drawing Objects":
          "Sélectionner l'outil pour les objets de dessin",
        Layers: "Calques",
        Theme: "Thème",
        "Change Toolbar Position": "Modifier la position de la barre d'outils",
        "Toolbar Pin": "Pin de la barre d'outils",
        Text: "Texte",
        Search: "Chercher",
        "Please enter email and password for login.":
          "Veuillez saisir votre adresse e-mail et votre mot de passe pour vous connecter.",
        "Sign in": "Connexion",
        Login: "Connexion",
        "If you experience a problem, please contact your domain administrator.":
          "Si vous rencontrez un problème, veuillez contacter votre administrateur de domaine.",
        Email: "Email",
        "Email is required": "Email est requis",
        "Please enter your email": "Veuillez saisir votre email",
        "Please enter your password": "Veuillez entrer votre mot de passe",
        "Please enter CeniBook Domain": "Veuillez entrer le domaine CeniBook",
        "Cenibook domain is required": "Le domaine Cenibook est requis",
        "Password domain is required": "Le domaine du mot de passe est requis",
        "Please enter a valid email, password and cenibook domain.":
          "Veuillez saisir une adresse e-mail, un mot de passe et un identifiant de cenibook domaine.",
        "Please check your login credentials.":
          "Veuillez vérifier vos identifiants de connexion.",
        "Please fill both the email and password fields.":
          "Veuillez remplir les champs email et mot de passe.",
        "CeniBook Domain": "Domaine CeniBook",
        Page: "Page",
        "My Bookmarks": "Mes favoris",
        Note: "Remarque",
        Audio: "Audio",
        Video: "Vidéo",
        Answer: "Répondre",
        Activity: "Activité",
        Image: "Image",
        "Open Sidebar": "Ouvrir la barre latérale",
        "Close Sidebar": "Fermer la barre latérale",
        Library: "Bibliothèque",
        Back: "Retour", ////
        Next: "Suivant", ////
        Done: "Fait", ////
        Skip: "Ignorer", ////
        TOC: "Table des matières", ////
        Activities: "Activités", ////
        "My Annotations": "Mes annotations", ////
        Bookmark: "Signet", ////
        "Stroke Size": "Stroke Size", ////
        Password: "Mot de passe", ////
        "Suggested Words": "Mots suggérés",
        "Search images from Pixabay":"Rechercher des images sur Pixabay",
        "Search images from Unsplash":"Rechercher des images sur Unsplash",
        "Search on Wikizero":"Rechercher sur Wikizero",
        "Search on Wikipedia":"Recherche Wikipedia",
        "Search on Merriam Dictionary":"Rechercher dans le dictionnaire Merriam",
        "Search on Wiktionary":"Recherche sur Wiktionnaire",
        "Search on WordWeb":"Rechercher sur WordWeb",
        "Search on PBS Learning Media":"Rechercher sur PBS Learning Media",
        "Search on Google":"Recherche sur Google",
        "Search on Google Images":"Rechercher sur Google Images",

        "Activity Tools":"Outils d'activité",
        "Add Note":"Ajouter une note",
        "Add Video":"Ajouter une vidéo",
        "Add Activity":"Ajouter une activité",
        "Add Audio":"Ajouter de l'audio",
        "Add Image":"Ajouter une image",
        "Question Tools":"Outils de questions",
        "Add a Multiple Choice Question":"Ajouter une question à choix multiple",
        "Add a Multiple Response Question":"Ajouter une question à réponses multiples",
        "True False":"Vrai faux",
        "Drag Drop":"Glisser Déposer",
        "Fill in the Blank":"Remplir les trous",
        "Drag":"Traîne",
        "Bookmark":"Signet",
        "Next Page":"Page suivante",
        "Previous Page":"Page précédente",
        "Close Search Bar":"Fermer la barre de recherche",
        "Open Search Bar":"Ouvrir la barre de recherche",
        "search":"chercher",
        "Moving Buttons":"Déplacement des boutons",
        
        "Confirm":"Confirmer",
        "You moved your own activity. Would you like to save the new position to the server?":"Vous avez déplacé votre propre activité. Souhaitez-vous enregistrer la nouvelle position sur le serveur?", 
        "Yes":"Oui",
        "No":"Non",
      },
  };
  