import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";

export default function AddMCQuestion(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickAddMCQuestion = () => {
    console.log("--Add a MC Question..");
    dispatch(toaster(t("Click a point to add a Multiple Choice Question"), "info"));
    props.changeMyTool("addmcquestion");
  };
  return (
    <li onClick={onClickAddMCQuestion}>
      <span
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "addmcquestion" ? "active" : ""
        }`}
        title={t("Add a Multiple Choice Question")}
        data-tip={t("Add a Multiple Choice Question")}
      >
        <img
          src={require("../../assets/img/svg/tools-multiple-choice.svg")}
          alt={t("Add a Multiple Choice Question")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Quiz")}</span>}
      </span>
    </li>
  );
}
