import {CAPTURED_IMAGE_SHOW, CAPTURED_IMAGE_HIDE,CAPTURED_INPROCESS,CAPTURED_NOT_INPROCESS,PAGE_NO_CHANGE} from '../actions/actionTypes'

export default (state = {imageBase64: "", showCapturedImage: false,captureInprocess:false}, action) => {
    switch (action.type) {

        case CAPTURED_IMAGE_SHOW:

            return {
                imageBase64: action.payload,
                showCapturedImage: true,
                captureInprocess:false

            };

        case CAPTURED_IMAGE_HIDE:

            return {
                imageBase64: state.imageBase64,
                showCapturedImage: false,
                captureInprocess:false
            };

        case CAPTURED_INPROCESS:

            return {
                imageBase64: "",
                showCapturedImage: false,
                captureInprocess:true
            };

        case CAPTURED_NOT_INPROCESS:

            return {
                imageBase64: "",
                showCapturedImage: false,
                captureInprocess:false
            };

        case PAGE_NO_CHANGE:
            //we have to rollback ozn page not change
            return {
                imageBase64: state.imageBase64,
                showCapturedImage: false,
                captureInprocess:false,
            };

        default:
            return state;
    }
};