/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import api from "../api";
import Thumbnail from "./Thumbnail";
import { uuid4 } from "./Sketch/utils";
import * as config from "../config.json";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toggleSideBar } from "../actions/sidebar";
import { pullPages, pageNoChange } from "../actions/pages";
import CustomModal from "./CustomModal";
import SidebarIndex from "./SidebarIndex";
import { renderToString } from "react-dom/server";
import configureStore from "../store/configureStore";
import { Provider } from "react-redux";
import { updatePageNo } from "../actions/pages";
import { toggleIndex, indexOpen } from "../actions/indexWindow";
import { parse } from "querystring";
import { withTranslation } from "react-i18next";

const store = configureStore();
const preloadedState = store.getState();
// console.log(preloadedState);
class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      modalData: {},
    };
  }

  // componentDidMount() {
  //   window.addEventListener("resize", this.resize);
  //   // this.props.pullPages();
  // }

  // resize=()=>{
  //   console.log('resizeddd')
  // }
  // componentWillUnmount = () =>
  // window.removeEventListener("resize", this.resize);

  componentWillReceiveProps = (props) => {
    this.setState({ currentPageNumber: props.currentPageNumber }, () => {
      this.onScrollSidebar("props");
    });
  };

  /**
   * hides and opens sidebar
   */
  toggleSidebar = () => {
    this.props.toggleSideBar(this.props.sidebarOpened);
  };

  /**
   * sets the current page number
   */
  onClickImage = (pageNumber) => {
    this.props.pageNoChange(pageNumber);
  };

  /**
   * renders sidebar page thumbnails
   */
  renderPageThumbnails() {
    let pages = this.props.pages;
    if (pages.length === 0 || !pages) return;

    return pages.map((page, index) => {
      if (index === 0) {
        return (
          <Thumbnail
            onClick={this.onClickImage}
            data={page}
            pageNumber={this.props.currentPageNumber}
            key={page.id}
            first={true}
          />
        );
      }
      return (
        <Thumbnail
          onClick={this.onClickImage}
          data={page}
          pageNumber={this.props.currentPageNumber}
          key={page.id}
        />
      );
    });
  }

  renderSelectPage() {
    let pages = this.props.pages;
    if (pages.length === 0 || !pages) return;

    return (
      <div className="select-area">
        <div className="select is-small">
          <select
            value={this.props.currentPageNumber || 1}
            // this.props.updatePageNo(obj)

            // onChange={e => this.onClickImage(e.target.value)
            // onChange={e => this.props.updatePageNo(e.target.value)}
            onChange={(e) => this.onClickToPage(e.target.value)}
          >
            {pages.map((page, index) => (
              <option key={uuid4()}>{page.pageNumber}</option>
            ))}
          </select>
          <span className="icon">
            <img
              src={require("../assets/img/svg/pagi-combobox-arrow.svg")}
              alt="First Page"
            />
          </span>
        </div>
        <div className="total-page">
          &nbsp; / <span>{this.props.totalPageNumber}</span>
        </div>
      </div>
    );
  }

  onClickToPage = (no) => {
    this.props.updatePageNo(parseInt(no));
    // window.location.hash = `#${parseInt(this.props.currentPageNumber) - 1}`;
  };
  onClickPreviousPage = () => {
    if (parseInt(this.props.currentPageNumber) === 1) return;
    this.props.updatePageNo(parseInt(this.props.currentPageNumber) - 1);
    // window.location.hash = `#${parseInt(this.props.currentPageNumber) - 1}`;
  };

  onClickNextPage = () => {
    if (parseInt(this.props.currentPageNumber) + 1 > this.props.pages.length)
      return;
    this.props.updatePageNo(parseInt(this.props.currentPageNumber) + 1);
    // window.location.hash = `#${parseInt(this.props.currentPageNumber) + 1}`;
  };

  onScrollSidebar = (source) => {
    if (source === "props") {
      let activeElement = document.querySelector(".pdf-thumbnail.active");

      if (!activeElement) return;
      let scrollTop = activeElement.offsetTop - 150;

      if (this.props.currentPageNumber === 1) {
        activeElement.parentElement.parentElement.scrollTop = 0;
        return;
      }

      activeElement.parentElement.parentElement.scrollTop = scrollTop;
    }
  };

  toggleModal = (e, modalData) => {
    this.props.toggleIndex(this.props.indexOpened);
    // console.log("this.props.indexOpened: " + this.props.indexOpened);
    // this.setState({ modalData });
    this.setState({ modalData });

    // console.log("this.state.modalIsOpen: " + this.state.modalIsOpen);
  };

  render() {
    return (
      //INTRO.JS STEP 3
      <>
        {!this.props.annotationSelectionMode && (
          <div
            className={`sidebar column is-narrow is-fullheight  ${
              //is-hidden-mobile
              this.props.sidebarOpened ? "open" : "hide"
            }`}
          >
            <CustomModal
              isOpen={this.props.indexOpened}
              toggleModal={(e) => this.toggleModal(e)}
              data={{ button_type: "component", content: <SidebarIndex /> }}
            />
            <div className="ceni-logo">
              <img src={require("../assets/img/svg/only_ceni6.svg")} />
            </div>
            <div className="pdf-thumbnail-list" onScroll={this.onScrollSidebar}>
              <ul>{this.renderPageThumbnails()}</ul>
            </div>
            <div className="sidebar-footer">
              <div className="pdf-navigation">
                <div className="first-page">
                  <Link
                    to={(this.props.currentPageNumber - 1).toString()}
                    className={
                      this.props.currentPageNumber === 1 ? "disable-button" : ""
                    }
                  >
                    <span
                      className="icon"
                      onClick={this.onClickPreviousPage}
                      data-tip={"Previous Page"}
                    >
                      <img
                        src={require("../assets/img/svg/pagi-prev-page.svg")}
                        alt="First Page"
                      />
                    </span>
                  </Link>
                </div>
                {this.renderSelectPage()}
                <div className="last-page">
                  <Link
                    to={(this.props.currentPageNumber + 1).toString()}
                    className={
                      this.props.currentPageNumber === this.props.pages.length
                        ? "disable-button"
                        : ""
                    }
                  >
                    <span
                      className="icon"
                      onClick={this.onClickNextPage}
                      data-tip={"Next Page"}
                    >
                      <img
                        src={require("../assets/img/svg/pagi-next-page.svg")}
                        alt="Last Page"
                      />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="corporation">
                <Link
                  to="/library"
                  className="corporation-link is-pulled-left"
                  data-tip={this.props.t("Library")}
                >
                  <figure className="icon">
                    {/* <img src={'http://cenibook.com/storage/' + this.props.icon} alt="Logo" /> */}
                    <img
                      // src={require("../assets/img/cenibook-logo.png")}
                      src={require("../assets/img/svg/library.svg")}
                      alt={this.props.t("Library")}
                      title={this.props.t("Library")}
                    />
                  </figure>
                </Link>

                <a
                  className="index-icon"
                  onClick={this.toggleModal}
                  data-tip={this.props.t("TOC")}
                >
                  {/*INTRO JS STEP 2 */}
                  <div data-step="2" data-intro={this.props.t("intro_2")}>
                    <figure className="icon">
                      <img
                        src={require("../assets/img/svg/sidebar-index.svg")}
                        alt={this.props.t("TOC")}
                        title={this.props.t("TOC")}
                      />
                    </figure>
                  </div>
                </a>
                <button
                  className="collapse-sidebar is-pulled-right"
                  onClick={this.toggleSidebar}
                  data-tip={
                    this.props.sidebarOpened
                      ? this.props.t("Close Sidebar")
                      : this.props.t("Open Sidebar")
                  }
                >
                  <span className="icon">
                    <img
                      src={require("../assets/img/svg/sidebar-collapse-left.svg")}
                      alt={this.props.t("Collapse Sidebar")}
                      title={
                        this.props.sidebarOpened
                          ? this.props.t("Close Sidebar")
                          : this.props.t("Open Sidebar")
                      }
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sidebarOpened: state.sideBarOpened,
    indexOpened: state.indexOpened,
    pages: state.pages.pages,
    icon: state.pages.icon,
    logo: state.pages.logo,
    totalPageNumber: state.pages.totalPageNumber,
    currentPageNumber: state.pages.currentPageNo,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
  };
};

// export default connect(mapStateToProps, {
//   toggleSideBar,
//   pullPages,
//   pageNoChange,
//   updatePageNo,
//   toggleIndex,
// })(withRouter(Sidebar));

export default withRouter(
  connect(mapStateToProps, {
    toggleSideBar,
    pullPages,
    pageNoChange,
    updatePageNo,
    toggleIndex,
  })(withTranslation()(Sidebar))
);
