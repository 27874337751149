/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Hand from "./ToolbarItems/Hand";
import ZoomOut from "./ToolbarItems/ZoomOut";
import ZoomIn from "./ToolbarItems/ZoomIn";
import ZoomNormal from "./ToolbarItems/ZoomNormal";
import FullScreen from "./ToolbarItems/FullScreen";
import DrawToolbar from "./ToolbarItems/DrawToolbar";
import ZoomToolbar from "./ToolbarItems/ZoomToolbar";
// import Layers from "./ToolbarItems/Layers";
import Menu from "./ToolbarItems/Menu";
import VerticalToolbar from "./ToolbarItems/VerticalToolbar";
import PinToolbar from "./ToolbarItems/PinToolbar";
import Pen from "./ToolbarItems/Pen";
import Highliter from "./ToolbarItems/Highliter";
import Text from "./ToolbarItems/Text";
import Eraser from "./ToolbarItems/Eraser";
import Shapes from "./ToolbarItems/Shapes";
import Line from "./ToolbarItems/Line";
import TextStrokeColor from "./ToolbarItems/TextStrokeColor";
import FillColor from "./ToolbarItems/FillColor";
// import UndoRedo from "./ToolbarItems/UndoRedo";
import Draggable from "react-draggable";
import Selecter from "./ToolbarItems/Selecter";
// import TransparentFillColor from "./ToolbarItems/TransparentFillColor";
import { CompactPicker } from "react-color";
// import { SketchPicker } from "react-color";
import AddNote from "./ToolbarItems/AddNote";
import ActivityOptions from "./ToolbarItems/ActivityOptions";
import TeacherTools from "./ToolbarItems/TeacherTools";
import QuestionOptions from "./ToolbarItems/QuestionOptions";
import { isMobile } from "react-device-detect";
import { setScalePageReset } from "../actions/scalePageReset";
import AddHideableAnnotation from "./ToolbarItems/AddHideableAnnotation";
import AddBlankPage from "./ToolbarItems/AddBlankPage";
import AddTimer from "./ToolbarItems/AddTimer";

// import { CompactPickerColor } from "react-color/color";
import Undo from "./ToolbarItems/Undo";
import Redo from "./ToolbarItems/Redo";
import { connect } from "react-redux";

// import Up from "./ToolbarItems/Up";
// import Down from "./ToolbarItems/Down";
import NextPage from "./ToolbarItems/NextPage";
import PrevPage from "./ToolbarItems/PrevPage";
// import PageOptions from "./ToolbarItems/PageOptions";
import { pageNoChange } from "../actions/pages";
import { toggleButtons } from "../actions/buttonShowHide";
import {
  setToolbarPosition,
  resetToolbarPosition,
  pinToolbar,
  unpinToolbar,
  setToolbarVertical,
} from "../actions/toolbar";

import { changeSelectedTool } from "../actions/selectedTool";
import { setAnnotationSelectionMode } from "../actions/annotationSelection";

// import { toggleSideBar } from "../actions/sidebar";
// import { pullZipImages } from "../actions/pages";
// import { hideInteractiveObjects as hideInteractiveObjectsAction } from "../actions/interactiveObjects";
// import { imagesLoaded as imagesLoadedAction } from "../actions/imageLoad";
import {
  hideInteractiveObjects,
  showInteractiveObjects,
} from "../actions/interactiveObjects";
import {
  showAnnotations,
  hideAnnotations,
  triggerRedo,
  triggerUndo,
  triggerRemove,
  triggerRemoveAll,
  triggerZoomIn,
  triggerZoomOut,
} from "../actions/settings.js";
import { captureInProcess, hideCaptureImage } from "../actions/imageCapture";
import Capture from "./ToolbarItems/CaptureTool";
import Curtain from "./ToolbarItems/CurtainTool";
// import AddNote from "./ToolbarItems/AddNote";
import AddVideo from "./ToolbarItems/AddVideo";
import AddH5p from "./ToolbarItems/AddH5p";
import AddAudio from "./ToolbarItems/AddAudio";
import AddImage from "./ToolbarItems/AddImage";
import AddMCQuestion from "./ToolbarItems/AddMCQuestion";
import AddMRQuestion from "./ToolbarItems/AddMRQuestion";
import Mouse from "./ToolbarItems/Mouse";
import HardDragger from "./ToolbarItems/HardDragger";
import DevModeTools from "./ToolbarItems/DevModeTools";
import { setLineWidth, setLineColor, setFillColor } from "../actions/toolbar";

var CompactPickerColors = (<CompactPicker />).props.colors;

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curtainState: 0,
      verticalToolbar: this.props.toolbar.verticalToolbar,
      toolbarPin: this.props.toolbar.toolbarPin,
      activeButton: this.props.toolbar.activeButton,
      activeSubButton: null,
      activeSubChildButton: null,
      activeMouseButton: null,
      activeDrags: 0,
      localControlledPosition: this.props.toolbar.toolbarPosition,
    };
  }
  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
    const { x, y } = this.state.localControlledPosition;
    this.props.setToolbarPosition({ x, y });
  };

  onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ localControlledPosition: { x, y } });
  };

  onClickNextPage = () => {
    let cPNo = this.props.currentPageNumber;
    this.props.pageNoChange(++cPNo);
  };
  onClickPrevPage = () => {
    this.props.pageNoChange(this.props.currentPageNumber - 1);
  };
  onClickScrollTop = () => {
    let paGe = document.getElementById("pdfPageList");
    paGe.scrollTop = 0;
  };
  onClickScrollDown = () => {
    let paGe = document.getElementById("pdfPageList");
    paGe.scrollTo(0, paGe.scrollHeight);
  };

  onChangeInteractiveObjects = (event) => {
    if (event.target.checked) {
      this.props.showInteractiveObjects();
    } else {
      this.props.hideInteractiveObjects();
    }
  };

  onChangeDrawingObjects = (event) => {
    if (event.target.checked) {
      this.props.showAnnotations();
    } else {
      this.props.hideAnnotations();
    }
  };

  onCloseAllOpenItems() {
    this.setState({
      activeSubButton: null,
      activeSubChildButton: null,
    });
  }

  onClickMouseButton = (button) => {
    this.setState({ activeButton: button, activeMouseButton: button }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool(button);
    });
  };

  onClickZoomOut = () => {
    this.setState({ activeSubButton: "zoom-out" }, () => {
      // this.onCloseAllOpenItems();
      this.props.triggerZoomOut(true);
    });
  };
  onClickZoomReset = () => {
    this.setState({ activeSubButton: "zoom-reset" }, () => {
      // this.onCloseAllOpenItems();
      this.props.setScalePageReset();
    });
  };

  onClickFullScreen = () => {
    this.setState({ activeSubButton: "full-screen" }, () => {
      this.onCloseAllOpenItems();
    });

    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  onClickZoomIn = () => {
    this.setState({ activeSubButton: "zoom-in" }, () => {
      // this.onCloseAllOpenItems();
      this.props.triggerZoomIn(true);
    });
  };

  onClickButton = (buttonName) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        activeButton: prevState.activeButton === buttonName ? null : buttonName,
        activeSubButton: null,
        activeSubChildButton: null,
      };
    });
  };
  onClickMouseSelectButton = (buttonName) => {
    let isTheSameButton = false; //if the mouse icon is as the same as selected mouse icon
    let prevActiveButton = null;

    //this is to make the main mouse icon to be selectable
    this.setState(
      (prevState) => {
        isTheSameButton =
          !prevState.activeButton?.includes("options") && // if this is not a sub menu opener
          prevState.activeButton !== null;
        prevActiveButton = prevState.activeButton;
        console.log(
          "prevState.activeButton",
          prevState.activeButton,
          prevState.activeMouseButton
        );
        return {
          ...prevState,
          activeButton:
            prevState.activeButton === buttonName
              ? prevState.activeMouseButton
              : buttonName,
          activeMouseButton: isTheSameButton
            ? prevState.activeButton
            : prevState.activeMouseButton,
          activeSubButton: null,
          activeSubChildButton: null,
        };
      },
      // , isTheSameButton && this.onClickMouseButton(buttonName)
      () => {
        if (isTheSameButton) {
          console.log(
            "isTheSameButton",
            isTheSameButton,
            buttonName,
            prevActiveButton
          );
          this.onCloseAllOpenItems();
          this.props.changeSelectedTool(prevActiveButton);
        }
      }
      // console.log("isTheSameButton", isTheSameButton, buttonName)

      // this.setState({ activeButton: button, activeMouseButton: button }, () => {
      // this.onCloseAllOpenItems();
      // this.props.changeSelectedTool(button);
      // });
    );
  };

  onClickSubButton = (buttonName) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          activeSubButton:
            prevState.activeSubButton === buttonName ? null : buttonName,
          activeSubChildButton: null,
        };
      },
      () => {
        this.props.changeSelectedTool(buttonName);
      }
    );
  };

  onClickSubChildButton = (childButtonName, selfClose = false) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          activeSubChildButton:
            prevState.activeSubChildButton === childButtonName
              ? null
              : childButtonName,
        };
      },
      () => {
        this.props.changeSelectedTool(childButtonName);
        selfClose &&
          setTimeout(() => {
            this.onCloseAllOpenItems();
          }, 1000);
      }
    );
  };
  onClickColorButton = (childButtonName) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        activeSubChildButton:
          prevState.activeSubChildButton === childButtonName
            ? null
            : childButtonName,
      };
    });
  };

  onClickVerticalToolbar = () => {
    this.props.setToolbarPosition({ x: 0, y: 20 });
    this.props.setToolbarVertical(!this.state.verticalToolbar);
    this.onCloseAllOpenItems();
    // this.setState(
    //   {
    //     activeButton: "vertical-toolbar",
    //     verticalToolbar: !this.state.verticalToolbar,
    //     // drawToolbarOpened: false,
    //     // controlledPosition: { x: 0, y: 20 },
    //   },
    //   () => {
    //     this.onCloseAllOpenItems();
    //     this.props.setToolbarVertical(this.state.verticalToolbar);
    //   }
    // );
  };

  onClickToolbarPin = () => {
    const toolbarWidth = document.getElementById("toolbar1").offsetWidth;
    this.setState({
      localControlledPosition: { x: 100, y: 100 },
    });
    // this.setState(
    //   {
    //     // activeButton: "toolbar-pin",
    //     toolbarPin: !this.state.toolbarPin,

    //     // drawToolbarOpened: false,
    //   },
    //   () => {
    //     this.state.toolbarPin
    //       ? this.props.pinToolbar()
    //       : this.props.unpinToolbar();
    //     this.onCloseAllOpenItems();
    //   }
    // );
  };

  onClickPen = () => {
    console.log("Heyy");
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          activeSubButton:
            prevState.activeSubButton === "pencil" ? null : "pencil",
        };
      },
      () => {
        this.props.changeSelectedTool("pencil");
      }
    );
  };

  onClickHighliter = () => {
    console.log("HIGHHH");
    this.setState(
      {
        activeSubButton: "highlighter",
      },
      () => {
        // this.onCloseAllOpenItems();
        this.props.changeSelectedTool("highlighter");
      }
    );
  };

  onClickText = () => {
    console.log("shouldShowButtonLabels", this.props.showLabels);
    this.setState(
      {
        activeSubButton: "text",
      },
      () => {
        // this.onCloseAllOpenItems();
        this.props.changeSelectedTool("text");
      }
    );
  };

  onClickEraseSelected = () => {
    this.onClickSubChildButton("eraseselected");
    this.props.triggerRemove(true);
  };
  onClickCapture = () => {
    this.setState({ activeButton: "capture" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("capture");
      // console.log("select");
    });
  };
  onClickQHideable = () => {
    this.setState({ activeButton: "qhideable" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("qhideable");
      // console.log("select");
    });
  };
  onClickCurtain = () => {
    if (this.state.curtainState === 0) {
      this.setState({ curtainState: 1, activeButton: "curtain" }, () => {
        this.props.toggleButtons(false);
        document.getElementById("root").style.backgroundColor = "BLACK";
        document.getElementById("parentOne").classList.remove("content");
        document
          .getElementById("parentOne")
          .classList.add("contentCurtainBlack");
        document.getElementById("mainOne").style.display = "none";
        this.props.toggleSideBar(true);
        this.onCloseAllOpenItems();
        this.props.changeSelectedTool("curtain");
        // console.log(this.state.curtainState);
      });
    } else if (this.state.curtainState === 1) {
      this.setState({ curtainState: 2 }, () => {
        document
          .getElementById("parentOne")
          .classList.remove("contentCurtainBlack");
        document
          .getElementById("parentOne")
          .classList.add("contentCurtainWhite");
        // console.log(this.state.curtainState);
      });
    } else {
      this.setState({ curtainState: 0, activeButton: null }, () => {
        document.getElementById("mainOne").style.display = "block";
        document
          .getElementById("parentOne")
          .classList.remove("contentCurtainWhite");
        document.getElementById("parentOne").classList.add("content");
        this.props.toggleSideBar(false);
        this.props.toggleButtons(true);
        // console.log(this.state.curtainState);
        this.onClickHand();
      });
    }
  };

  onClickHand = () => {
    this.setState({ activeButton: "pan" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("pan");
      // console.log("select");
    });
  };

  onClickEraseAll = () => {
    this.onClickSubChildButton("eraseall", true);
    this.props.triggerRemoveAll(true);
  };

  onClickLineSubItem = (width) => {
    this.props.setLineWidth(width * 3);
  };

  onChangeLineColor = (color) => {
    this.props.setLineColor(color.hex);
  };

  onChangeFillColor = (color) => {
    this.props.setFillColor(color.hex);
  };

  // componentDidMount(){
  //   this.setState({
  //     verticalToolbar: this.props.toolbar.verticalToolbar ,
  //     toolbarPin: this.props.toolbar.toolbarPin,
  //     activeButton: this.props.toolbar.activeButton,
  //     localControlledPosition: this.props.toolbar.toolbarPosition,
  //   })
  // }
  componentDidUpdate(prevProps) {
    if (
      this.props.selectedToolChangedFromOutside !==
      prevProps.selectedToolChangedFromOutside
    ) {
      this.buttonHandler(this.props.selectedTool);
      // console.log('selectedToolChangedFromOutside' )
    }
    if (
      this.props.toolbar.toolbarPosition !== prevProps.toolbar.toolbarPosition
    ) {
      this.setState({
        localControlledPosition: this.props.toolbar.toolbarPosition,
      });
    }
    if (
      this.props.toolbar.verticalToolbar !== prevProps.toolbar.verticalToolbar
    ) {
      console.log(
        "SHOULD CHANGE VERTICAL TOOLBAR",
        this.props.toolbar.verticalToolbar
      );
      this.setState({
        verticalToolbar: this.props.toolbar.verticalToolbar,
      });
    }
    if (this.props.toolbar.toolbarPin !== prevProps.toolbar.toolbarPin) {
      this.setState({
        toolbarPin: this.props.toolbar.toolbarPin,
      });
    }
  }
  buttonHandler = (btn) => {
    switch (btn) {
      case "capture":
        this.onClickCapture();
        break;
      case "qhideable":
        this.onClickQHideable();
        break;
      case "hand":
        this.onClickHand();
        break;
      case "curtain":
        this.onClickButton("curtain");
        break;
      case "menu":
        this.onClickButton("menu");
        break;
      case "pin":
        this.onClickToolbarPin();
        break;
      case "pencil":
        this.onClickPen();
        break;
      case "highlighter":
        this.onClickHighliter();
        break;
      case "text":
        this.onClickText();
        break;
      case "eraser":
        this.onClickSubButton("eraser");
        break;
      case "eraseselected":
        this.onClickEraseSelected();
        break;
      case "erase":
        this.onClickEraseSelected();
        break;
      case "eraseall":
        this.this.onClickEraseAll();
        break;
      case "shapeline":
        this.onClickSubButton("shapeline");
        break;
      case "circle":
        this.onClickSubButton("circle");
        break;
      case "emptysquare":
        this.onClickSubButton("emptysquare");
        break;
      case "emptycircle":
        this.onClickSubButton("emptycircle");
        break;
      case "rectangle":
        this.onClickSubButton("rectangle");
        break;
      case "line":
        this.onClickSubButton("line");
        break;
      case "textstroke":
        this.onClickColorButton("textstroke");
        break;
      case "fillcolor":
        this.onClickColorButton("fillcolor");
        break;
      case "zoomin":
        this.onClickZoomIn();
        break;
      case "zoomnormal":
        this.onClickZoomReset();
        break;
      case "zoomout":
        this.onClickZoomOut();
        break;
      case "fullscreen":
        this.onClickFullScreen();
        break;
      case "pan":
        this.onClickMouseButton("pan");
        break;
      case "select":
        this.onClickMouseButton("select");
        break;
      case "harddrag":
        this.onClickMouseButton("harddrag");
        break;

      default:
        break;
    }
    console.log(
      "The toolbar changed from outside",
      this.props.selectedToolChangedFromOutside,
      btn
    );
  };

  render() {
    const userRole =
      this.props.roles.length === 0 ? "student" : this.props.roles[0].name;
    const showTeacherTools = userRole !== "student";
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    let toolbarClass = "";
    switch (this.props.toolbarSize) {
      case "1.5":
        toolbarClass = "toolbarsize-1-5x";
        break;
      case "2":
        toolbarClass = "toolbarsize-2x";
        break;
      default:
        break;
    }

    const devMode = true;
    // devMode ? (this.props.showLabels = true) : (this.props.showLabels = false);

    // const { controlledPosition } = this.props;
    const isSmallScreen = this.props.canvasVals
      ? window.innerWidth < 641
      : false;
    const isVerySmallScreen = this.props.canvasVals
      ? window.innerWidth < 341
      : false;

    return (
      <Draggable
        // disabled={false}
        handle=".drag"
        // bounds=".columns"
        // defaultPosition={(100, 100)}
        // defaultPosition={isSmallScreen ? { x: 0, y: 0 } : null}
        scale={1}
        // axis={isSmallScreen ? "x" : "both"}
        axis={"both"}
        grid={[2, 2]}
        defaultClassName="toolbar-draggable-handle"
        // position={isSmallScreen ? null : controlledPosition}
        // defaultPosition={{ x: 0, y: 0 }}
        defaultPosition={this.state.localControlledPosition}
        position={this.state.localControlledPosition}
        {...dragHandlers}
        onDrag={this.onControlledDrag}
        onStart={this.onStart}
        onStop={this.onStop}
        // disabled={isSmallScreen}
      >
        <div
          // data-step="4"
          // data-intro="Search Bar: Search images, definitions and other resources! You can also see predefined wordlist here."
          //${isSmallScreen ? "mobile-toolbar" : ""}
          className={`${
            this.state.verticalToolbar ? "vertical1" : "horizontal1"
          } toolbar-wrapper toolbar-new ${
            this.props.searchBarOpen ? "toolbar-wrapper-change-left" : ""
          }mobile-toolbar`}
          id="toolbar1"
        >
          {this.state.activeSubChildButton === "textstroke" && (
            <div
              className={`line-color-picker ${
                this.state.activeSubChildButton === "textstroke" ? "" : "dOff"
              } ${
                this.state.verticalToolbar && !isSmallScreen
                  ? "verticalOn"
                  : "verticalOff"
              } `}
            >
              {this.state.activeSubChildButton === "textstroke" ? (
                <CompactPicker onChange={this.onChangeLineColor} />
              ) : (
                ""
              )}
            </div>
          )}
          {this.state.activeSubChildButton === "fillcolor" && (
            <div
              className={`fill-color-picker ${
                this.state.activeSubChildButton === "fillcolor" ? "" : "dOff"
              } ${
                this.state.verticalToolbar && !isSmallScreen
                  ? "verticalOn"
                  : "verticalOff"
              }`}
            >
              {this.state.activeSubChildButton === "fillcolor" ? (
                <CompactPicker
                  colors={[...CompactPickerColors, "transparent"]}
                  onChange={this.onChangeFillColor}
                />
              ) : (
                ""
              )}
            </div>
          )}
          <div
            className={`toolbar ${
              this.state.verticalToolbar && !isSmallScreen
                ? "vertical " + toolbarClass + "-v" //no space after vertical
                : "horizontal " + toolbarClass + "-h" //add one space after horizontal
            }

            `}
            // ${this.state.toolbarPin ? " min-toolbar" : ""}
          >
            <div
              className="toolbar-items"
              // style={}
            >
              {/* TODO Use style top left to set min width/2 plus */}
              <div className="main-toolbar">
                <div
                  // data-step="3"
                  // data-intro="Search Bar: Search images, definitions and other resources! You can also see predefined wordlist here."
                  className="buttons has-addons"
                >
                  {!this.state.toolbarPin ? (
                    <ul>
                      <li
                        className="drag-element"
                        onMouseDown={() => this.onCloseAllOpenItems()}
                      >
                        <span
                          className="button drag"
                          id="drag1"
                          // title="Drag"
                          data-tip={"Drag"}
                        >
                          <img
                            src={require("../assets/img/svg/toolbar-drag.svg")}
                            alt="Drag"
                            draggable="false"
                          />
                        </span>
                      </li>

                      {/* <Hand
                        onClick={this.onClickHand}
                        activeButton={this.state.activeButton}
                        hideButton={this.props.isButtonsShown}
                      /> */}
                      <Mouse
                        // onClick={this.onClickSelecter}
                        onClick={() =>
                          this.onClickMouseSelectButton("mouse-options")
                        }
                        activeButton={this.state.activeButton}
                        hideButton={this.props.isButtonsShown}
                        activeMouseButton={this.state.activeMouseButton}
                      />
                      {/* <PrevPage onClick={this.onClickPrevPage}></PrevPage>
                      <NextPage onClick={this.onClickNextPage}></NextPage> */}
                      <PrevPage
                        onClick={this.onClickPrevPage}
                        currentPageNumber={this.props.currentPageNumber}
                        TotalPageNumber={this.props.totalPageNumber}
                      ></PrevPage>
                      <NextPage
                        onClick={this.onClickNextPage}
                        currentPageNumber={this.props.currentPageNumber}
                        totalPageNumber={this.props.totalPageNumber}
                      ></NextPage>
                      {/* <ZoomOut
                      onClick={this.onClickZoomOut}
                      activeButton={this.state.activeButton}
                      hideButton={this.props.isButtonsShown}
                    />
                    <FullScreen
                      onClick={this.onClickFullScreen}
                      activeButton={this.state.activeButton}
                    />
                    <ZoomIn
                      onClick={this.onClickZoomIn}
                      activeButton={this.state.activeButton}
                      hideButton={this.props.isButtonsShown}
                    /> */}

                      {/* <UndoRedo
                      onClick={this.onClickUndoRedo}
                      onClickUndo={this.props.onClickUndo}
                      onClickRedo={this.props.onClickRedo}
                      openableItems={this.state.openableItems}
                      activeButton={this.state.activeButton}
                    /> */}
                      <ZoomToolbar
                        onClick={() => this.onClickButton("zoom-options")}
                        activeButton={this.state.activeButton}
                        hideButton={this.props.isButtonsShown}
                      />

                      <DrawToolbar
                        onClick={() => this.onClickButton("draw-toolbar")}
                        activeButton={this.state.activeButton}
                        hideButton={this.props.isButtonsShown}
                      />

                      {/* <PageOptions
                      onClick={this.onClickPageOptions}
                      onClick={() => this.onClickButton("page-options")}
                      activeButton={this.state.activeButton}
                      hideButton={this.props.isButtonsShown}
                    /> */}
                      {!isSmallScreen && (
                        <>
                          <ActivityOptions
                            onClick={() =>
                              this.onClickButton("activity-options")
                            }
                            activeButton={this.state.activeButton}
                            hideButton={this.props.isButtonsShown}
                          />
                        </>
                      )}
                      {/*<Layers*/}
                      {/*  onClick={this.onClickLayers}*/}
                      {/* onClick={() => this.onClickButton("layers")} */}
                      {/*  activeButton={this.state.activeButton}*/}
                      {/*  openableItems={this.state.openableItems}*/}
                      {/*  onChangeInteractiveObjects={*/}
                      {/*    this.onChangeInteractiveObjects*/}
                      {/*  }*/}
                      {/*  onChangeDrawingObjects={this.onChangeDrawingObjects}*/}
                      {/*/>*/}
                      {/*
                    {this.props.imageCapture.showCapturedImage == false ? (
                      <ScreenCapture
                        onClick={this.props.captureInProcess}
                      ></ScreenCapture>
                    ) : (
                      <ScreenCapture
                        onClick={this.props.hideCaptureImage}
                      ></ScreenCapture>
                    )} */}
                      {!isSmallScreen && showTeacherTools && (
                        <>
                          <TeacherTools
                            onClick={() => this.onClickButton("teacher-tools")}
                            activeButton={this.state.activeButton}
                            /* hideButton={this.props.isButtonsShown}*/
                          />
                        </>
                      )}

                      {devMode && (
                        <>
                          <QuestionOptions
                            onClick={() =>
                              this.onClickButton("question-options")
                            }
                            activeButton={this.state.activeButton}
                            hideButton={this.props.isButtonsShown}
                          />
                          <DevModeTools
                            onClick={() => this.onClickButton("devmode-tools")}
                            activeButton={this.state.activeButton}
                            /* hideButton={this.props.isButtonsShown}*/
                          />
                        </>
                      )}

                      <Menu
                        onClick={() => this.buttonHandler("menu")}
                        activeButton={this.state.activeButton}
                        // openableItems={this.state.openableItems}
                        // onChangeTheme={this.props.onChangeTheme}
                        onClickLoadAll={this.props.onClickLoadAll}
                        zipFileLoaded={this.props.zipFileLoaded}
                        onChangeInteractiveObjects={
                          this.onChangeInteractiveObjects
                        }
                        onChangeDrawingObjects={this.onChangeDrawingObjects}
                        smallScreenShow={isSmallScreen}
                      />
                      {!isSmallScreen && (
                        <>
                          <VerticalToolbar
                            onClick={this.onClickVerticalToolbar}
                            activeButton={this.state.activeButton}
                          />
                        </>
                      )}
                      {/* <PinToolbar
                        onClick={() => this.buttonHandler("pin")}
                        activeButton={this.state.activeButton}
                      /> */}

                      <li
                        className="drag-element"
                        onMouseDown={() => this.onCloseAllOpenItems()}
                      >
                        <span
                          className="button text-bold drag"
                          id="drag2"
                          title="Drag"
                        >
                          <img
                            src={require("../assets/img/svg/toolbar-drag.svg")}
                            alt="Drag"
                            draggable="false"
                          />
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li
                        className="drag-element"
                        onMouseDown={() => this.onCloseAllOpenItems()}
                      >
                        <span className="button drag" id="drag1" title="Drag">
                          <img
                            src={require("../assets/img/svg/toolbar-drag.svg")}
                            alt="Drag"
                            draggable="false"
                          />
                        </span>
                      </li>

                      <PinToolbar
                        onClick={() => this.buttonHandler("pin")}
                        activeButton={this.state.activeButton}
                      />

                      <li
                        className="drag-element"
                        onMouseDown={() => this.onCloseAllOpenItems()}
                      >
                        <span
                          className="button text-bold drag"
                          id="drag2"
                          title="Drag"
                        >
                          <img
                            src={require("../assets/img/svg/toolbar-drag.svg")}
                            alt="Drag"
                            draggable="false"
                          />
                        </span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>

              <div
                className={`draw-toolbar ${
                  this.state.activeButton === "draw-toolbar" ? "open" : ""
                }`}
                id="draw-toolbar"
              >
                <div className="buttons has-addons">
                  <ul>
                    <Pen
                      onClick={() => this.buttonHandler("pencil")}
                      // onClick={() => this.onClickSubButton("pencil")}
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <Highliter
                      onClick={() => this.buttonHandler("highlighter")}
                      // onClick={() => this.onClickSubButton("highlighter")}
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <Text
                      onClick={() => this.buttonHandler("text")}
                      // onClick={() => this.onClickSubButton("text")}
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <Eraser
                      onClick={() => this.buttonHandler("eraser")}
                      // onClick={() => this.onClickSubButton("eraser")}
                      onClickEraseSelected={this.onClickEraseSelected}
                      onClickEraseAll={this.onClickEraseAll}
                      activeSubButton={this.state.activeSubButton}
                      activeSubChildButton={this.state.activeSubChildButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <Shapes
                      onClick={() => this.onClickSubButton("shapes")}
                      onClickShapeLine={() =>
                        // this.onClickSubChildButton("shapeline")
                        this.buttonHandler("shapeline")
                      }
                      onClickShapeCircle={() => this.buttonHandler("circle")}
                      onClickEmptySquare={() =>
                        this.buttonHandler("emptysquare")
                      }
                      onClickEmptyCircle={() =>
                        this.buttonHandler("emptycircle")
                      }
                      onClickShapeSquare={() => this.buttonHandler("rectangle")}
                      activeButton={this.state.activeSubButton}
                      activeSubChildButton={this.state.activeSubChildButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <Line
                      onClick={() => this.buttonHandler("line")}
                      onClickSubItem={this.onClickLineSubItem}
                      activeButton={this.state.activeSubButton}
                      lineWidth={this.props.toolbar.lineWidth}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <TextStrokeColor
                      onClick={() => this.buttonHandler("textstroke")}
                      color={this.props.toolbar.lineColor}
                      activeButton={this.state.activeSubChildButton}
                    />
                    <FillColor
                      onClick={() => this.buttonHandler("fillcolor")}
                      color={this.props.toolbar.fillColor}
                      activeButton={this.state.activeSubChildButton}
                    />
                    <Undo
                      onClickUndo={() => this.props.triggerUndo(true)}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <Redo
                      onClickRedo={() => this.props.triggerRedo(true)}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                  </ul>
                </div>
              </div>

              <div
                className={`draw-toolbar   ${
                  this.state.activeButton === "zoom-options" ? "open" : ""
                }
                ${
                  !isSmallScreen ? "zoom-options" : "zoom-options-small-screen"
                } `}
                id="zoom-toolbar"
              >
                <div className="buttons has-addons">
                  <ul>
                    <ZoomIn
                      onClick={() => this.buttonHandler("zoomin")}
                      activeButton={this.state.activeSubButton}
                      hideButton={this.props.isButtonsShown}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <ZoomNormal
                      onClick={() => this.buttonHandler("zoomnormal")}
                      activeButton={this.state.activeSubButton}
                      hideButton={this.props.isButtonsShown}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <ZoomOut
                      onClick={() => this.buttonHandler("zoomout")}
                      activeButton={this.state.activeSubButton}
                      hideButton={this.props.isButtonsShown}
                      shouldShowButtonLabels={this.props.showLabels}
                    />

                    <FullScreen
                      onClick={() => this.buttonHandler("fullscreen")}
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                  </ul>
                </div>
              </div>

              <div
                className={`draw-toolbar mouse-options ${
                  this.state.activeButton === "mouse-options" ? "open" : ""
                }`}
                id="mouse-toolbar"
              >
                <div className="buttons has-addons">
                  <ul>
                    <Hand
                      onClick={() => this.buttonHandler("pan")}
                      activeButton={this.state.activeMouseButton}
                      hideButton={this.props.isButtonsShown}
                    />
                    <Selecter
                      onClick={() => this.buttonHandler("select")}
                      activeButton={this.state.activeMouseButton}
                      hideButton={this.props.isButtonsShown}
                    />
                    <HardDragger
                      onClick={() => this.buttonHandler("harddrag")}
                      activeButton={this.state.activeMouseButton}
                      hideButton={this.props.isButtonsShown}
                    />
                  </ul>
                </div>
              </div>

              {/* <div
                className={`page-options ${
                  this.state.pageOptionsOpened ? "open" : ""
                }`}
                id="draw-toolbar"
              >
                <div className="buttons has-addons">
                  <ul>
                    <Up onClick={this.onClickScrollTop} />
                    <Down onClick={this.onClickScrollDown}></Down>

                  </ul>
                </div>
              </div> */}
              {/* {this.state.activeButton === "page-options" && (
                <div
                  className={`page-options2 ${
                    this.state.pageOptionsOpened ? "open" : ""
                  }`}
                  id="page-options-toolbar"
                >
                  <div className="buttons has-addons">
                    <ul>
                      <PrevPage onClick={this.onClickPrevPage}></PrevPage>

                      <NextPage onClick={this.onClickNextPage}></NextPage>

                      <Up onClick={this.onClickScrollTop} />
                      <Down onClick={this.onClickScrollDown}></Down>
                    </ul>
                  </div>
                </div>
              )} */}

              <div
                className={`draw-toolbar activity-options ${
                  this.state.activeButton === "activity-options" ? "open" : ""
                }`}
                id="add-media-toolbar"
              >
                <div className="buttons has-addons">
                  <ul>
                    <AddBlankPage
                      changeMyTool={(toolName) =>
                        this.onClickSubButton(toolName)
                      }
                      activeButton={this.state.activeSubButton}
                      activeSubButton={this.state.activeSubButton}
                      state={this.state}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <AddNote
                      changeMyTool={(toolName) =>
                        this.onClickSubButton(toolName)
                      }
                      activeButton={this.state.activeSubButton}
                      activeSubButton={this.state.activeSubButton}
                      state={this.state}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <AddHideableAnnotation
                      // changeMyTool={(toolName) => {
                      //   this.onClickSubButton(toolName);
                      //   this.props.setAnnotationSelectionMode(true);
                      //   setTimeout(() => {
                      //     this.onClickButton("draw-toolbar");
                      //   }, 1000);
                      // }}
                      // activeButton={this.state.activeSubButton}
                      // activeSubButton={this.state.activeSubButton}
                      // state={this.state}
                      onClick={() => {
                        this.buttonHandler("qhideable");
                        // this.props.setAnnotationSelectionMode(true);
                      }}
                      activeButton={this.state.activeButton}
                      hideButton={this.props.isButtonsShown}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <AddVideo
                      changeMyTool={(toolName) =>
                        this.onClickSubButton(toolName)
                      }
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <AddH5p
                      changeMyTool={() => this.onClickSubButton("addh5p")}
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <AddAudio
                      changeMyTool={() => this.onClickSubButton("addaudio")}
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <AddImage
                      changeMyTool={() => this.onClickSubButton("addimage")}
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                  </ul>
                </div>
              </div>

              <div
                style={{ marginRight: 28 }}
                className={`draw-toolbar question-options ${
                  this.state.activeButton === "question-options" ? "open" : ""
                }
                ${!isSmallScreen ? "" : "question-options-small-screen"}

                `}
                id="add-questions-toolbar"
              >
                <div className="buttons has-addons">
                  <ul>
                    <AddMCQuestion
                      changeMyTool={() =>
                        this.onClickSubButton("addmcquestion")
                      }
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <AddMRQuestion
                      changeMyTool={() =>
                        this.onClickSubButton("addmrquestion")
                      }
                      activeButton={this.state.activeSubButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    {/* <PrevPage onClick={this.onClickPrevPage}></PrevPage>

                    <NextPage onClick={this.onClickNextPage}></NextPage>

                    <Up onClick={this.onClickScrollTop} />
                    <Down onClick={this.onClickScrollDown}></Down> */}
                    {/* <li>
                        <span
                          className="button normal-btn next-page"
                          title="Multiple choice"
                        >
                          <img
                             src={require("../assets/img/svg/tools-multiple-choice.svg")}
                            alt="Multiple choice"
                          />
                        </span>
                      </li> */}
                    {/* <li>
                      <span
                        className="button normal-btn next-page"
                        title="Multiple Elective"
                      >
                        <img
                          src={require("../assets/img/svg/tools-multiple-elective.svg")}
                          alt="Multiple Elective"
                        />
                      </span>
                    </li> */}
                    <li>
                      <span
                        className="button normal-btn next-page"
                        title="True False"
                        data-tip={"True False"}
                      >
                        <img
                          src={require("../assets/img/svg/tools-true-false.svg")}
                          alt="True False"
                        />
                      </span>
                    </li>
                    <li>
                      <span
                        className="button normal-btn next-page"
                        title="Drag Drop"
                        data-tip={"Drag Drop"}
                      >
                        <img
                          src={require("../assets/img/svg/drag-drop.svg")}
                          alt="Drag Drop"
                        />
                      </span>
                    </li>
                    <li>
                      <span
                        className="button normal-btn next-page"
                        title="Fill in the Blank"
                        data-tip={"Fill in the Blank"}
                      >
                        <img
                          src={require("../assets/img/svg/tools-fill-in-the-blank.svg")}
                          alt="Fill in the Blank"
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className={`draw-toolbar teacher-tools ${
                  this.state.activeButton === "teacher-tools" ? "open" : ""
                }`}
                id="add-media-toolbar"
              >
                <div className="buttons has-addons">
                  <ul>
                    <Capture
                      // onClick={this.onClickCapture}
                      onClick={() => this.buttonHandler("capture")}
                      activeButton={this.state.activeButton}
                      hideButton={this.props.isButtonsShown}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <Curtain
                      onClick={() => this.buttonHandler("curtain")}
                      onClick={this.onClickCurtain}
                      activeButton={this.state.activeButton}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                  </ul>
                </div>
              </div>

              <div
                className={`draw-toolbar devmode-tools ${
                  this.state.activeButton === "devmode-tools" ? "open" : ""
                }
                ${!isSmallScreen ? "" : "devmode-tools-small-screen"} `}
                id="add-media-toolbar"
              >
                <div className="buttons has-addons">
                  <ul>
                    <AddTimer
                      // onClick={this.onClickCapture}
                      onClick={() => this.buttonHandler("addtimer")}
                      activeButton={this.state.activeButton}
                      hideButton={this.props.isButtonsShown}
                      shouldShowButtonLabels={this.props.showLabels}
                    />
                    <li>
                      <span
                        className={`button normal-btn next-page`}
                        title={"Add Timer"}
                        data-tip={"Add Timer"}
                      >
                        <img
                          src={require("../assets/img/svg/tools-area-zoom.svg")}
                          alt={"Add Timer"}
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalPageNumber: state.pages.totalPageNumber,
    currentPageNumber: state.pages.currentPageNo,
    TotalPageNumber: state.pages.totalPageNumber,
    scaleZoomFactorVal: state.scaleZoomFactor,
    isButtonsShown: state.isButtonsShown,
    selectedTool: state.selectedTool.selectedTool,
    selectedToolChangedFromOutside:
      state.selectedTool.selectedToolChangedFromOutside,
    searchBarOpen: state.searchBarOpen,
    imageCapture: state.imageCapture,
    canvasVals: state.scaleZoomFactor.canvas,
    controlledPosition: state.toolbar.toolbarPosition,
    showAnnotations: state.settings.showAnnotations,
    toolbar: state.toolbar,
    showLabels: state.toolbar.showLabels,
    toolbarSize: state.toolbar.toolbarSize,
    roles: state.login.user_profile.roles,
  };
};

export default connect(
  mapStateToProps,
  {
    pageNoChange,
    toggleButtons,
    captureInProcess,
    hideCaptureImage,
    hideInteractiveObjects,
    showInteractiveObjects,
    setToolbarPosition,
    resetToolbarPosition,
    pinToolbar,
    unpinToolbar,
    setScalePageReset,
    changeSelectedTool,
    setAnnotationSelectionMode,
    showAnnotations,
    hideAnnotations,
    triggerRedo,
    triggerUndo,
    triggerRemove,
    triggerRemoveAll,
    triggerZoomIn,
    triggerZoomOut,
    setLineWidth,
    setLineColor,
    setFillColor,
    setToolbarVertical,
  },
  null,
  { forwardRef: true }
)(Toolbar);
