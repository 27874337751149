import api from "../api";

import { setLoginSuccess } from "./login";

export const details = () => {
  console.log("geldi");
  const baseUrl =
    process.env.NODE_ENV === "_development"
      ? "http://" + localStorage.domain + ".localhost:8003/api/"
      : "https://" + localStorage.domain + ".uppybook.com/api/";//TODO cenibook.com

  return (dispatch) => {
    api
      .getData(baseUrl + "user_details/") //
      .then((res) => {
        // console.log("res", res);
        dispatch(setLoginSuccess(res.data.success));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};
