import {
  CHANGE_TOOLBAR_VIEW,
  SET_TOOLBAR_POSITION,
  RESET_TOOLBAR_POSITION,
  PIN_TOOLBAR,
  UNPIN_TOOLBAR,
  SET_LINE_WIDTH,
  SET_FILL_COLOR,
  SET_LINE_COLOR,
  SET_TOOLBAR_VERTICAL,
  SET_TOOLBAR_SIZE,
  SHOW_TOOLBAR_LABELS
} from "../actions/actionTypes";

var defaultToolbarState = {
  toolbarPosition: { x: 0, y: 0 },
  verticalToolbar: true,
  toolbarPin: false,
  activeButton: null,
  selectedTool: null,
  // drawToolbarOpened: false,
  // openableItems: {
  //   layersOpened: false,
  //   menuOpened: false,
  //   eraserOpened: false,
  //   lineOpened: false,
  //   shapesOpened: false,
  //   // undoRedoOpened: false,
  //   textStrokeOpened: false,
  //   fillColorOpened: false,
  // },
  lineColor: "#ff0000",
  fillColor: "#00ff22",
  lineWidth: 3,
  toolbarSize: 1,
  showLabels: false,
};

export default (state = defaultToolbarState, action) => {
  switch (action.type) {
    case CHANGE_TOOLBAR_VIEW:
      return true;

    case SET_TOOLBAR_POSITION:
      return {
        ...state,
        toolbarPosition: { x: action.payload.x, y: action.payload.y },
      };
    case SET_TOOLBAR_VERTICAL:
      return {
        ...state,
        verticalToolbar: action.payload,
      };

    case RESET_TOOLBAR_POSITION:
      return {
        ...state,
        toolbarPosition: { x: 0, y: 0 },
      };
    case PIN_TOOLBAR:
      return {
        ...state,
        toolbarPin: true,
      };
    case UNPIN_TOOLBAR:
      return {
        ...state,
        toolbarPin: false,
      };
    case SET_LINE_WIDTH:
      return {
        ...state,
        lineWidth: action.payload,
      };
    case SET_LINE_COLOR:
      return {
        ...state,
        lineColor: action.payload,
      };
    case SET_FILL_COLOR:
      return {
        ...state,
        fillColor: action.payload,
      };
    case SET_TOOLBAR_SIZE:
      return {
        ...state,
        toolbarSize: action.payload,
      };
    case SHOW_TOOLBAR_LABELS:
      return {
        ...state,
        showLabels: action.payload,
      };

    default:
      return state;
  }
};
