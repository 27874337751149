/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import { themeChange } from "../../actions/theme";
import { withTranslation } from "react-i18next";
import { enableButtonMove, disableButtonMove } from "../../actions/settings";
import { setToolbarSize, showToolbarLabels } from "../../actions/toolbar";

class Menu extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   theme: ""
    // };
  }

  /**
   * when the user selects a theme, it passes the theme value to upper component
   */
  // onChangeTheme = theme => {
  //   this.setState({ theme });
  //   this.props.onChangeTheme(theme);
  // };

  onClickLogout = () => {
    console.log("logged out");
    // localStorage.removeItem('auth_token');
    //  const history = createBrowserHistory({ forceRefresh: true });
    //  history.push("/");
    localStorage.clear();
    window.location.reload();
  };

  render() {
    // console.log('this.props.activeButton', this.props.activeButton)
    const isSmallScreen = this.props.canvasVals
      ? window.innerWidth < 340
      : false;
    // console.log('MENU isSmallScreen ', isSmallScreen , this.props.canvasVals.vpW, window.innerWidth)
    return (
      <>
        <li
          className={`sub-menu ${
            this.props.activeButton === "menu" ? "open" : ""
          }`}
          //title={this.props.t("Settings")}
          // onClick={this.props.onClick}
          // data-tip={this.props.t("Settings")}
        >
          <span
            className={`button normal-btn menu ${
              this.props.activeButton === "menu" ? "active" : ""
            }`}
            title={this.props.t("Settings")}
            onClick={this.props.onClick}
            data-tip={this.props.t("Settings")}
          >
            <img
              src={require("../../assets/img/svg/toolbar-menu.svg")}
              alt={this.props.t("Settings")}
            />
          </span>
          {this.props.activeButton === "menu" && (
            <div
              className={`user-menu ${
                isSmallScreen ? "small-screen-sub-menu" : ""
              }`}
            >
              <div className="user-info">
                <div className="user-photo is-hidden-mobile">
                  <figure className="image is-48x48">
                    <img
                      src={require("../../assets/img/pp.png")}
                      alt={this.props.t("Profile")}
                      className="is-rounded"
                    />
                  </figure>
                </div>
                <div className="field is-grouped is-grouped-left is-grouped-multiline user-title capitalize is-hidden-tablet">
                  <span className="user-name">{this.props.profile.name}</span>
                  <span className=" is-hidden-tablet px-2 ">{" / "}</span>
                  <span className="user-work">
                    {this.props.profile.domain_name}
                  </span>
                </div>
                <div className="user-title capitalize is-hidden-mobile">
                  <span className="user-name">{this.props.profile.name}</span>
                  <span className="user-work">
                    {this.props.profile.domain_name}
                  </span>
                </div>
              </div>
              <div className="user-menu-buttons">
                <div className="">
                  <div className="field is-grouped not-selectable">
                    <input
                      id="io-switchSmall"
                      type="checkbox"
                      name="io-switchSmall"
                      className="switch is-small is-rounded is-outlined is-success"
                      // defaultChecked={!this.props.hideInteractiveObjectsVal}
                      checked={!this.props.hideInteractiveObjectsVal}
                      onChange={this.props.onChangeInteractiveObjects}
                    />
                    <label htmlFor="io-switchSmall">
                      {this.props.t("Interactive Objects")}
                    </label>
                  </div>
                  <div className="field is-grouped not-selectable">
                    <input
                      id="do-switchSmall"
                      type="checkbox"
                      name="do-switchSmall"
                      // defaultChecked={true}
                      checked={this.props.showAnnotations}
                      className="switch is-small is-rounded is-outlined is-success"
                      onChange={this.props.onChangeDrawingObjects}
                    />
                    <label htmlFor="do-switchSmall">
                      {this.props.t("Drawing Objects")}
                    </label>
                  </div>
                  <div className="field is-grouped not-selectable ">
                    <input
                      id="mv-switchSmall"
                      type="checkbox"
                      name="mv-switchSmall"
                      defaultChecked={this.props.moveButtonsVal}
                      className="switch is-small is-rounded is-outlined is-success"
                      onChange={
                        !this.props.moveButtonsVal
                          ? this.props.enableButtonMove
                          : this.props.disableButtonMove
                      }
                    />
                    <label htmlFor="mv-switchSmall">
                      {this.props.t("Moving Buttons")}
                    </label>
                  </div>
                  <div className="field is-grouped not-selectable ">
                    <input
                      id="bt-switchSmall"
                      type="checkbox"
                      name="bt-switchSmall"
                      defaultChecked={this.props.showLabels}
                      className="switch is-small is-rounded is-outlined is-success"
                      onChange={() =>
                        this.props.showToolbarLabels(!this.props.showLabels)
                      }
                    />
                    <label htmlFor="bt-switchSmall">
                      {this.props.t("Button Labels")}
                    </label>
                  </div>
                </div>
                {/*  <div className="field is-grouped not-selectable">
              <button
                className="w-100 button"
                disabled={this.props.zipFileLoaded}
                onClick={this.props.onClickLoadAll}
              >
                {this.props.t("Load All Images")}
              </button>
            </div> */}
              </div>
              {!this.props.smallScreenShow && (
                <>
                  <div className="theme-selection mt">
                    <div className="select-area">
                      <label>{this.props.t("Toolbar Size")}</label>
                      <div className="select is-small">
                        <select
                          value={this.props.toolbarSize}
                          onChange={(e) =>
                            this.props.setToolbarSize(e.target.value)
                          }
                        >
                          <option value={1}>1X</option>
                          <option value={1.5}>1.5X</option>
                          <option value={2}>2X</option>
                        </select>
                        <span className="icon">
                          <img
                            src={require("../../assets/img/svg/toolbar-zoom-in.svg")}
                            alt={this.props.t("Toolbar Size")}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="theme-selection mt-2">
                <div className="select-area">
                  <label htmlFor="do-switchSmall">
                    {this.props.t("Theme")}
                  </label>
                  <div className="select is-small">
                    <select
                      value={this.props.theme}
                      onChange={(e) => this.props.themeChange(e.target.value)}
                    >
                      <option value="">{this.props.t("Dark")}</option>
                      <option value="light">{this.props.t("Light")}</option>
                      <option value="dark-blue">
                        {this.props.t("Dark Blue")}
                      </option>
                      <option value="green">{this.props.t("Green")}</option>
                      <option value="purple">{this.props.t("Purple")}</option>
                    </select>
                    <span className="icon">
                      <img
                        src={require("../../assets/img/svg/pagi-combobox-arrow.svg")}
                        alt="First Page"
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="theme-selection mt-2">
                <div className="select-area">
                  <label htmlFor="do-switchSmall">
                    {this.props.t("Language")}
                  </label>
                  <div className="select is-small">
                    <select
                      value={this.props.i18n.language}
                      onChange={(e) =>
                        this.props.i18n.changeLanguage(e.target.value)
                      }
                      // onChange={(e) => this.props.themeChange(e.target.value)}
                    >
                      <option value="tr">TR</option>
                      <option value="en">EN</option>
                      <option value="fr">FR</option>
                      <option value="ar">AR</option>
                      <option value="ja">JA</option>
                    </select>
                    <span className="icon">
                      <img
                        src={require("../../assets/img/svg/pagi-combobox-arrow.svg")}
                        alt="First Page"
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="has-text-centered	 not-selectable">
                <button
                  className=" menu-button "
                  onClick={() => this.onClickLogout()}
                >
                  {this.props.t("Logout")}
                </button>
              </div>
            </div>
          )}
        </li>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    zipFileLoaded: state.pages.zipLoaded,
    profile: state.login.user_profile,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    moveButtonsVal: state.settings.moveButtons,
    editModeVal: state.settings.editMode,
    canvasVals: state.scaleZoomFactor.canvas,
    showAnnotations: state.settings.showAnnotations,
    toolbarSize: state.toolbar.toolbarSize,
    showLabels: state.toolbar.showLabels,

    // domain: state.login.domain,
  };
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     enableButtonMove: () => dispatch(enableButtonMove()),
//     disableButtonMove: () => dispatch(disableButtonMove()),
//   };
// };
export default connect(mapStateToProps, {
  themeChange,
  enableButtonMove,
  disableButtonMove,
  setToolbarSize,
  showToolbarLabels,
})(withTranslation()(Menu));
