import {PAGE_LOADED} from '../actions/actionTypes'

export default (state = false, action) => {
    switch (action.type) {

        case PAGE_LOADED:
            return true;

        default:
            return state;
    }
};