import axios from "axios";
import configuration from "./config.json";
import React from 'react'


class BaseService {
  constructor() {
    // const domain = useSelector(state => state.login.domain)
    // this.baseUrl =
    //   process.env.NODE_ENV === "_development"
    //     ? "http://" + domain + ".localhost:8003/api/"
    //     : "http://" + domain + ".cenibook.com/api/";

    axios.interceptors.request.use(function (config) {
      config.headers.Authorization = "Bearer " + localStorage.auth_token;

      return config;
    });
  }

  getData(path) {
    // let url = `${this.baseUrl}${path}`;
    let url = `${path}`;
    return axios.get(`${url}`);
  }

  putData(path, data) {
    let url = `${path}`;
    return axios.put(`${url}`, data);
  }

  postData(path, data) {
    let url = `${path}`;
    return axios.post(`${url}`, data);
  }

  deleteData(path) {
    let url = `${path}`;
    return axios.delete(`${url}`);
  }
}

export default new BaseService();
