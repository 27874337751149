import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";

export default function AddMCQuestion(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickAddMRQuestion = () => {
    console.log("--Add a MR Question..");
    dispatch(toaster(t("Click a point to add a Multiple Response Question"), "info"));
    props.changeMyTool("addmrquestion");
  };
  return (
    <li onClick={onClickAddMRQuestion}>
      <span
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "addmcquestion" ? "active" : ""

        }`}
        title={t("Add a Multiple Response Question")}
        data-tip={t("Add a Multiple Response Question")}
      >
        <img
          src={require("../../assets/img/svg/tools-multiple-elective.svg")}
          alt={t("Add a Multiple Response Question")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Quiz")}</span>}
      </span>
    </li>
  );
}
