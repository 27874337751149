export const SIDEBAR_OPEN = "SIDEBAR_OPEN";
export const SIDEBAR_CLOSE = "SIDEBAR_CLOSE";
export const THEME_CHANGE = "THEME_CHANGE";
export const PAGE_NO_CHANGE = "PAGE_NO_CHANGE";
export const GET_PAGES = "GET_PAGES";
export const RESET_PAGES = "RESET_PAGES";
export const PAGE_LOADED = "PAGE_LOADED";
export const GET_ZIP = "GET_ZIP";
export const ZIP_DOWN_PROG = "ZIP_DOWN_PROG";
export const CHANGE_TOOLBAR_VIEW = "CHANGE_TOOLBAR_VIEW";
export const HIDE_INTERACTIVE_OBJECTS = "HIDE_INTERACTIVE_OBJECTS";
export const SHOW_INTERACTIVE_OBJECTS = "SHOW_INTERACTIVE_OBJECTS";
export const HIDE_ANNOTATIONS = "HIDE_ANNOTATIONS";
export const SHOW_ANNOTATIONS = "SHOW_ANNOTATIONS";
export const SCALE_PAGE = "SCALE_PAGE";
export const PAGE_SCALE_UP = "PAGE_SCALE_UP";
export const PAGE_SCALE_DOWN = "PAGE_SCALE_DOWN";
export const PAGE_SCALE_MAX = "PAGE_SCALE_MAX";
export const PAGE_SCALE_SET = "PAGE_SCALE_SET";
export const PAGE_SCALE_RESET = "PAGE_SCALE_RESET";
export const BUTTONS_SHOWN = "BUTTONS_SHOWN";
export const BUTTONS_HIDDEN = "BUTTONS_HIDDEN";
export const IMAGES_LOADED = "IMAGES_LOADED";
export const IMAGES_UNLOADED = "IMAGES_UNLOADED";
export const SELECTED_TOOL_CHANGED = "SELECTED_TOOL_CHANGED";
export const SELECTED_TOOL_CHANGED_FROM_OUTSIDE =
  "SELECTED_TOOL_CHANGED_FROM_OUTSIDE";
// export const SELECTED_TOOL_MOUSE_BUTTON_CHANGED =
//   "SELECTED_TOOL_MOUSE_BUTTON_CHANGED";
// export const SELECTED_TOOL_SUB_CHILD_BUTTON_CHANGED =
//   "SELECTED_TOOL_SUB_CHILD_BUTTON_CHANGED";
// export const SELECTED_TOOL_SUB_BUTTON_CHANGED =
//   "SELECTED_TOOL_SUB_BUTTON_CHANGED";
export const GET_PIXABAY = "GET_PIABAY";
export const CLOSE_PIXABAY = "CLOSE_PIXABAY";
export const GET_UNSPLASH = "GET_UNSPLASH";
export const CLOSE_UNSPLASH = "CLOSE_UNSPLASH";
export const GET_PIXABAYVIDEO = "GET_PIXABAYVIDEO";
export const CLOSE_PIXABAYVIDEO = "CLOSE_PIXABAYVIDEO";
export const SEARCHBAR_OPEN = "SEARCHBAR_OPEN";
export const SEARCHBAR_CLOSE = "SEARCHBAR_CLOSE";
export const GET_WIKIZERO = "GET_WIKIZERO";
export const CLOSE_WIKIZERO = "CLOSE_WIKIZERO";
export const GET_WIKIPEDIA = "GET_WIKIPEDIA";
export const CLOSE_WIKIPEDIA = "CLOSE_WIKIPEDIA";
export const GET_WIKTIONARY = "GET_WIKTIONARY";
export const CLOSE_WIKTIONARY = "CLOSE_WIKTIONARY";
export const GET_QWANT = "GET_QWANT";
export const CLOSE_QWANT = "CLOSE_QWANT";
export const INDEX_OPEN = "INDEX_OPEN";
export const INDEX_CLOSE = "INDEX_CLOSE";
export const GET_MERRIAM = "GET_MERRIAM";
export const GET_MERRIAM_SOUND = "GET_MERRIAM_SOUND";
export const GET_MERRIAM_MODAL = "GET_MERRIAM_MODAL";
export const CLOSE_MERRIAM_MODAL = "CLOSE_MERRIAM_MODAL";
export const CAPTURED_IMAGE_SHOW = "CAPTURED_IMAGE_SHOW";
export const CAPTURED_IMAGE_HIDE = "CAPTURED_IMAGE_HIDE";
export const CAPTURED_INPROCESS = "CAPTURED_INPROCESS";
export const CAPTURED_NOT_INPROCESS = "CAPTURED_NOT_INPROCESS";
export const CAPTURE_ON = "CAPTURE_ON";
export const CAPTURE_OFF = "CAPTURE_OFF";
export const SET_LOGIN_PENDING = "SET_LOGIN_PENDING";
export const SET_LOGIN_SUCCESS = "SET_LOGIN_SUCCESS";
export const SET_BUTTON_SCALE_FACTOR = "SET_BUTTON_SCALE_FACTOR";
export const SET_LIBRARY = "SET_LIBRARY";
export const SET_DOMAIN = "SET_DOMAIN";
export const SET_LIBRARY_FROM_LOCAL = "SET_LIBRARY_FROM_LOCAL";

// export const REMOVE_WIKIZERO = "SEARCHBAR_CLOSE";
export const ADD_BUTTON_MODAL_OPEN = "ADD_BUTTON_MODAL_OPEN";
export const ADD_BUTTON_MODAL_CLOSE = "ADD_BUTTON_MODAL_CLOSE";
export const SHOW_TOAST = "SHOW_TOAST";
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const INSERT_ACTIVITY = "INSERT_ACTIVITY";
export const CANCEL_ACTIVITY = "CANCEL_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const DONE_ACTIVITY = "DONE_ACTIVITY";
export const EDIT_ACTIVITY = "EDIT_ACTIVITY";
export const SELECT_MC_QUESTION = "SELECT_MC_QUESTION";
export const SUBMIT_MC_QUESTION = "SUBMIT_MC_QUESTION";
export const MC_QUESTION_MODAL_OPEN = "MC_QUESTION_MODAL_OPEN";
export const MC_QUESTION_MODAL_CLOSE = "MC_QUESTION_MODAL_CLOSE";
export const ADD_MC_QUESTION_MODAL_OPEN = "ADD_MC_QUESTION_MODAL_OPEN";
export const ADD_MC_QUESTION_MODAL_CLOSE = "ADD_MC_QUESTION_MODAL_CLOSE";
export const HIDE_TOOLTIPS = "HIDE_TOOLTIPS";
export const SHOW_TOOLTIPS = "SHOW_TOOLTIPS";
export const ENABLE_BUTTON_MOVE = "ENABLE_BUTTON_MOVE";
export const DISABLE_BUTTON_MOVE = "DISABLE_BUTTON_MOVE";
export const ENABLE_EDIT_MODE = "ENABLE_EDIT_MODE";
export const DISABLE_EDIT_MODE = "DISABLE_EDIT_MODE";
export const ENABLE_STUDIO_MODE = "ENABLE_STUDIO_MODE";
export const DISABLE_STUDIO_MODE = "DISABLE_STUDIO_MODE";
export const SET_TOOLBAR_POSITION = "SET_TOOLBAR_POSITION";
export const SET_TOOLBAR_VERTICAL = "SET_TOOLBAR_VERTICAL";
export const SET_BACKGROUND_IMAGE_OPACITY = "SET_BACKGROUND_IMAGE_OPACITY";
export const RESET_TOOLBAR_POSITION = "RESET_TOOLBAR_POSITION";
export const SET_PAGE_FROM_PAN = "SET_PAGE_FROM_PAN";
export const PIN_TOOLBAR = "PIN_TOOLBAR";
export const UNPIN_TOOLBAR = "UNPIN_TOOLBAR";
export const SET_ANNOTATION_SELECTION_SAVE_MODE =
  "SET_ANNOTATION_SELECTION_SAVE_MODE"; //FOR ADDING ANNOTATIONS TO A QUESTION SHOW HIDE BUTTON
export const SET_ANNOTATION_SELECTION_COORDS =
  "SET_ANNOTATION_SELECTION_COORDS";
export const RESET_ANNOTATION_SELECTION = "RESET_ANNOTATION_SELECTION";
export const SAVE_ANNOTATION_SELECTION_TO_BUTTON =
  "SAVE_ANNOTATION_SELECTION_TO_BUTTON";
export const UNDO_TRIGGERED = "UNDO_TRIGGERED";
export const REDO_TRIGGERED = "REDO_TRIGGERED";
export const REMOVE_TRIGGERED = "REMOVE_TRIGGERED";
export const REMOVE_ALL_TRIGGERED = "REMOVE_ALL_TRIGGERED";
export const ZOOM_IN_TRIGGERED = "ZOOM_IN_TRIGGERED";
export const ZOOM_OUT_TRIGGERED = "ZOOM_OUT_TRIGGERED";
export const SET_LINE_WIDTH = "SET_LINE_WIDTH";
export const SET_FILL_COLOR = "SET_FILL_COLOR";
export const SET_LINE_COLOR = "SET_LINE_COLOR";
export const IMAGE_LOADED = "IMAGE_LOADED";
export const SET_EDIT_SELECTION_MODE = "SET_EDIT_SELECTION_MODE";
export const SET_SELECTION_COPY = "SET_SELECTION_COPY";
export const SET_SELECTION_PASTE = "SET_SELECTION_PASTE";
export const SET_SELECTION_REMOVE = "SET_SELECTION_REMOVE";
export const SET_SELECTION_UNDO = "SET_SELECTION_UNDO";
export const SET_SELECTION_REDO = "SET_SELECTION_REDO";
export const SET_ANNOTATION_BEFORE_STATE = "  SET_ANNOTATION_BEFORE_STATE";
export const SHOW_ANNOTATION_BY_ID = "  SHOW_ANNOTATION_BY_ID";
export const SET_TOOLBAR_SIZE = "  SET_TOOLBAR_SIZE";
export const SHOW_TOOLBAR_LABELS = "  SHOW_TOOLBAR_LABELS";
export const SET_PAGE_PART_ZOOM_MODE = "SET_PAGE_PART_ZOOM_MODE";
export const SET_PAGE_PART_ZOOM_OBJECT = "SET_PAGE_PART_ZOOM_OBJECT";
