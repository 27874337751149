import axios from "axios";
import { insertActivity } from "../actions/addActivity";
import { INSERT_ACTIVITY } from "../actions/actionTypes";

export const login = (email, password, domain) => {
  const base_url =
    process.env.NODE_ENV === "_development"
      ? "https://" + domain + ".localhost:8003/api/"
      : "https://" + domain + ".uppybook.com/api/";
  return new Promise((resolve, reject) => {
    let params = { email: email, password: password };

    axios
      .post(base_url + "login", params)
      .then((res) => {
        const data = res.data;
        console.log("data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addButtonService = (formData, domain, pageNumber) => {
  const base_url = "https://" + domain + ".uppybook.com/api/";
  // var formData = new FormData();
  // formData.append("content", data.fileupload);
  // formData.append("type", data.type);
  // formData.append("link", data.link);
  // formData.append("left", data.left);
  console.log("--", formData, domain, pageNumber);

  return new Promise((resolve, reject) => {
    // resolve({});

    //   // let params = { email: email, password: password };

    axios
      .post(base_url + "cenibook-pages/store/" + pageNumber, formData)

      .then((res) => {
        const data = res.data;
        console.log(" return data", data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const addButtonFormService = (sendData, domain, pageNumber) => {
  console.log('api----sendData', sendData)
  // return;
  const base_url = "https://" + domain + ".uppybook.com/api/";
  const formData = new FormData();
  formData.append("content", sendData.content);
  formData.append("button_type", sendData.button_type);
  formData.append("style", sendData.style);
  formData.append("top", parseInt(sendData.top));
  formData.append("left", parseInt(sendData.left));
  formData.append("width", parseInt(sendData.width));
  formData.append("height", parseInt(sendData.height));
  formData.append("show", parseInt(sendData.show));
  formData.append("status", parseInt(sendData.status));
  formData.append("sharing", parseInt(sendData.sharing));

  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "cenibook-pages/store/" + pageNumber, formData)
      .then((res) => {
        const data = res.data;
        console.log(" return data", data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateButtonService = (formData, domain, attributeNumber) => {
  const base_url = "https://" + domain + ".uppybook.com/api/";
  console.log("--", formData, domain, attributeNumber);

  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "cenibook-pages/update/" + attributeNumber, formData)
      .then((res) => {
        const data = res.data;
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteButtonService = (domain, attributeId) => {
  const base_url = "https://" + domain + ".uppybook.com/api/";
  ///api/cenibook-pages/delAttr/8985
  console.log("-- delete Attr", attributeId);

  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "cenibook-pages/delAttr/" + attributeId)
      .then((res) => {
        const data = res.data;
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
