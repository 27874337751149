import React from "react";
import ReactAudioPlayer from "react-audio-player";
import MCQuestion from "../Quiz/MCQuestion";
import MRQuestion from "../Quiz/MRQuestion";
// import ReactTestUtils from "react-dom/test-utils"; // ES6
import { isDOMComponent } from "react-dom/test-utils";
import dompurify from "dompurify";
import ReactPlayer from "react-player";

export const renderModalData = (data) => {
  const sanitizer = dompurify.sanitize;

  const minWindowHeight = window.innerHeight / 2;
  const minWindowWidth = window.innerWidth / 2;

  // console.log("renderModalData > data", data);
  switch (data.button_type) {
    case "video":
      let myData3 = iframe(data.content);
      let s3 = myData3.__html;
      let temp3 = document.createElement("div");
      temp3.innerHTML = s3;

      const minimumWidth = window.innerWidth / 2;

      return (
        <div style={{ minWidth: minimumWidth }}>
          {ReactPlayer.canPlay(data.content) ? (
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={data.content}
                width="100%"
                height="100%"
                // light
                controls
              />
            </div>
          ) : (
            <span>
              {/* {t("Please enter a valid link like") +
                " https://youtu.be/SjiSEvh6fJs"} */}
            </span>
          )}
        </div>
        // <div
        //   style={{
        //     minHeight: h5pMinHeight3,
        //     minWidth: h5pMinWidth3,
        //     paddingTop: "20px",
        //   }}
        // >
        //   {/* return ( */}
        //   {/* <div style={{ minHeight: 400 }}> */}
        //   <div
        //     className="interactive-video-player mt-10"
        //     dangerouslySetInnerHTML={iframe(data.content)}
        //   />
        // </div>
      );

    case "videoUrl":
      return (
        <div style={{ minHeight: 400 }}>
          <video controls>
            <source src={data.content} type="video/mp4" />
          </video>
        </div>
      );

    case "h5p":
      let myData = iframe(data.content);
      //   console.log("data.content", data.content);
      //   console.log("sanitizer(data.content)", sanitizer(data.content));
      // console.log(myData.__html);
      var s = myData.__html;
      var temp = document.createElement("div");
      temp.innerHTML = s;
      console.log("temp.firstChild", temp.firstChild);
      const h5pMinHeight = parseInt(
        isDOMComponent(temp.firstChild)
          ? temp.firstChild.getAttribute("height")
          : minWindowHeight
      );
      const h5pMinWidth = parseInt(
        isDOMComponent(temp.firstChild)
          ? temp.firstChild.getAttribute("width")
          : minWindowWidth
      );

      // console.log(h5pMinHeight);
      return (
        <div style={{ height: h5pMinHeight, width: h5pMinWidth }}>
          <div className="loadingText">Content is loading ...</div>
          <div
            className="interactive-h5p-player"
            dangerouslySetInnerHTML={myData}
            style={{ height: h5pMinHeight, width: h5pMinWidth }}
          />
        </div>
      );

    case "h5p-answer":
      let myData2 = iframe(data.content);
      var s2 = myData2.__html;
      var temp2 = document.createElement("div");
      temp2.innerHTML = s2;

      const h5pMinHeight2 = parseInt(
        isDOMComponent(temp2.firstChild)
          ? temp2.firstChild.getAttribute("height")
          : minWindowHeight
      );
      const h5pMinWidth2 = parseInt(
        isDOMComponent(temp2.firstChild)
          ? temp2.firstChild.getAttribute("width")
          : minWindowWidth
      );

      return (
        // <ModalBody style={{ minHeight: h5pMinHeight2 }}>
        <div style={{ minHeight: h5pMinHeight2, minWidth: h5pMinWidth2 }}>
          <div className="loadingText">Content is loading ...</div>
          <div
            className="interactive-h5p-player"
            dangerouslySetInnerHTML={sanitizer(myData2)}
          />
        </div>
        // </ModalBody>
      );

    case "audio":
      return (
        <div className="audioPlayer">
          <ReactAudioPlayer
            controls
            style={{ width: "100%" }}
            src={data.content}
          />
        </div>
      );

    case "image":
      return (
        <div>
          <img
            src={data.content}
            alt={data.label}
            //   onLoad={hideSpinner}
          />
        </div>
      );

    case "link":
      return (
        <div style={{ minHeight: minWindowHeight, minWidth: minWindowWidth }}>
          <div className="interactive-link-player">
            <iframe src={data.content} title="Link"></iframe>
          </div>
        </div>
      );

    case "text":
      return (
        <p
          dangerouslySetInnerHTML={{
            //TODO ADD SECURITY JS INJECTION CHECK
            __html: sanitizer(data.content),
          }}
        />
      );
    case "component":
      //
      return <div>{data.content}</div>;

    case "answer":
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: sanitizer(data.content),
          }}
        />
      );
    case "mc_question": //multiple choice question
      return (
        <div style={{ minHeight: 270 }}>
          <MCQuestion data={data} />
        </div>
      );
    case "mr_question": //multiple Response question
      return (
        <div style={{ minHeight: 270 }}>
          <MCQuestion data={data} />
        </div>
      );

    default:
      return <div>{data.content}</div>;
  }
};

const iframe = (iframe) => {
  const sanitizer = dompurify.sanitize;
  const cleanedIframe = sanitizer(iframe, {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
  });

  return {
    __html: cleanedIframe,
    // onLoad: console.log('Loaded')
  };
};
