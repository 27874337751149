import {
  ENABLE_EDIT_MODE,
  DISABLE_EDIT_MODE,
  ENABLE_BUTTON_MOVE,
  DISABLE_BUTTON_MOVE,
  ENABLE_STUDIO_MODE,
  DISABLE_STUDIO_MODE,
  SET_BACKGROUND_IMAGE_OPACITY,
  HIDE_ANNOTATIONS,
  SHOW_ANNOTATIONS,
  UNDO_TRIGGERED,
  REDO_TRIGGERED,
  REMOVE_TRIGGERED,
  REMOVE_ALL_TRIGGERED,
  ZOOM_IN_TRIGGERED,
  ZOOM_OUT_TRIGGERED,
  IMAGE_LOADED,
  SET_PAGE_PART_ZOOM_MODE,
  SET_PAGE_PART_ZOOM_OBJECT,
} from "./actionTypes";

//EDIT MODE
export const disableEditMode = () => {
  return {
    type: DISABLE_EDIT_MODE,
  };
};
export const enableEditMode = () => {
  return {
    type: ENABLE_EDIT_MODE,
  };
};

//MOVE BUTTONS
export const disableButtonMove = () => {
  return {
    type: DISABLE_BUTTON_MOVE,
  };
};
export const enableButtonMove = () => {
  return {
    type: ENABLE_BUTTON_MOVE,
  };
};

//STUDIO MODE
export const disableStudioMode = () => {
  return {
    type: DISABLE_STUDIO_MODE,
  };
};
export const enableStudioMode = () => {
  return {
    type: ENABLE_STUDIO_MODE,
  };
};

//SET BACKGROUND IMAGE OPACITY
export const setBackgroundImageOpacity = (payload) => {
  return {
    type: SET_BACKGROUND_IMAGE_OPACITY,
    payload,
  };
};

export const hideAnnotations = () => {
  return {
    type: HIDE_ANNOTATIONS,
  };
};
export const showAnnotations = () => {
  return {
    type: SHOW_ANNOTATIONS,
  };
};

export const triggerUndo = (payload) => {
  return {
    type: UNDO_TRIGGERED,
    payload,
  };
};
export const triggerRedo = (payload) => {
  return {
    type: REDO_TRIGGERED,
    payload,
  };
};
export const triggerRemove = (payload) => {
  return {
    type: REMOVE_TRIGGERED,
    payload,
  };
};
export const triggerRemoveAll = (payload) => {
  return {
    type: REMOVE_ALL_TRIGGERED,
    payload,
  };
};
export const triggerZoomIn = (payload) => {
  return {
    type: ZOOM_IN_TRIGGERED,
    payload,
  };
};
export const triggerZoomOut = (payload) => {
  return {
    type: ZOOM_OUT_TRIGGERED,
    payload,
  };
};
export const setImageLoaded = (payload) => {
  return {
    type: IMAGE_LOADED,
    payload,
  };
};
export const setPagePartZoomObject = (orderId, downCoords, upCoords) => {
  // TODO orderId id is the order in the page TODO set/change it through an UI
  return {
    type: SET_PAGE_PART_ZOOM_OBJECT,
    payload: { orderId, downCoords, upCoords },
  };
};
export const setPagePartZoomMode = (payload) => {
  //payload should be one of these: zoomed, null
  return {
    type: SET_PAGE_PART_ZOOM_MODE,
    payload,
  };
};
