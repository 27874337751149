import React from "react";
import { useTranslation } from "react-i18next";

export default function QButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (
    <div
      className="interactive-object-without-border"
      style={{ transform, transformOrigin: "left top" }}
    >
      <span className="object-icon">
        {/* <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="11"
            fill="white"
            stroke="#FFA800"
            strokeWidth="2"
          />
          <path
            d="M17.913 14.122C17.994 14.2485 18.0159 14.3989 17.974 14.54C17.9321 14.6811 17.8298 14.8014 17.6895 14.8744C17.5492 14.9475 17.3826 14.9673 17.2261 14.9295C17.0697 14.8916 16.9363 14.7993 16.8553 14.6728L16.0271 13.3787C15.5602 13.6413 15.058 13.8491 14.5327 13.997L14.7968 15.3479C14.8117 15.4195 14.8106 15.493 14.7938 15.5642C14.7769 15.6354 14.7446 15.7029 14.6986 15.7628C14.6527 15.8226 14.594 15.8737 14.5261 15.913C14.4581 15.9522 14.3822 15.979 14.3027 15.9916C14.2232 16.0043 14.1417 16.0026 14.0629 15.9866C13.9842 15.9707 13.9097 15.9408 13.8438 15.8988C13.7779 15.8567 13.722 15.8032 13.6791 15.7415C13.6363 15.6798 13.6074 15.6111 13.5942 15.5392L13.3371 14.224C12.7803 14.2831 12.2176 14.283 11.6608 14.2235L11.4038 15.5387C11.375 15.6819 11.2845 15.8091 11.152 15.8923C11.0195 15.9755 10.8559 16.008 10.697 15.9827C10.538 15.9574 10.3966 15.8764 10.3037 15.7573C10.2108 15.6383 10.1739 15.4909 10.2012 15.3474L10.4653 13.9958C9.9408 13.8477 9.43937 13.6399 8.97315 13.3774L8.13945 14.68C8.05848 14.8065 7.92511 14.8988 7.76868 14.9366C7.61225 14.9745 7.44557 14.9547 7.30532 14.8816C7.16507 14.8086 7.06273 14.6883 7.02081 14.5472C6.97889 14.406 7.00083 14.2557 7.08181 14.1292L7.99036 12.7096C7.68405 12.4606 7.40014 12.19 7.14129 11.9004C7.0411 11.7867 6.9948 11.6418 7.01248 11.4974C7.03016 11.353 7.11038 11.2207 7.23568 11.1294C7.36097 11.0381 7.52119 10.9951 7.68143 11.0099C7.84167 11.0246 7.98896 11.0959 8.09121 11.2082C8.89143 12.1016 10.306 13.1666 12.5013 13.1666C14.6964 13.1666 16.111 12.1016 16.9113 11.2082C16.9614 11.1513 17.0235 11.1038 17.0941 11.0687C17.1647 11.0335 17.2422 11.0113 17.3224 11.0033C17.4025 10.9953 17.4836 11.0018 17.5609 11.0222C17.6383 11.0426 17.7104 11.0767 17.7731 11.1224C17.8358 11.1681 17.8879 11.2245 17.9263 11.2884C17.9647 11.3524 17.9887 11.4225 17.9968 11.4949C18.005 11.5672 17.9973 11.6403 17.974 11.7099C17.9507 11.7795 17.9124 11.8443 17.8612 11.9005C17.6018 12.1907 17.3172 12.4618 17.0101 12.7113L17.913 14.122Z"
            fill="#FFA800"
          />
        </svg> */}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="11"
            fill="white"
            stroke="#FFA800"
            strokeWidth="2"
          />
          <path
            d="M18.9154 11.1578C17.5973 8.67789 14.9879 7 12 7C9.01211 7 6.40193 8.67906 5.08457 11.158C5.02897 11.2641 5 11.3813 5 11.5001C5 11.619 5.02897 11.7361 5.08457 11.8422C6.40266 14.3221 9.01211 16 12 16C14.9879 16 17.5981 14.3209 18.9154 11.842C18.971 11.7359 19 11.6187 19 11.4999C19 11.381 18.971 11.2639 18.9154 11.1578ZM12 14.875C9.60225 14.875 7.40405 13.5859 6.21697 11.5C7.40405 9.41406 9.60201 8.125 12 8.125C14.398 8.125 16.5959 9.41406 17.783 11.5C16.5962 13.5859 14.398 14.875 12 14.875Z"
            fill="#FFA800"
          />
          <circle cx="12" cy="11.5" r="2.5" fill="#FFA800" />
        </svg>
      </span>
    </div>
  );
}
