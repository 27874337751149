import React from "react";
import { useTranslation } from "react-i18next";

export default function Undo(props) {
  const { t } = useTranslation();
  return (
    <li onClick={props.onClickUndo}>
      <span 
        className={`button normal-btn undo ${
        props.shouldShowButtonLabels ? "show-label-span" : ""
        }`}
      title={t("Undo")}
      data-tip={t("Undo")}
      >
        <img
          src={require("../../assets/img/svg/history-undo.svg")}
          alt={t("Undo")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Undo")}</span>}
      </span>
    </li>
  );
}
