import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";
import SearchBar from "./SearchBar";
import PdfPageList from "./PdfPageList";
import { connect, useSelector, useDispatch } from "react-redux";
import { toggleSideBar, sideBarOpen, sideBarClose } from "../actions/sidebar";
import { toggleSearchBar } from "../actions/searchbar";
// import { pageNoChange } from "../actions/pages";
import { pullPages, pageNoChange } from "../actions/pages";
import Loading from "./Loading";
import QuestionButton from "./SketchSelectedButtons/QuestionButton";
import CopyButton from "./SketchSelectedButtons/CopyButton";
import PasteButton from "./SketchSelectedButtons/PasteButton";
import RemoveButton from "./SketchSelectedButtons/RemoveButton";
import UndoButton from "./SketchSelectedButtons/UndoButton";
import RedoButton from "./SketchSelectedButtons/RedoButton";
import Timer from "./SketchSelectedButtons/Timer";
import ExitButton from "./SketchSelectedButtons/ExitButton";
import {
  setAnnotationSelectionMode,
  setAnnotationSelectionCoords,
  saveAnnotationSelectionToButton,
  resetAnnotationSelectionMode,
  setEditSelectionMode,
  setSelectionCopyMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setAnnotationBeforeState,
} from "../actions/annotationSelection";
import {
  setPagePartZoomMode,
  setPagePartZoomObject,
} from "../actions/settings";

import {
  BrowserRouter as Router,
  useLocation,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
// import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Offline } from "react-detect-offline";
import startTour from "./Tour";
import TourIntro3 from "./TourIntro3";
import {
  captureInProcess,
  hideCaptureImage,
  captureNotInprocess,
} from "../actions/imageCapture";
// import ReactTooltip from "react-tooltip";
import { addButtonService, addButtonFormService } from "../services/api";

import { insertActivity } from "../actions/addActivity";
import BottomMenu from "./BottomMenu";

// import { useSwipeable } from "react-swipeable";

function MainPage(props) {
  const _pdfPageList = useRef(null);
  // const handlers = useSwipeable({
  //   onSwipedRight: (SwipeEventData) => onClickPrevPage(SwipeEventData),
  //   onSwipedLeft: (SwipeEventData) => onClickNextPage(SwipeEventData),
  //   // onSwiping: (eventData) => onTapPage(eventData),
  //   preventDefaultTouchmoveEvent: true,
  //   delta:
  //     props.scaleZoomFactorVal.scaleFactor > 1 ||
  //     window.innerWidth < props.scaleZoomFactorVal.canvas.canvasWidth
  //       ? (window.innerWidth / 5) * 4
  //       : 350,
  //   trackMouse: true, // track mouse input
  // });
  const { annotationSelectionMode } = props;
  const onClickNextPage = (e) => {
    props.pageNoChange(parseInt(props.currentPageNumber) + 1);
  };
  const onClickPrevPage = (e) => {
    console.log("onClickPrevPage+ ", props.scaleZoomFactorVal.scaleFactor);
    props.pageNoChange(parseInt(props.currentPageNumber) - 1);
  };
  const { t, i18n } = useTranslation();
  let location = useLocation();
  let { page } = useParams();

  useEffect(() => {
    props.pullPages();
    if (localStorage.showIntro !== "false") {
      startTour();
    }

    if (window.innerWidth < 1024) {
      console.log("close sidebar!!!");
      props.sideBarClose();
    }
  }, []);

  useEffect(() => {
    props.pageNoChange(page);
  }, [location]);

  useEffect(() => {
    if (annotationSelectionMode)
      props.setAnnotationBeforeState({
        buttonState: props.hideInteractiveObjectsVal,
        drawingState: props.showAnnotations,
        zoomState: props.scaleZoomFactorVal.scaleFactor,
      });
  }, [annotationSelectionMode]);

  const status = parseInt(props.totalPageNumber) > 0;

  useEffect(() => {
    if (
      props.annotations.annotation &&
      props.annotations.annotation.length > 0
    ) {
      console.log("USE EFFECT SEND DATA");
      const myRatio =
        props.canvasVals.canvasBackgroundImageWidth /
        props.canvasVals.canvasWidth; //ratio of the book page
      const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000
      const bLeft =
        Math.floor(
          myRatio * parseInt(props.currentSelection.left) * originRatio
        ) - 10;

      const sendData = {
        // id: guidGenerator(), //For redux based id generation.
        left: bLeft > 0 ? bLeft : 0, // parseInt(props.currentSelection.left),
        top: Math.floor(
          myRatio * parseInt(props.currentSelection.top) * originRatio
        ), //parseInt(props.currentSelection.top),
        height: parseInt(props.currentSelection.height),
        width: parseInt(props.currentSelection.width),
        show: 1,
        button_type:"questionHideable",
        own_activity: 1,
        style: "style2",
        status: 1,
        public: 1,
        sharing: 1,
        content: JSON.stringify(props.annotations.annotation),
      };
      const formData = new FormData();
      formData.append("content", sendData.content);
      formData.append("button_type", sendData.button_type);
      formData.append("style", sendData.style);
      formData.append("top", sendData.top);
      formData.append("left", sendData.left);
      formData.append("width", sendData.width);
      formData.append("height", sendData.height);
      formData.append("show", sendData.show);
      formData.append("status", sendData.status);
      formData.append("sharing", sendData.sharing);
      addButtonService(
        formData,
        String(props.domain).toLowerCase(),
        props.currentPageId
      )
        .then(async (response) => {
          sendData.id = response.id;
          await props.insertActivity(sendData, props.currentPageId);
          // await props.setAnnotationSelectionMode(true);
          // await props.setAnnotationSelectionMode(false);

          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("logged error", error);

          // setIsLoading(false);
        });
      props.resetAnnotationSelectionMode();
      console.log("degiti");
    }
  }, [props.annotations]);
  useEffect(() => {
    if (props.pageZoomPartObject.orderId) {
      console.log("USE EFFECT -------- SEND DATA");
      const myRatio =
        props.canvasVals.canvasBackgroundImageWidth /
        props.canvasVals.canvasWidth; //ratio of the book page
      const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000
      const bLeft =
        Math.floor(
          myRatio *
            parseInt(props.pageZoomPartObject.downCoords.x) *
            originRatio
        ) - 10;

      const sendData = {
        left: bLeft > 0 ? bLeft : 0, // parseInt(props.currentSelection.left),
        top: Math.floor(
          myRatio *
            parseInt(props.pageZoomPartObject.downCoords.y) *
            originRatio
        ), //parseInt(props.currentSelection.top),
        height: parseInt(props.pageZoomPartObject.upCoords.x),
        width: parseInt(props.pageZoomPartObject.upCoords.y),
        show: 1,
        button_type: "zoomPagePart",
        own_activity: 1,
        style: "style2",
        status: 1,
        public: 1,
        sharing: 1,
        content: JSON.stringify(props.pageZoomPartObject),
      };

      addButtonFormService(
        sendData,
        String(props.domain).toLowerCase(),
        props.currentPageId
      )
        .then(async (response) => {
          sendData.id = response.id;
          await props.insertActivity(sendData, props.currentPageId);
          await props.setPagePartZoomObject(null, null, null);
        })
        .catch((error) => {
          console.log("logged error", error);
        });
      // props.resetAnnotationSelectionMode();
      console.log("degiti");
    }
  }, [props.pageZoomPartMode]);

  const shouldTopMenuOpened = props.editSelectionMode;
  const shouldBottomMenuOpened =
    props.annotationSelectionMode || props.pageZoomPartMode;
  // console.log("shouldBottomMenuOpened", shouldBottomMenuOpened);
  const renderTopMenu = () => {
    return (
      <div
        className={`navbar is-fixed-top top-annotation-menu ${
          shouldTopMenuOpened ? "open" : "hide"
        } `}
        // style={{ top, left }}
      >
        {/* <Timer/> | */}
        {/* <QuestionButton
          onClickHandler={() => {
            props.setSelectionPasteMode(true);
          }}
        />
         <CopyButton
          onClickHandler={() => {
            props.setSelectionCopyMode(true);
          }}
        /> */}
        {props.editSelectionMode && (
          <>
            <PasteButton
              onClickHandler={() => {
                props.setSelectionPasteMode(true);
              }}
            />

            <RemoveButton
              onClickHandler={() => {
                props.setSelectionRemoveMode(true);
              }}
            />
          </>
        )}

        {/* <UndoButton
          onClickHandler={() => {
            props.setSelectionUndoMode(true);
          }}
        />
        <RedoButton
          onClickHandler={() => {
            props.setSelectionRedoMode(true);
          }}
        /> */}
        {props.annotationSelectionMode && (
          <ExitButton
            onClickHandler={() => {
              props.setAnnotationSelectionMode(false);
            }}
          />
        )}
      </div>
    );
  };

  const renderSubmitQHideableButton = () => {
    const top = props.currentSelection.top
      ? props.currentSelection.top - props.currentSelection.height
      : window.innerHeight - 100;
    const left = props.currentSelection.top
      ? props.currentSelection.left + props.currentSelection.width
      : window.innerWidth - 100;

    return (
      <>
        {props.annotationSelectionMode && (
          <button
            className="float-edit-button"
            style={{ top, left }}
            onClick={() => {
              console.log("tik");
            }}
          >
            {t("Select an annotation or add one to submit.")}{" "}
          </button>
        )}
      </>
    );
  };

  return (
    <div>
      {status ? (
        <div>
          <div
            className="App"
            style={{
              display: `${
                props.imageCapture.captureInprocess ? "none" : "block"
              }`,
            }}
          >
            <div
              className="hero is-fullhd is-fullheight"
              data-theme={props.theme}
            >
              <div className="columns is-gapless">
                {/* <NavBar /> */}

                <ToastContainer autoClose={2000} />
                {renderTopMenu()}
                <BottomMenu shouldBottomMenuOpened={shouldBottomMenuOpened} />
                <Offline>
                  <div className="no-internet-ebook">
                    {t("No internet connection.")}
                  </div>
                </Offline>

                <Sidebar />
                <div className="column content" id="parentOne">
                  <TourIntro3 />
                  <PdfPageList
                    toolBarDraggable={false}
                    // handlers={props.selectedTool === "pan" ? handlers : null}
                    forwardedRef={_pdfPageList}
                  />
                </div>
                <div className={`is-pulled-right`}>
                  <SearchBar />
                </div>
                <button
                  className="float-search-button collapse-searchbar is-pulled-right"
                  onClick={
                    () => props.toggleSearchBar(props.searchBarOpened) //TODO Add same for the left side bar-thumbnails
                  }
                >
                  <span className="icon">
                    <img
                      src={require("../assets/img/svg/toolbar-search.svg")}
                      alt="Collapse Sidebar"
                    />
                  </span>
                </button>
                {/* {renderSubmitQHideableButton()} */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="hero is-fullhd is-fullheight content"
          data-theme={props.theme}
        >
          <Loading />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    pages: state.pages.pages,
    pagesState: state.pages,
    totalPageNumber: state.pages.totalPageNumber,
    currentPageNumber: state.pages.currentPageNo,
    currentPageId: state.pages.currentPage[0]?.id,
    currentPage: state.pages.currentPage,
    pageLoaded: state.pageLoaded,
    imageCapture: state.imageCapture,
    imagesLoaded: state.imagesLoaded,
    scaleZoomFactorVal: state.scaleZoomFactor,
    selectedTool: state.selectedTool.selectedTool,
    searchBarOpened: state.searchBarOpen,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    editSelectionMode: state.annotationSelections.editSelectionMode,
    currentSelection: state.annotationSelections.currentSelection,
    annotations: state.annotationSelections.annotations,
    domain: state.login.user_profile.domain_name,
    ebookId: state.pages.ebookId,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    showAnnotations: state.settings.showAnnotations,
    canvasVals: state.scaleZoomFactor.canvas,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObject: state.settings.pageZoomPartObject,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
  toggleSearchBar,
  pageNoChange,
  pullPages,
  captureInProcess,
  captureNotInprocess,
  hideCaptureImage,
  sideBarOpen,
  sideBarClose,
  setAnnotationSelectionMode,
  setEditSelectionMode,
  resetAnnotationSelectionMode,
  setSelectionCopyMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setAnnotationBeforeState,
  insertActivity,
  setPagePartZoomMode,
  setPagePartZoomObject,
})(MainPage);
