import { GET_MERRIAM,GET_MERRIAM_SOUND, RESET_PAGES} from "./actionTypes";
import axios from "axios";
import configuration from "../config.json";

export const pullWordFromMerriam = (searchWord) => {
  return dispatch => {
    fetch(
      "https://www.dictionaryapi.com/api/v3/references/learners/json/"+searchWord+"?key="+configuration.merriamKey+""
    ) .then((res) => res.json())
    .then((result) => {
      console.log("result merriam", result);
      dispatch(getMerriam(result));
      return result;
    }).then(
      resp => {
        console.log("sound", resp);
        console.log("audio", resp[0].hwi.prs[0].sound.audio);
          dispatch(getMerriamSound(resp[0].hwi.prs[0].sound.audio));
      }
    )
    .catch((e) => {
      console.log(e);
    });
    
  };
};

export const getMerriam = result => {
  return {
    type: GET_MERRIAM,
    payload: result    
  };
};

export const getMerriamSound = result => {
    return {
        type: GET_MERRIAM_SOUND,
        payload: result
    }
} 

