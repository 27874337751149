import { GET_PIXABAYVIDEO, CLOSE_PIXABAYVIDEO } from "./actionTypes";
import axios from "axios";
import configuration from "../config.json";
import i18n from "i18next";

const axiosConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    crossorigin: true,
  },
};
//https://pixabay.com/api/?key=18563003-6f1f80a7f7d937b317c81a97b&q=cat&lang=en&per_page=10&safesearch=true&page=1
export const pullVideosFromPixabay = (searchQuery, page = 1) => {
  // const API_KEY = "18563003-6f1f80a7f7d937b317c81a97b";
  // const URL =
  //   "https://pixabay.com/api/?key=" +
  //   API_KEY +
  //   "&q=" +
  //   encodeURIComponent("red roses");

  return (dispatch) => {
    // axios
    // .get(
    //   "https://pixabay.com/api/?key=18563003-6f1f80a7f7d937b317c81a97b&q=cat&lang=en&per_page=10&safesearch=true&page=1",
    //   axiosConfig
    // )
    fetch(
      "https://pixabay.com/api/videos/?key=18563003-6f1f80a7f7d937b317c81a97b&q=" +
        searchQuery +
        "&lang=" +
        i18n.language +
        "&per_page=10&safesearch=true&page=" +
        page
    )
      .then((res) => res.json())
      // .then(
      //   (result) => {
      //     // this.setState({
      //     //   isLoaded: true,
      //     //   items: result.items,
      //     // });
      //     console.log('result', result)
      //   },
      //   // Note: it's important to handle errors here
      //   // instead of a catch() block so that we don't swallow
      //   // exceptions from actual bugs in components.
      //   (error) => {
      //     // this.setState({
      //     //   isLoaded: true,
      //     //   error,
      //     // });
      //     console.log('error', error)
      //   }
      // );
      // axios
      //   .get("https://pixabay.com/api/", {
      //     params: {
      //       key: configuration.pixabayKey,
      //       q: searchQuery,
      //       lang: configuration.searchBarLang,
      //       per_page: 10,
      //       safesearch: true,
      //       page: page,
      //     }

      //     axiosConfig,
      //   })
      .then((result) => {
        console.log("result pixabay video", result);
        dispatch(getPixabayVideo({ result, page: page }));
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const getPixabayVideo = (result) => {
  return {
    type: GET_PIXABAYVIDEO,
    payload: result,
  };
};

export const closePixabayVideo = (result) => {
  return {
    type: CLOSE_PIXABAYVIDEO,
    payload: result,
  };
};
