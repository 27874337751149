import {
  ADD_ACTIVITY,
  CANCEL_ACTIVITY,
  DONE_ACTIVITY,
  INSERT_ACTIVITY,
  DELETE_ACTIVITY,
  EDIT_ACTIVITY
} from "./actionTypes";

export function addActivity(name, top, left, title) {
  return function (dispatch) {
    //send as object (name, top, left)
    dispatch({
      type: ADD_ACTIVITY,
      payload: { name, top, left, title },
    });
  };
}
export function editActivity(id, name, top, left,btnStyle,activityShow) {//name is activity type
  return function (dispatch) {
    dispatch({
      type: EDIT_ACTIVITY,
      payload: { id, name, top, left,btnStyle,activityShow },
    });
  };
}
export function cancelActivity() {
  //reset form when user cancels
  return function (dispatch) {
    dispatch({
      type: CANCEL_ACTIVITY,
    });
  };
}
export function doneActivity() {
  //reset when form closes
  return function (dispatch) {
    dispatch({
      type: DONE_ACTIVITY,
    });
  };
}
export function insertActivity(pageNumber, button) {
  //insert button
  return function (dispatch) {
    dispatch({
      type: INSERT_ACTIVITY,
      payload: [pageNumber, button],
    });
  };
}
export function deleteActivity(button) {
  //insert button
  return function (dispatch) {
    dispatch({
      type: DELETE_ACTIVITY,
      payload: button,
    });
  };
}
