import {CAPTURED_IMAGE_SHOW,CAPTURED_IMAGE_HIDE,CAPTURED_INPROCESS,CAPTURED_NOT_INPROCESS} from "./actionTypes";

export const imageCaptured = (payload) => {
    return {
        type: CAPTURED_IMAGE_SHOW,
        payload:payload
    }
};

export const hideCaptureImage = () =>{
    return {
        type: CAPTURED_IMAGE_HIDE,
    }
}

export const captureInProcess = () =>{
    return {
        type: CAPTURED_INPROCESS,
    }
}

export const captureNotInprocess = () =>{
    return {
        type: CAPTURED_NOT_INPROCESS,
    }
}

