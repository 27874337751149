import { CAPTURE_ON, CAPTURE_OFF } from "../actions/actionTypes";

export default (state = false, action) => {
  switch (action.type) {
    case CAPTURE_ON:
      return true;

    case CAPTURE_OFF:
      return false;

    default:
      return state;
  }
};
